import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setOrigin } from 'redux/actions/ui';

const requireAuthentication = (ChildComponent) => {
  class ComposedComponent extends Component {
    render() {
      const { auth, location, setOrigin } = this.props;
      if (auth && auth.currentUser && auth.currentUser.id) {
        return <ChildComponent {...this.props} />;
      }

      setOrigin({
        origin: location.pathname,
        feature: '[RequireAuthentication]',
      });
      return <Redirect to="/signin" />;
    }
  }

  const mapToStateProps = (state) => ({ auth: state.auth });
  const mapDispatchToProps = { setOrigin };
  return connect(mapToStateProps, mapDispatchToProps)(ComposedComponent);
};
// const requireAuthentication = (ChildComponent) => {
//   class ComposedComponent extends Component {
//     componentDidMount() {
//       this.shouldNavigateAway();
//     }

//     componentDidUpdate() {
//       this.shouldNavigateAway();
//     }

//     shouldNavigateAway() {
//       const { auth, history, location, setOrigin } = this.props;
//       if (!auth || !auth.currentUser || !auth.currentUser.id) {
//         setOrigin({
//           origin: location.pathname,
//           feature: '[RequireAuthentication]',
//         });
//         history.push('/signin');
//         return false;
//       }

//       return true;
//     }

//     render() {
//       if (this.shouldNavigateAway()) {
//         return <ChildComponent {...this.props} />;
//       }
//       return <></>;
//     }
//   }

//   const mapToStateProps = (state) => ({ auth: state.auth });
//   const mapDispatchToProps = { setOrigin };

//   return connect(mapToStateProps, mapDispatchToProps)(ComposedComponent);
// };

export default requireAuthentication;
