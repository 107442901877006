import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { monthMapper } from './month.mapper';
import { connect } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MontlyTaskComparisonChart = ({ monthlyResults, localizations }) => {
  const createMonthArray = () => {
    const date = new Date();
    const indexOfCurrentMonth = date.getMonth() + 1;
    const firstMonthIndex = parseInt(monthMapper[0].index);

    return Array.from(
      {
        length:
          indexOfCurrentMonth < firstMonthIndex
            ? 13 + indexOfCurrentMonth - firstMonthIndex
            : 1 + indexOfCurrentMonth - firstMonthIndex,
      },
      (_, i) => i + firstMonthIndex
    );
  };

  const getReadingData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon) === (month === 12 ? 12 : month % 12) &&
          current.type === 'READ'
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const getListeningData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon) === (month === 12 ? 12 : month % 12) &&
          current.type === 'LISTEN'
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const getQuizData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon) === (month === 12 ? 12 : month % 12) &&
          current.type === 'QUIZ'
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const calculateAcademicYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index)) {
      return `${currentYear}-${currentYear + 1}`;
    }
    return `${currentYear - 1}-${currentYear}`;
  };

  return (
    <div className="student-improvement-progress">
      <div className="section-header">
        {`${
          localizations?.MONTHLY_TASK_COMPARISON_HEADER ?? 'Gelişim Durumu'
        } (${calculateAcademicYear()} ${
          localizations?.REPORT_ACADEMIC_YEAR ?? 'Eğitim Öğretim Yılı'
        })`}
      </div>
      <div className="chart-container">
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
                align: 'center',
                labels: { font: { size: 16 }, padding: 8 },
              },
              title: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: { font: { size: 16 } },
              },
              y: {
                ticks: { font: { size: 20 }, precision: 0 },
              },
            },
          }}
          data={{
            labels: monthMapper.map((month) => month.text),
            datasets: [
              {
                label:
                  localizations?.MONTHLY_TASK_COMPARISON_READING_LABEL ??
                  'Okuma',
                data: getReadingData(),
                // data: monthlyResults.map((item) => item.readingCount),
                borderColor:
                  localizations?.MONTHLY_TASK_COMPARISON_READING_BORDER_COLOR ??
                  'rgb(79, 118, 255)',
                backgroundColor:
                  localizations?.MONTHLY_TASK_COMPARISON_READING_BGCOLOR ??
                  'rgba(79, 118, 255, 1)',
                // borderColor: 'rgb(0, 63, 92)',
                // backgroundColor: 'rgba(0, 63, 92, 1)',
              },
              {
                label:
                  localizations?.MONTHLY_TASK_COMPARISON_LISTENING_LABEL ??
                  'Dinleme',
                data: getListeningData(),
                // data: monthlyResults.map((item) => item.readingCount),
                borderColor:
                  localizations?.MONTHLY_TASK_COMPARISON_LISTENING_BORDER_COLOR ??
                  'rgb(255, 41, 96)',
                backgroundColor:
                  localizations?.MONTHLY_TASK_COMPARISON_LISTENING_BGCOLOR ??
                  'rgba(255, 41, 96, 1)',
                // borderColor: 'rgb(102, 81, 145)',
                // backgroundColor: 'rgba(102, 81, 145, 1)',
              },
              {
                label:
                  localizations?.MONTHLY_TASK_COMPARISON_QUIZ_LABEL ??
                  'Değerlendirme',
                data: getQuizData(),
                // data: monthlyResults.map((item) => item.readingCount),
                borderColor:
                  localizations?.MONTHLY_TASK_COMPARISON_QUIZ_BORDER_COLOR ??
                  'rgb(255, 193, 23)',
                backgroundColor:
                  localizations?.MONTHLY_TASK_COMPARISON_QUIZ_BGCOLOR ??
                  'rgba(255, 193, 23, 1)',
                // borderColor: 'rgb(212, 80, 135)',
                // backgroundColor: 'rgba(212, 80, 135, 1)',
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(MontlyTaskComparisonChart);
