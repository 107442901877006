import DropdownFixedNavbar from 'components/Custom/DropdownFixedNavbar';
import NothingHeader from 'components/Custom/NothingHeader';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, CardBody, Table, Container, Row, Col } from 'reactstrap';
import { startBookListScreen } from 'redux/actions/pages/book-list';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { BOOK_LIST } from 'redux/actions/pages';
import { removeMessage } from 'redux/actions/message';
import Swal from 'sweetalert2';
import MessageTypes from 'enums/message-types';
// import requireStudentAuthentication from 'hocs/require-student-authentication';
// import requireAuthentication from 'hocs/require-authentication';
import requireSpecificUserAuthentication from 'hocs/require-specific-user-authentication';

const BookList = ({
  currentUser,
  allBooks,
  loading,
  message,
  startScreen,
  removeMessage,
  localizations,
  language,
}) => {
  const history = useHistory();

  useEffect(() => {
    if (currentUser?.id > 0) {
      startScreen();
    }
    // if (currentUser?.id > 0 && currentUser?.userType === 'STUDENT') {
    //   startScreen();
    // }
    document.body.classList.add('book-list-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('book-list-page');
      document.body.classList.remove('sidebar-collapse');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ''
    ) {
      Swal.fire({
        icon: message.messageType || 'error',
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GET_BOOK_LIST_CONFIRM_BTN,
      }).then((_) => {
        removeMessage(BOOK_LIST);
        history.push('/');
      });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      {loading ? (
        <div className="loading whole-screen">
          <PacmanLoader
            color="#67BCEC"
            // loading={loading}
            // css={override}
            size={50}
          />
        </div>
      ) : null}
      <DropdownFixedNavbar />
      <div className="wrapper">
        <NothingHeader />
        <div className="main">
          <div className="section" style={{ paddingTop: 0 }}>
            <Container>
              <div>
                <h3 className="title">{localizations?.ALL_BOOKS}</h3>
                <div id="tables">
                  <Row>
                    <Col md="12">
                      <Card className="card-plain">
                        <CardBody>
                          <Table
                            lang={language}
                            className="table-shopping"
                            responsive
                          >
                            <thead>
                              <tr>
                                <th className="text-center"></th>
                                <th>{localizations?.BOOK}</th>
                                <th>{localizations?.LANGUAGE}</th>
                                <th>{localizations?.GRADE}</th>
                                <th>{localizations?.SERIE}</th>
                                <th>{localizations?.LEVEL}</th>
                                <th>{localizations?.CATEGORY}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {allBooks &&
                                Object.values(allBooks).map((book) => (
                                  <tr key={book.id}>
                                    <td>
                                      <div className="img-container">
                                        <img
                                          alt="..."
                                          src={book.coverImage}
                                        ></img>
                                      </div>
                                    </td>
                                    <td className="td-name">
                                      <Link
                                        to={{
                                          pathname: `/read/${book.id}`,
                                          state: { from: '/book-list' },
                                        }}
                                      >
                                        {book.title}
                                      </Link>
                                      <br></br>
                                      <small>
                                        {localizations[book.author]}
                                      </small>
                                    </td>
                                    <td>{localizations[book.language]}</td>
                                    <td>{localizations[book.grade]}</td>
                                    <td>{localizations[book.series]}</td>
                                    <td>{localizations[book.level]}</td>
                                    <td>{localizations[book.category]}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth?.currentUser,
  allBooks: state.data.books,
  loading: state.ui.loading,
  message: state.message[BOOK_LIST],
  language: state.localization?.language,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  startScreen: startBookListScreen,
  removeMessage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  requireSpecificUserAuthentication(['OTHER', 'EDICT', 'DEMO_OTHER'])(BookList)
);
