import React from 'react';

const NothingHeader = () => {
  return (
    <div
      className="nothing-header"
      style={{
        width: '100%',
        height: '100px',
      }}
    />
  );
};

export default NothingHeader;
