import React from 'react';
import { connect } from 'react-redux';

const ReportGeneralInfo = ({
  bookCount,
  readingCount,
  listeningCount,
  recordingCount,
  quizCount,
  localizations,
}) => (
  <div className="student-report-general-info">
    <div className="section-header">
      {localizations?.REPORT_GENERAL_INFO_HEADER ?? 'Genel Bilgiler'}
    </div>
    <div className="student-report-items-container">
      <div className="student-report-item">
        <div className="item-value">{bookCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_BOOK_ITEM ?? 'Kitap'}
        </div>
      </div>
      <div className="student-report-item">
        <div className="item-value">{readingCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_READING_ITEM ?? 'Okuma'}
        </div>
      </div>
      <div className="student-report-item">
        <div className="item-value">{listeningCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_LISTENING_ITEM ?? 'Dinleme'}
        </div>
      </div>
      <div className="student-report-item">
        <div className="item-value">{recordingCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_RECORDING_ITEM ?? 'Oku Kaydet'}
        </div>
      </div>
      <div className="student-report-item">
        <div className="item-value">{quizCount}</div>
        <div className="item-label">
          {localizations?.REPORT_GENERAL_INFO_QUIZ_ITEM ?? 'Değerlendirme'}
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(ReportGeneralInfo);
