import React from 'react';

const RoundedCard = ({
  title,
  titleColor,
  subtitle,
  subtitleColor,
  backgroundColor,
  background,
  backgroundImage,
  backgroundSize,
  backgroundPosition,
  children,
}) => {
  const cardStyle = {
    marginTop: '30px',
    borderRadius: '30px',
    height: '650px',
    maxHeight: '650px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  // Öncelik sırası backgroundColor -> background -> backgroundImage olacaktır.
  if (
    backgroundColor !== null &&
    backgroundColor !== undefined &&
    backgroundColor !== ''
  ) {
    cardStyle.backgroundColor = backgroundColor;
  } else if (
    background !== null &&
    background !== undefined &&
    background !== ''
  ) {
    cardStyle.background = background;
  } else if (
    backgroundImage !== null &&
    backgroundImage !== undefined &&
    backgroundImage !== ''
  ) {
    // background image
    cardStyle.backgroundImage = `url(${backgroundImage})`;
    cardStyle.backgroundSize = backgroundSize;

    if (
      backgroundPosition !== null &&
      backgroundPosition !== undefined &&
      backgroundPosition !== ''
    ) {
      cardStyle.backgroundPosition = backgroundPosition;
    }
  } else {
    // do nothing
  }
  return (
    <div style={cardStyle}>
      <div style={{ padding: '36px 40px 0 40px', flexGrow: 0 }}>
        <h3
          style={{
            color: titleColor,
            fontSize: '21px',
            lineHeight: '1.19',
            fontWeight: 600,
            letterSpacing: '0.011em',
            margin: 0,
            padding: 0,
            whiteSpace: 'pre-wrap',
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontSize: '19px',
            lineHeight: 1.421,
            fontWeight: 500,
            letterSpacing: '0.012em',
            margin: '0.3em 0 0 0',
            color: subtitleColor,
            whiteSpace: 'pre-wrap',
          }}
        >
          {subtitle}
        </p>
      </div>
      {children}
    </div>
  );
};

export default RoundedCard;
