// action types
export const GET_FROM_STORAGE = 'GET_FROM_STORAGE';
export const SET_TO_STORAGE = 'SET_TO_STORAGE';
export const REMOVE_FROM_STORAGE = 'REMOVE_FROM_STORAGE';

export const FOUND_IN_STORAGE = 'FOUND_IN_STORAGE';
export const NOT_FOUND_IN_STORAGE = 'NOT_FOUND_IN_STORAGE';

// action creators
export const getFromStorage = ({ storageKey, feature }) => ({
  type: `${feature} ${GET_FROM_STORAGE}`,
  payload: storageKey,
  meta: { feature },
});

export const setToStorage = ({ storageKey, storageValue, feature }) => ({
  type: `${feature} ${SET_TO_STORAGE}`,
  payload: { storageKey, storageValue },
  meta: { feature },
});

export const removeFromStorage = ({ storageKey, feature }) => ({
  type: `${feature} ${REMOVE_FROM_STORAGE}`,
  payload: storageKey,
  meta: { feature },
});

export const foundInStorage = ({ result, feature }) => ({
  type: `${feature} ${FOUND_IN_STORAGE}`,
  payload: result,
  meta: { feature },
});

export const notFoundInStorage = ({ feature }) => ({
  type: `${feature} ${NOT_FOUND_IN_STORAGE}`,
  meta: { feature },
});
