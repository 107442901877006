import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const SIGNIN_FEATURE = `${PAGES} [Signin]`;

// action types
export const START_SIGNIN_PAGE = `${SIGNIN_FEATURE} START`;
export const SIGNIN = `${SIGNIN_FEATURE} SIGNIN`;

// action creators
export const startSigninPage = () => ({
  type: START_SIGNIN_PAGE,
});

export const signin = ({ email, password }) => ({
  type: SIGNIN,
  payload: { email, password },
});
