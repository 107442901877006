import { PAGES } from 'redux/actions/pages';

// feature name
export const STUDENT_ASSIGNMENTS = `${PAGES} [StudentAssignments]`;

// action types
export const START_STUDENT_ASSIGNMENTS_PAGE = `${STUDENT_ASSIGNMENTS} START_PAGE`;
export const GET_STUDENT_ASSIGNMENTS = `${STUDENT_ASSIGNMENTS} GET_STUDENT_ASSIGNMENTS`;
export const MATERIAL_TASK_COMPLETED = `${STUDENT_ASSIGNMENTS} MATERIAL_TASK_COMPLETED`;

// action creators
export const startStudentAssignmentsPage = () => ({
  type: START_STUDENT_ASSIGNMENTS_PAGE,
});

export const getStudentAssignments = () => ({
  type: GET_STUDENT_ASSIGNMENTS,
});

export const materialTaskCompleted = ({ taskId }) => ({
  type: MATERIAL_TASK_COMPLETED,
  payload: { taskId },
});
