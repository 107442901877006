import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap';
import { CaretLeft } from 'phosphor-react';

import requireSpecificUserAuthentication from 'hocs/require-specific-user-authentication';
import {
  getStudentMessages,
  startStudentMessagesPage,
} from 'redux/actions/pages';
import StudentLoadingIndicator from './StudentLoadingIndicator';
import noMessageImage from 'assets/img/fall.svg';
import ReactAudioPlayer from 'react-audio-player';
// import backBtnImage from 'assets/img/geri-btn2.svg';

const StudentMessages = ({
  myMessages,
  getStudentMessages,
  loading,
  startStudentMessagesPage,
}) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    startStudentMessagesPage();

    getStudentMessages({ page: 1, size: 100 });

    function handleWindowHeight() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleWindowHeight);

    return () => {
      window.removeEventListener('resize', handleWindowHeight);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="student-messages">
        <StudentLoadingIndicator />
        <div className="go-back">
          <Link to={{ pathname: '/student' }}>
            <CaretLeft
              color="white"
              weight="bold"
              size={windowHeight <= 768 ? 40 : 80}
            />
          </Link>
        </div>
        {!loading ? (
          <div className="messages-container">
            <div className="scroll-container">
              <Container>
                <Row>
                  <Col
                    md={{ size: 8, offset: 2 }}
                    lg={{ size: 6, offset: 3 }}
                    xs={12}
                  >
                    {myMessages &&
                    myMessages.messages &&
                    myMessages.messages.length > 0 ? (
                      <div className="message-list">
                        {myMessages.messages.map((message) => (
                          <div className="message-item" key={message.id}>
                            <Card className="card-profile">
                              <div className="card-avatar">
                                <a
                                  href="#pablo"
                                  onClick={(e) => e.preventDefault()}
                                >
                                  <img
                                    style={{
                                      objectFit: 'cover',
                                      objectPosition: 'center',
                                      width: '130px',
                                      height: '130px',
                                      minWidth: '130px',
                                      minHeight: '130px',
                                      maxWidth: '130px',
                                      maxHeight: '130px',
                                    }}
                                    alt="..."
                                    className="img img-raised"
                                    src={message.fromUser.avatarUrl}
                                    // src={require('assets/img/olivia.jpg').default}
                                  ></img>
                                </a>
                              </div>
                              <CardBody>
                                {message.book && (
                                  <p
                                    className="card-description"
                                    style={{
                                      fontWeight: 500,
                                    }}
                                  >
                                    {`"${message.book?.title}"`}
                                  </p>
                                )}
                                <CardTitle tag="h3">
                                  {message.message}
                                </CardTitle>
                                {message.audioMessageUrl &&
                                message.audioMessageUrl !== '' ? (
                                  <ReactAudioPlayer
                                    src={message.audioMessageUrl}
                                    controls
                                  />
                                ) : null}
                                <CardFooter>
                                  <h6
                                    className="category text-info"
                                    style={{
                                      fontSize: '1.1em',
                                      fontWeight: 600,
                                    }}
                                  >
                                    {message.creationDate
                                      .split(' ')
                                      .join(' - ')}
                                  </h6>
                                </CardFooter>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-message">
                        <img src={noMessageImage} alt="Mesaj Yok!" />
                        <p>MESAJ YOK!</p>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  myMessages: state.data.myMessages,
  loading: state.ui.loading,
});

const mapDispatchToProps = {
  getStudentMessages,
  startStudentMessagesPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireSpecificUserAuthentication(['STUDENT'])(StudentMessages));
