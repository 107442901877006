import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import {
  User,
  EnvelopeSimple,
  Phone,
  MapPin,
  GitBranch,
  Suitcase,
  Fish,
} from 'phosphor-react';

import DropdownFixedNavbar from 'components/Custom/DropdownFixedNavbar';
import FooterBlack from 'components/Custom/FooterBlack';
import CustomAlert from './CustomAlert';
import CustomLoading from './CustomLoading';
import MessageTypes from 'enums/message-types';

import { connect } from 'react-redux';
import { SEND_CONTACT_FORM, sendContactForm } from 'redux/actions/pages';
import { removeMessage } from 'redux/actions/message';

function ContactPage({
  backendMessage,
  sendContactForm,
  removeMessage,
  localizations,
}) {
  const [nameFocus, setNameFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [numberFocus, setNumberFocus] = useState(false);

  const [rod, setRod] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.body.classList.add('contact-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('contact-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  useEffect(() => {
    if (
      backendMessage &&
      backendMessage.messageContent !== undefined &&
      backendMessage.messageContent !== null &&
      backendMessage.messageContent !== ''
    ) {
      Swal.fire({
        icon: backendMessage.messageType || 'error',
        title:
          backendMessage.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: backendMessage.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN,
        confirmButtonColor: '#3BA8FF',
      }).then((_) => {
        removeMessage(SEND_CONTACT_FORM);
        if (backendMessage.messageType === MessageTypes.success) {
          setRod('');
          setName('');
          setEmail('');
          setNumber('');
          setMessage('');
        }
      });
    }
    // eslint-disable-next-line
  }, [backendMessage]);

  return (
    <>
      <CustomAlert />
      <CustomLoading />
      <DropdownFixedNavbar />
      <div className="wrapper">
        <div className="section-space" />
        <div className="section">
          <div className="main">
            <div className="contact-content">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="5">
                    <h2 className="title">{localizations?.CONTACT_HEADER}</h2>
                    <p className="description">
                      {localizations?.CONTACT_DESC}
                      <br></br>
                      <br></br>
                    </p>
                    <Form
                      id="contact-form"
                      method="post"
                      role="form"
                      className="contact-form"
                    >
                      <label>
                        {localizations?.FORM_NAME}
                        <span> *</span>
                      </label>
                      <InputGroup style={{ display: 'none' }}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Fish color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label="Rod"
                          autoComplete="Rod"
                          placeholder="Fish Rod"
                          type="text"
                          value={rod}
                          onChange={(e) => setRod(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={nameFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <User color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label={localizations?.FORM_NAME}
                          autoComplete="name"
                          placeholder={localizations?.FORM_NAME}
                          type="text"
                          onFocus={() => setNameFocus(true)}
                          onBlur={() => setNameFocus(false)}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>
                        {localizations?.FORM_EMAIL}
                        <span> *</span>
                      </label>
                      <InputGroup
                        className={emailFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <EnvelopeSimple color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label={localizations?.FORM_EMAIL}
                          autoComplete="email"
                          placeholder={localizations?.FORM_EMAIL}
                          type="email"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>{localizations?.FORM_PHONE}</label>
                      <InputGroup
                        className={numberFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Phone color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="number"
                          placeholder={localizations?.FORM_PHONE}
                          type="text"
                          onFocus={() => setNumberFocus(true)}
                          onBlur={() => setNumberFocus(false)}
                          value={number}
                          onChange={(e) => setNumber(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <FormGroup>
                        <label>
                          {localizations?.FORM_MESSAGE}
                          <span> *</span>
                        </label>
                        <Input
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                          placeholder={localizations?.FORM_MESSAGE}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></Input>
                      </FormGroup>
                      <div className="submit text-center">
                        <Button
                          className="btn-raised btn-round"
                          color="info"
                          defaultValue={localizations?.FORM_SEND_BTN}
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            if (rod === '') {
                              sendContactForm({
                                name,
                                email,
                                phone: number,
                                message,
                              });
                            }
                          }}
                        >
                          {localizations?.FORM_SEND_BTN}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                  <Col className="ml-auto mr-auto" md="5">
                    <div className="info info-horizontal mt-5">
                      <div className="icon icon-info">
                        <MapPin size={28} weight="duotone" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">
                          {localizations?.CONTACT_HEAD_OFFICE_TITLE}
                        </h4>
                        <p style={{ whiteSpace: 'pre-line' }}>
                          {localizations?.CONTACT_HEAD_OFFICE_ADDRESS}
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal">
                      <div className="icon icon-info">
                        <GitBranch size={28} weight="duotone" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">
                          {localizations?.CONTACT_BRANCH_OFFICE_TITLE}
                        </h4>
                        <p style={{ whiteSpace: 'pre-line' }}>
                          {localizations?.CONTACT_BRANCH_OFFICE_ADDRESS}
                        </p>
                      </div>
                    </div>
                    <div className="info info-horizontal">
                      <div className="icon icon-info">
                        <Suitcase size={28} weight="duotone" />
                      </div>
                      <div className="description">
                        <h4 className="info-title">
                          {localizations?.CONTACT_COMPANY_TITLE}
                        </h4>
                        <p style={{ whiteSpace: 'pre-line' }}>
                          {localizations?.CONTACT_COMPANY_DETAILS}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <FooterBlack />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  backendMessage: state.message[SEND_CONTACT_FORM],
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  sendContactForm,
  removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
