import React from 'react';
import { useHistory } from 'react-router-dom';
import { X, CheckCircle, BookBookmark } from 'phosphor-react';
import { Col, Container, Row } from 'reactstrap';

import listenImage from 'assets/img/listen.svg';
import quizImage from 'assets/img/quiz.svg';
import readImage from 'assets/img/read.svg';
import recordImage from 'assets/img/record.svg';
import materialImage from 'assets/img/material.svg';
import BookActionTypes from 'enums/book-action-types';
import { connect } from 'react-redux';
import { materialTaskCompleted } from 'redux/actions/pages';
import BookStates from 'enums/book-states';

// Burada assignment id de READ Book path'ina state olarak
// yollanabilir. Böylece işlem tamamlandığında gerekli olan
// REDUX değişikliği yapılabilir.

// Burada aslında Material url getirilerek direkt yeni sayfada açılabilinir.

const AssignmentActionsModalContent = ({
  assignment,
  onClose,
  returnWithCurrentTask,
  materialTaskCompleted,
  localizations,
}) => {
  const history = useHistory();

  const onListenClick = (e, task) => {
    e.preventDefault();
    history.push({
      pathname: `/read/${assignment?.id}`,
      state: {
        actionType: BookActionTypes.listen,
        assignmentId: assignment?.assignmentId,
        hasAnyOtherIncompleteTask: assignment?.tasks
          ?.filter((task) => task.taskType !== BookActionTypes.listen)
          ?.some((task) =>
            task.elements.some((element) => element.isCompleted === false)
          ),
      },
    });
  };
  const onReadClick = (e, task) => {
    e.preventDefault();
    history.push({
      pathname: `/read/${assignment?.id}`,
      state: {
        actionType: BookActionTypes.read,
        assignmentId: assignment?.assignmentId,
        hasAnyOtherIncompleteTask: assignment?.tasks
          ?.filter((task) => task.taskType !== BookActionTypes.read)
          ?.some((task) =>
            task.elements.some((element) => element.isCompleted === false)
          ),
      },
    });
  };
  const onRecordClick = (e, task) => {
    e.preventDefault();
    history.push({
      pathname: `/read/${assignment?.id}`,
      state: {
        actionType: BookActionTypes.record,
        assignmentId: assignment?.assignmentId,
        hasAnyOtherIncompleteTask: assignment?.tasks
          ?.filter((task) => task.taskType !== BookActionTypes.record)
          ?.some((task) =>
            task.elements.some((element) => element.isCompleted === false)
          ),
      },
    });
  };
  const onQuizClick = (e, task) => {
    e.preventDefault();
    returnWithCurrentTask(task);
    // onClose();
  };
  const onMaterialClick = (e, task) => {
    e.preventDefault();
    if (task?.url && task.url !== '') {
      if (task?.taskId > 0) {
        materialTaskCompleted({ taskId: task.taskId });
      }

      window.open(task.url, '_blank');
    }
  };

  const taskTypeMapper = {
    [BookActionTypes.read]: {
      image: readImage,
      text: localizations?.ACTION_TYPE_READ ?? 'OKUMA',
      onClick: onReadClick,
    },
    [BookActionTypes.listen]: {
      image: listenImage,
      text: localizations?.ACTION_TYPE_LISTEN ?? 'DİNLEME',
      onClick: onListenClick,
    },
    [BookActionTypes.record]: {
      image: recordImage,
      text: localizations?.ACTION_TYPE_RECORD ?? 'OKU - KAYDET',
      onClick: onRecordClick,
    },
    [BookActionTypes.quiz]: {
      image: quizImage,
      text: localizations?.ACTION_TYPE_QUIZ ?? 'DEĞERLENDİRME',
      onClick: onQuizClick,
    },
    [BookActionTypes.material]: {
      image: materialImage,
      text: localizations?.ACTION_TYPE_MATERIAL ?? 'MATERYAL',
      onClick: onMaterialClick,
    },
  };

  return (
    <>
      <div className="modal-header justify-content-center my-3">
        <button
          aria-hidden={true}
          className="close"
          onClick={onClose}
          type="button"
        >
          <X color="red" size={40} />
        </button>
      </div>
      <div className="modal-body mb-4">
        <Container>
          <Row className="no-gutters">
            <Col xs={6}>
              <img src={assignment?.coverImage} alt="" />
              <div className="assignment-due-date">
                <span className="font-luckiest-guy">
                  {localizations?.ASSIGNMENT_DEADLINE ?? 'TESLİM TARİHİ'}:{' '}
                  {assignment?.dueDate}
                </span>
              </div>
            </Col>
            <Col xs={6}>
              {Object.values(BookActionTypes).map((actionType) => {
                const releatedTask = assignment?.tasks?.find(
                  (task) => task.taskType === actionType
                );

                if (releatedTask?.elements?.length > 0) {
                  return releatedTask.elements.map((task, index) => {
                    return (
                      <div
                        key={task.taskId}
                        className="assignment-action-item"
                        onClick={(e) => {
                          const returnedTask = {
                            ...task,
                            taskType: releatedTask.taskType,
                          };
                          return taskTypeMapper[actionType].onClick(
                            e,
                            returnedTask
                          );
                        }}
                      >
                        <img
                          src={taskTypeMapper[actionType].image}
                          alt={taskTypeMapper[actionType].text}
                        />
                        <span className="font-luckiest-guy">
                          {releatedTask.elements.length > 1
                            ? `${taskTypeMapper[actionType].text} ${index + 1}`
                            : taskTypeMapper[actionType].text}
                        </span>
                        {task.isCompleted ? (
                          <CheckCircle
                            color="#06d6a0"
                            size={30}
                            weight="duotone"
                          />
                        ) : (releatedTask.taskType === BookActionTypes.read &&
                            assignment?.pageLocation > 0) ||
                          (releatedTask.taskType === BookActionTypes.listen &&
                            assignment?.listenPageLocation > 0) ? (
                          <BookBookmark
                            color={BookStates.notCompleted.color}
                            size={30}
                            weight="duotone"
                          />
                        ) : null}
                      </div>
                    );
                  });
                }

                return null;
              })}
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});
const mapDispatchToProps = {
  materialTaskCompleted,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignmentActionsModalContent);
