import { PAGES } from 'redux/actions/pages';

// feature name
export const STUDENT_MESSAGES = `${PAGES} [StudentMessages]`;

// action types
export const START_STUDENT_MESSAGES_PAGE = `${STUDENT_MESSAGES} START_PAGE`;
export const GET_STUDENT_MESSAGES = `${STUDENT_MESSAGES} GET_MESSAGES`;

// action creators
export const startStudentMessagesPage = () => ({
  type: START_STUDENT_MESSAGES_PAGE,
});
export const getStudentMessages = ({ page, size }) => ({
  type: GET_STUDENT_MESSAGES,
  payload: !page || !size ? {} : { page, size },
});
