import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import CloseKediImage from 'assets/img/close-kedi-sag.png';
import ToolIconButton from './ToolIconButton';
import ReactAudioPlayer from 'react-audio-player';
import {
  startReadBookPage,
  saveUserSoundUrl,
  setUserBookmark,
  removeUserBookmark,
  setBookLastPage,
  closeCurrentBook,
  READ_BOOK,
  getAudioUploadParams,
  removeAudioUploadParams,
} from 'redux/actions/pages';
import { Howl, Howler } from 'howler';

import ImageZoom from 'components/Custom/ImageZoom';
import CustomPopover from './CustomPopover';
import { Button, Input } from 'reactstrap';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { removeMessage } from 'redux/actions/message';

import {
  Play,
  Pause,
  SpeakerNone,
  SpeakerHigh,
  SpeakerLow,
  Microphone,
  Record,
  Pencil,
  EyeClosed,
  Eye,
  BookmarkSimple,
  CaretRight,
  CaretLeft,
  MagnifyingGlassPlus,
  MagnifyingGlassMinus,
  XCircle,
  X,
  PaperPlaneTilt,
  Binoculars,
  PauseCircle,
  PlayCircle,
  StopCircle,
  DownloadSimple,
} from 'phosphor-react';
import { connect } from 'react-redux';
import Slider from 'nouislider';
import CustomModal from './CustomModal';
import MessageTypes from 'enums/message-types';
import CustomAlert from './CustomAlert';
import Dictionary from './Dictionary';
import requireAuthentication from 'hocs/require-authentication';
import BookActionTypes from 'enums/book-action-types';
import { useAudioRecorder } from 'hooks/use-audio';
import RecordStatusTypes from 'enums/record-status-types';

const ReadBook = ({
  book,
  loading,
  message,
  startReadBookPage,
  saveUserSoundUrl,
  setUserBookmark,
  removeUserBookmark,
  setBookLastPage,
  removeMessage,
  match,
  user,
  localizations,
  closeCurrentBook,
  getAudioUploadParams,
  removeAudioUploadParams,
  uploadParameters,
}) => {
  const location = useLocation();
  const history = useHistory();

  const [currentLocation, setCurrentLocation] = useState(0);
  const [soundRecordModal, setSoundRecordModal] = useState(false);
  const [resumeBookModal, setResumeBookModal] = useState(false);
  const [isResumeBookModalOpened, setIsResumeBookModalOpened] = useState(false);
  const [retryModal, setRetryModal] = useState(false);
  const [isDictionaryOpened, setIsDictionaryOpened] = useState(true);
  const [isTextOpened, setIsTextOpened] = useState(true);
  const [isZoomOpened, setIsZoomOpened] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [canChangePage, setCanChangePage] = useState(true);
  const [refresh, setRefresh] = useState(0);
  const [refreshForRecorder, setRefreshForRecorder] = useState(0);
  const [bookmarkList, setBookmarkList] = useState([]);
  // const [tempId, setTempId] = useState(bookmarkList.length + 1); // TODO: Geçici çözüm...
  const [currentTime, setCurrentTime] = useState(0);
  const [pageIndex, setPageIndex] = useState(-1);

  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(null);

  const [isVolumeControlPopoverShown, setIsVolumeControlPopoverShown] =
    useState(false);
  const [isBookmarkPopoverShown, setIsBookmarkPopoverShown] = useState(false);
  const [isRecorderPopoverShown, setIsRecorderPopoverShown] = useState(false);

  const [actionType, setActionType] = useState(null);

  const bookRef = useRef(null);
  const prevButtonRef = useRef(null);
  const nextButtonRef = useRef(null);
  const headerItemsRef = useRef(null);

  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioData,
    audioResult,
    errorMessage,
    removeAudioData,
  } = useAudioRecorder();

  const pageToPaper = (pageArray) => {
    const result = pageArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = [];
      }

      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);

    return { papers: result, length: result.length };
  };

  const openBook = () => {
    bookRef.current.style.transform = 'translateX(50%)';

    // { page-width / 2 - button-width }
    // 420 / 2 - 60 = 150
    prevButtonRef.current.style.transform = 'translateX(-150em)';
    nextButtonRef.current.style.transform = 'translateX(150em)';
  };

  const closeBook = (isAtBeginning) => {
    if (isAtBeginning) {
      bookRef.current.style.transform = 'translateX(0%)';
    } else {
      bookRef.current.style.transform = 'translateX(100%)';
    }
    prevButtonRef.current.style.transform = 'translateX(0em)';
    nextButtonRef.current.style.transform = 'translateX(0em)';
  };

  const handleNextPage = (disableCanChange = false) => {
    if (!disableCanChange && playing) {
      return;
    }

    if (isBookmarkPopoverShown) {
      return;
    }

    if (canChangePage || disableCanChange) {
      changeBookmarkTypesToExisting();

      if (currentLocation < book.pages.length / 2) {
        setCanChangePage(false);
        if (currentLocation === 0) {
          openBook();
        }

        const paperEl = document.querySelector(`#paper${currentLocation}`);
        paperEl.classList.add('flipped');
        setTimeout(() => {
          paperEl.style.zIndex = currentLocation + 1;
          if (currentLocation === 0) {
            paperEl.querySelector('.back').style.boxShadow =
              '-10em 10em 15em grey';
          }
          setCanChangePage(true);
        }, 750);
        if (currentLocation === book.pages.length / 2 - 1) {
          closeBook(false);
          if (
            (location.state?.actionType === BookActionTypes.read &&
              book &&
              !book.isBookReaded) ||
            (location.state?.actionType === BookActionTypes.listen &&
              book &&
              !book.isBookListened)
          ) {
            setRetryModal(true);
          } else {
            if (
              location.state?.actionType === BookActionTypes.read ||
              location.state?.actionType === BookActionTypes.listen
            ) {
              // konfetti
            }
          }
        }
        setCurrentLocation(currentLocation + 1);
      }
    }
  };

  const handleGoPage = (e) => {
    e.preventDefault();

    if (playing) return;

    if (isBookmarkPopoverShown) {
      return;
    }

    gotoPage(parseInt(e.target.value));
  };

  const gotoPage = (pageNumber) => {
    if (canChangePage) {
      changeBookmarkTypesToExisting();
      const currentLoc = currentLocation;
      let futureLoc;
      if (pageNumber > book.pages.length - 3) {
        futureLoc = Math.floor(book.pages.length / 2);
      } else {
        futureLoc = Math.floor(pageNumber / 2) + 1;
      }

      if (futureLoc > currentLoc) {
        for (let i = currentLoc; i < futureLoc; i++) {
          if (i === 0) {
            openBook();
          }
          const paperEl = document.querySelector(`#paper${i}`);
          paperEl.classList.add('flipped');
          paperEl.style.zIndex = 1 + i;

          if (i === 0) {
            paperEl.querySelector('.back').style.boxShadow =
              '-10em 10em 15em grey';
          }

          if (i === book.pages.length / 2 - 1) {
            closeBook(false);
          }
        }
        setCurrentLocation(futureLoc);
      } else if (futureLoc < currentLoc) {
        for (let i = currentLoc; i > futureLoc; i--) {
          if (i === 1) {
            closeBook(true);
          }
          const paperEl = document.querySelector(`#paper${i - 1}`);
          paperEl.classList.remove('flipped');
          paperEl.style.zIndex = book.pages.length + 2 - i;

          if (i === 1) {
            paperEl.querySelector('.back').style.boxShadow = '';
          }

          if (i === book.pages.length / 2) {
            openBook(false);
          }
        }
        setCurrentLocation(futureLoc);
      } else {
        console.log('No change');
      }
    }
  };

  const handlePrevPage = (e) => {
    if (playing) return;

    if (isBookmarkPopoverShown) {
      return;
    }

    if (canChangePage) {
      changeBookmarkTypesToExisting();
      if (currentLocation > 0) {
        if (currentLocation === 1) {
          closeBook(true);
        }

        const paperEl = document.querySelector(`#paper${currentLocation - 1}`);
        paperEl.classList.remove('flipped');
        paperEl.style.zIndex = book.pages.length + 2 - currentLocation;

        if (currentLocation === 1) {
          paperEl.querySelector('.back').style.boxShadow = '';
        }

        if (currentLocation === book.pages.length / 2) {
          openBook(false);
        }
        setCurrentLocation(currentLocation - 1);
      }
    }
  };

  const onSendSoundClick = (e) => {
    e.preventDefault();
    setSoundRecordModal(false);
    handleUploadSound();
  };

  const onDownloadSoundClick = (e) => {
    e.preventDefault();

    const blobUrl = window.URL.createObjectURL(audioData);
    let a = document.createElement('a');
    a.href = blobUrl;
    a.download = 'user-record.mp4';
    a.click();
  };

  const onMicrophoneClick = (e) => {
    startRecording();
  };

  // const onRecordClick = (e) => {
  //   stopRecording();
  //   setSoundRecordModal(true);
  // };

  const onPauseClick = (e) => {
    pauseRecording();
  };
  const onResumeClick = (e) => {
    resumeRecording();
  };

  const onStopClick = (e) => {
    stopRecording();
    setSoundRecordModal(true);
    setIsRecorderPopoverShown(false);
    setRefreshForRecorder((prev) => prev + 1);
  };

  const handleUploadSound = () => {
    const fileType = 'audio/mpeg';
    const extention = 'mp3';
    const folderName = 'usersoundfiles';
    getAudioUploadParams({ type: fileType, folder: folderName, extention });
  };

  const checkForResumeReading = (currentBook) => {
    return (
      currentBook?.resumePageLocation > 1 &&
      (!location.state?.actionType ||
        location.state?.actionType === BookActionTypes.read)
    );
  };

  const checkForResumeListening = (currentBook) => {
    return (
      currentBook?.resumeListenPageLocation > 1 &&
      (!location.state?.actionType ||
        location.state?.actionType === BookActionTypes.listen)
    );
  };

  const CriteriaTypes = Object.freeze({
    word: 'word',
    sentence: 'sentence',
  });

  const getCorrectIndexOfArrayWithCriteria = (
    indexOfWord,
    arr,
    criteriaType
  ) => {
    let correctIndex = -1;
    let index = -1;
    while (index !== indexOfWord) {
      correctIndex++;
      if (correctIndex < arr.length) {
        if (criteriaType === CriteriaTypes.word) {
          if (arr[correctIndex][0] !== '<') {
            index++;
          }
        } else {
          if (
            arr[correctIndex] !== '' &&
            arr[correctIndex] !== ' ' &&
            arr[correctIndex] !== '  '
          ) {
            index++;
          }
        }
      }
    }
    return correctIndex;
  };

  const highlightText = (text, index) => {
    // 1. Word

    // Check if any word must be highlighted - if not return with original text
    const currentWordObject = book.pages[index].wordHighlights?.find(
      (j) => j.startTime <= currentTime && j.endTime > currentTime
    );

    if (!currentWordObject) {
      return text;
    }

    // Add space before/after p tags and split with space
    const wordTextArray = text
      .replace(/<p>/g, '<p> ')
      .replace(/<\/p>/g, ' </p>')
      .split(' ');

    // Find the word current index after replacements
    const wordIndex = getCorrectIndexOfArrayWithCriteria(
      currentWordObject.sequence,
      wordTextArray,
      CriteriaTypes.word
    );

    // Wrap the word with mark tags
    wordTextArray[
      wordIndex
    ] = `<mark class="highlight-word">${wordTextArray[wordIndex]}</mark>`;

    // Join word array with space and undo p tag changes
    const endOfWord = wordTextArray
      .join(' ')
      .replace(/<p> /g, '<p>')
      .replace(/ <\/p>/g, '</p>');

    // 2. Sentence

    // Check if any sentence must be highlighted - if not return with original text
    const currentSentenceObject = book.pages[index].sentenceHighlights?.find(
      (j) => j.startTime <= currentTime && j.endTime > currentTime
    );

    if (!currentSentenceObject) {
      return text;
    }

    // Firstly, replace p tags with # and save seperators in array
    const pTagSeperators = [];
    const textAfterPTagReplacements = endOfWord.replace(/<p>|<\/p>/g, (sp) => {
      pTagSeperators.push(sp);
      return ' # ';
    });

    // Secondly, replace any html tags with % and save sepeators in array
    const htmlTagSeperators = [];
    const textAfterHtmlTagReplacements = textAfterPTagReplacements.replace(
      /<.*?>|<\/.*?>/g,
      (sp) => {
        htmlTagSeperators.push(sp);
        return ' % ';
      }
    );

    // Thirdly, replace end of sentence punctuations with & and save seperators in array
    const punctuationSeperators = [];
    const textAfterPunctuationReplacements =
      textAfterHtmlTagReplacements.replace(
        /!"|\?"|\."|\?|!|\.\.\.|\./g,
        (sp) => {
          punctuationSeperators.push(sp);
          return ' & ';
        }
      );

    // Lastly, replace # or & chars with @ and save seperators in array
    const seperationCharSeperators = [];
    const textAfterSeperationCharReplacements =
      textAfterPunctuationReplacements.replace(/ # | & /g, (sp) => {
        seperationCharSeperators.push(sp);
        return '@';
      });

    // Split repleced text with @
    const sentenceTextArray = textAfterSeperationCharReplacements.split('@');

    // Find the sentence current index
    const sentenceIndex = getCorrectIndexOfArrayWithCriteria(
      currentSentenceObject.sequence,
      sentenceTextArray,
      CriteriaTypes.sentence
    );

    // Wrap the sentence with mark tags
    sentenceTextArray[
      sentenceIndex
    ] = `<mark class="highlight-sentence">${sentenceTextArray[sentenceIndex]}</mark>`;

    // Join and undo replacements
    let seperationCharIndex = 0;
    let punctuationIndex = 0;
    let htmlTagIndex = 0;
    let pTagIndex = 0;

    const result = sentenceTextArray
      .join('@')
      // Undo Seperation Char Replacement
      .replace(/@/g, (_) => {
        const result = seperationCharSeperators[seperationCharIndex];
        seperationCharIndex++;
        return result;
      })
      // Undo Punctuation Replacement
      .replace(/ & /g, (_) => {
        const result = punctuationSeperators[punctuationIndex];
        punctuationIndex++;
        return result;
      })
      // Undo Html Tags Replacement
      .replace(/ % /g, (_) => {
        const result = htmlTagSeperators[htmlTagIndex];
        htmlTagIndex++;
        return result;
      })
      // Undo P Tags Replacemnet
      .replace(/#/g, (_) => {
        const result = pTagSeperators[pTagIndex];
        pTagIndex++;
        return result;
      });
    return result;
  };

  const buildBook = () => {
    const { papers, length } = pageToPaper(book.pages);
    return papers.map((p, i) => buildPaper(p, i, length));
  };

  const buildPaper = (paper, index, maxCount) => {
    return (
      <div
        className="paper"
        id={`paper${index}`}
        style={{ zIndex: 2 * maxCount + 1 - index }}
        key={index}
      >
        <div
          className="front"
          style={
            index === maxCount - 1 ? { boxShadow: '10em 10em 15em grey' } : {}
          }
        >
          <div className="front-content">
            <div
              className="image"
              style={{ width: '420em' /*, height: '467em' */ }}
            >
              {isZoomOpened ? (
                <ImageZoom src={paper[0].image} alt="" />
              ) : (
                <img src={paper[0].image} alt="" />
              )}
            </div>

            <div className="text-area">
              {isTextOpened ? (
                <>
                  <div className="page-number to-left" />
                  <div
                    className={`text-content ${paper[0].textStyle?.toLowerCase()}`}
                    dangerouslySetInnerHTML={{
                      __html:
                        pageIndex === 2 * index && playing && paper[0].text
                          ? highlightText(paper[0].text, pageIndex)
                          : paper[0].text,
                    }}
                    // dangerouslySetInnerHTML={{ __html: paper[0].text }}
                  />
                  <div className="page-number to-right">
                    <span>
                      {paper[0].pageNumber !== 0 ? paper[0].pageNumber : ''}
                    </span>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
        <div className="back">
          <div className="back-content">
            <div
              className="image"
              style={{ width: '420em' /*, height: '467em' */ }}
            >
              {isZoomOpened ? (
                <ImageZoom src={paper[1].image} alt="" />
              ) : (
                <img src={paper[1].image} alt="" />
              )}
            </div>
            <div className="text-area">
              {isTextOpened ? (
                <>
                  <div className="page-number to-left">
                    <span>
                      {paper[1].pageNumber !== 0 ? paper[1].pageNumber : ''}
                    </span>
                  </div>
                  <div
                    className={`text-content ${paper[1].textStyle?.toLowerCase()}`}
                    dangerouslySetInnerHTML={{
                      __html:
                        pageIndex === index * 2 + 1 && playing && paper[1].text
                          ? highlightText(paper[1].text, pageIndex)
                          : paper[1].text,
                    }}
                  />
                  <div className="page-number to-right" />
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const checkAppendBookmarkButtonEnabled = () => {
    if (currentLocation === 0) {
      return false;
    }

    if (currentLocation === book.pages.length / 2) {
      return false;
    }

    if (currentLocation === book.pages.length / 2 - 1) {
      const page = book.pages.find(
        (p) => p.pageNumber === currentLocation * 2 - 2
      );
      if (page) {
        if (bookmarkList.some((b) => b.pageId === page.id)) {
          return false;
        } else {
          return true;
        }
      }
    }

    const page = book.pages.find(
      (p) => p.pageNumber === currentLocation * 2 - 1
    );
    if (page) {
      if (bookmarkList.some((b) => b.pageId === page.id)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const checkRemoveBookmarkButtonEnabled = () => {
    if (currentLocation === 0) {
      return false;
    }

    if (currentLocation === book.pages.length / 2) {
      return false;
    }

    if (currentLocation === book.pages.length / 2 - 1) {
      const page = book.pages.find(
        (p) => p.pageNumber === currentLocation * 2 - 2
      );
      if (page) {
        if (bookmarkList.some((b) => b.pageId === page.id)) {
          return true;
        } else {
          return false;
        }
      }
    }

    const page = book.pages.find(
      (p) => p.pageNumber === currentLocation * 2 - 1
    );
    if (page) {
      if (bookmarkList.some((b) => b.pageId === page.id)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const changeBookmarkTypesToExisting = () => {
    let copyOfBookmarks = [...bookmarkList];
    let changed = false;
    copyOfBookmarks = copyOfBookmarks.map((b) => {
      if (b.type !== 'existing') {
        changed = true;
        if (b.id) {
          setUserBookmark({
            id: b.id,
            bookmarkText: b.bookmarkText,
          });
        } else {
          setUserBookmark({
            bookId: book.id,
            pageId: b.pageId,
            bookmarkText: b.bookmarkText,
          });
        }
        // DB'e değişikliği yolla...

        return { ...b, type: 'existing' };
      }
      return b;
    });

    if (changed) {
      setBookmarkList(copyOfBookmarks);
    }
  };

  useEffect(() => {
    if (currentLocation > 0 && actionType === 'LISTEN') {
      setPlaying(true);
      setActionType(null);
    }
  }, [actionType, currentLocation]);

  useEffect(() => {
    setActionType(location.state?.actionType);
  }, [location.state?.actionType]);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    appHeight();
    window.addEventListener('resize', appHeight);

    const handleKeyDown = (e) => {
      if (!playing) {
        switch (e.keyCode) {
          case 37:
            handlePrevPage();
            break;
          // case 38:
          //   alert('up');
          //   break;
          case 39:
            handleNextPage();
            break;
          // case 40:
          //   alert('down');
          //   break;
          default:
            break;
        }
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    let timerId;
    if (book && book.id > 0 && audio) {
      timerId = setInterval(() => {
        setCurrentTime(audio.seek());
      }, 50);
    }

    return (_) => {
      window.removeEventListener('resize', appHeight);
      document.removeEventListener('keydown', handleKeyDown);
      if (timerId) {
        clearInterval(timerId);
      }
    };
  });

  useEffect(() => {
    if (match?.params?.id && match?.params?.id !== '') {
      const id = parseInt(match.params.id);
      if (book && book.id && book.id === id) {
        setBookmarkList(
          book.userBookmarks
            .sort((a, b) => {
              const pageIndexOfA = book.pages?.findIndex(
                (p) => p.id === a.pageId
              );
              const pageIndexOfB = book.pages?.findIndex(
                (p) => p.id === b.pageId
              );
              return (
                book.pages[pageIndexOfA]?.pageNumber -
                book.pages[pageIndexOfB]?.pageNumber
              );
            })
            .map((b) => {
              return { ...b, type: 'existing' };
            })
        );
        if ((book.pages.length - 3) / 2 > book.resumePageLocation) {
          if (
            (book.resumePageLocation > 1 &&
              (!location.state?.actionType ||
                location.state?.actionType === BookActionTypes.read)) ||
            (book.resumeListenPageLocation > 1 &&
              (!location.state?.actionType ||
                location.state?.actionType === BookActionTypes.listen))
          ) {
            if (!isResumeBookModalOpened) {
              setResumeBookModal(true);
              setIsResumeBookModalOpened(true);
            }
          } else {
            setIsResumeBookModalOpened(true);

            if (location.state?.actionType === BookActionTypes.listen) {
              setPlaying(true);
              setActionType(null);
            }
          }
          // gotoPage(book.resumePageLocation * 2 - 1);
        }
      } else {
        if (user && user.id > 0) {
          startReadBookPage({ bookId: id });
        }
      }
    } else {
      // REDIRECT TO BOOK LIST
      history.push('/');
    }
    // eslint-disable-next-line
  }, [book?.id, book?.userBookmarks, user?.id]);

  useEffect(() => {
    if (
      !document.getElementById('sliderVolume').classList.contains('noUi-target')
    ) {
      Slider.create(document.getElementById('sliderVolume'), {
        start: [50],
        connect: [false, true],
        step: 1,
        range: { min: 0, max: 100 },
      }).on('update', function (values) {
        Howler.volume(Math.round(values[0]) * 0.01);
      });
    }
    document.body.classList.add('read-book-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('read-book-page');
      document.body.classList.remove('sidebar-collapse');
      Howler.unload();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log('useEffect called...');
    let timeoutId;
    if (!playing) {
      if (book && book.id > 0 && book.pages && book.pages.length > 0) {
        if (!resumeBookModal && currentLocation !== book.resumePageLocation) {
          if (currentLocation >= 1 && currentLocation < book.pages.length / 2) {
            timeoutId = setTimeout(() => {
              setBookLastPage({
                bookId: book.id,
                pageLocation: currentLocation,
              });
            }, 5000);
          }
        }
      }
    }
    return () => {
      if (timeoutId) {
        clearInterval(timeoutId);
      }
    };
    // eslint-disable-next-line
  }, [book?.id, currentLocation]);

  useEffect(() => {
    if (book && book.id > 0 && book.pages && book.pages.length > 0) {
      // Page Number Setting
      let pageNumber = '';
      if (
        currentLocation * 2 - 1 <= book.pages.length - 3 &&
        currentLocation * 2 - 1 >= 1
      ) {
        pageNumber =
          currentLocation * 2 - 1 === book.pages.length - 3
            ? book.pages.length - 4
            : currentLocation * 2 - 1;
      }
      setPageNum(pageNumber);

      // Change Page and play next audio
      if (currentLocation <= 1) {
        if (book.pages[currentLocation * 2].sound) {
          const newSound = new Howl({
            src: [book.pages[currentLocation * 2].sound],
          });

          newSound.once('end', () => {
            handleNextPage(true);
            setBookLastPage({
              bookId: book.id,
              pageLocation: currentLocation + 1,
              isListening: true,
            });
          });
          setCurrentTime(0);
          setAudio(newSound);
          setPageIndex(currentLocation * 2);
        } else {
          setAudio(null);
        }
      } else if (currentLocation < book.pages.length / 2 - 1) {
        if (book.pages[currentLocation * 2 - 1].sound) {
          const evenSound = new Howl({
            src: [book.pages[currentLocation * 2 - 1].sound],
          });

          if (book.pages[currentLocation * 2].sound) {
            const oddSound = new Howl({
              src: [book.pages[currentLocation * 2].sound],
            });

            evenSound.once('end', () => {
              setCurrentTime(0);
              setAudio(oddSound);
              setPageIndex(currentLocation * 2);
            });

            oddSound.once('end', () => {
              setBookLastPage({
                bookId: book.id,
                pageLocation: currentLocation + 1,
                isListening: true,
              });
              handleNextPage(true);
            });
          }

          setCurrentTime(0);
          setAudio(evenSound);
          setPageIndex(currentLocation * 2 - 1);
        }
      } else {
        if (book.pages[currentLocation * 2 - 1].sound) {
          const newSound = new Howl({
            src: [book.pages[currentLocation * 2 - 1].sound],
          });

          newSound.once('end', () => {
            setBookLastPage({
              bookId: book.id,
              pageLocation: currentLocation + 1,
              isListening: true,
            });
            handleNextPage(true);
            setPlaying(false);
            setAudio(null);
          });
          setCurrentTime(0);
          setAudio(newSound);
          setPageIndex(currentLocation * 2 - 1);
        }
      }
    }
    // eslint-disable-next-line
  }, [book?.id, book?.pages, currentLocation]);

  useEffect(() => {
    if (
      uploadParameters &&
      uploadParameters.key &&
      uploadParameters.key !== '' &&
      uploadParameters.url &&
      uploadParameters.url !== ''
    ) {
      const { key, url } = uploadParameters;
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', `${url}`);
      xhr.setRequestHeader('Content-Type', 'audio/mpeg');
      xhr.setRequestHeader('x-amz-acl', 'public-read');
      xhr.send(audioData);
      xhr.onload = (e) => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            const edictSoundUrl = `https://edictdigitalspaces.fra1.digitaloceanspaces.com/${key}`;
            saveUserSoundUrl({
              bookId: book.id,
              audioUrl: edictSoundUrl,
            });

            removeAudioUploadParams();
            removeAudioData();
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr.statusText);
          }
        }
      };
      xhr.onerror = (e) => {
        // eslint-disable-next-line no-console
        console.error('on sound upload error');
        // eslint-disable-next-line no-console
        console.error(xhr.statusText);
      };
      xhr.onloadend = (ev) => {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadParameters]);

  useEffect(() => {
    if (playing) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [audio, playing]);

  useEffect(() => {
    if (isDictionaryOpened) {
      headerItemsRef.current.style.width = '1040em';
    } else {
      headerItemsRef.current.style.width = '840em';
    }
  }, [isDictionaryOpened]);

  useEffect(() => {
    if (errorMessage && errorMessage !== '') {
      Swal.fire({
        icon: 'error',
        title: localizations?.MESSAGE_ERROR,
        text: localizations?.MESSAGE_START_AUDIO_ERROR_TEXT,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN,
      }).then((_) => {
        removeAudioData();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessage]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ''
    ) {
      Swal.fire({
        icon: message.messageType || 'error',
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_READ_BOOK_CONFIRM_BTN,
      }).then((_) => {
        removeMessage(READ_BOOK);
        history.push('/');
      });
    }
    // eslint-disable-next-line
  }, [message]);

  return (
    <>
      <CustomAlert />
      {loading ? (
        <div className="loading whole-screen">
          <PacmanLoader
            color="#67BCEC"
            // loading={loading}
            // css={override}
            size={50}
          />
        </div>
      ) : null}
      <div className="wrapper">
        <div className="main">
          <div className="read-book-section">
            <div className="main-section">
              <div className="main-header">
                <div className="header-items" ref={headerItemsRef}>
                  <div className="header-item">
                    {/* <img src={OkaImage} alt="" /> */}
                  </div>
                  <div
                    className="header-item"
                    style={{ marginBottom: '-10em' }}
                    onClick={() => {
                      closeCurrentBook({
                        bookId: book.id,
                        pageLocation: book.resumePageLocation,
                        listenPageLocation: book.resumeListenPageLocation,
                      });

                      if (location.state) {
                        const {
                          actionType,
                          assignmentId,
                          bookId,
                          hasAnyOtherIncompleteTask,
                        } = location.state;

                        if (assignmentId > 0) {
                          history.push({
                            pathname: '/assignments',
                            state: {
                              assignmentId,
                              showModal:
                                hasAnyOtherIncompleteTask ||
                                (actionType === 'LISTEN' &&
                                  book.resumeListenPageLocation > 0) ||
                                (actionType === 'READ' &&
                                  book.resumePageLocation > 0),
                            },
                          });
                        } else if (bookId > 0) {
                          history.push({
                            pathname: '/books',
                            state: {
                              bookId,
                              showModal:
                                hasAnyOtherIncompleteTask ||
                                (actionType === 'LISTEN' &&
                                  book.resumeListenPageLocation > 0) ||
                                (actionType === 'READ' &&
                                  book.resumePageLocation > 0),
                            },
                          });
                        } else {
                          history.push({ pathname: '/' });
                        }
                      } else {
                        window.opener = null;
                        window.open('', '_self');
                        window.close();

                        history.push({ pathname: '/' });
                      }
                    }}
                  >
                    {/* <Link
                      to={{
                        pathname: '/book-list',
                      }}
                    > */}
                    <img src={CloseKediImage} alt="" />
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              {book && book.id && book.id > 0 ? (
                <div className="main-body">
                  <div className="main-book">
                    <button
                      className="btn book-button"
                      ref={prevButtonRef}
                      onClick={(e) => handlePrevPage()}
                    >
                      {isZoomOpened ? null : (
                        <CaretLeft
                          className="icon-size60 next-prev-button"
                          color="black"
                          weight="fill"
                        />
                      )}
                    </button>
                    <div className="book" ref={bookRef}>
                      {buildBook()}
                    </div>
                    <button
                      className="btn book-button"
                      ref={nextButtonRef}
                      onClick={(e) => handleNextPage()}
                    >
                      {isZoomOpened ? null : (
                        <CaretRight
                          className="icon-size60 next-prev-button"
                          color="black"
                          weight="fill"
                        />
                      )}
                    </button>
                  </div>
                  {isDictionaryOpened ? (
                    <div
                      className="main-dictionary"
                      style={{
                        paddingLeft: '5em',
                        paddingRight: '5em',
                      }}
                    >
                      {/* <img src={DictionaryImage} alt="" /> */}
                      <Dictionary
                        pageIds={
                          currentLocation > 0 &&
                          currentLocation < book?.pages.length / 2
                            ? [
                                book?.pages[2 * currentLocation - 1]?.id ?? [],
                                book?.pages[2 * currentLocation]?.id ?? [],
                              ]
                            : []
                        }
                      />
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
            <div className="tools-menu">
              <div className="icon-button-group">
                <ToolIconButton
                  size={40}
                  color={playing ? '#B3DEF6' : '#67BCEC'}
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  {playing ? (
                    <Pause
                      className="icon-size20"
                      weight="duotone"
                      color="white"
                      onClick={() => {
                        setPlaying(false);
                      }}
                    />
                  ) : (
                    <Play
                      className="icon-size20"
                      weight="duotone"
                      color="white"
                      onClick={() => {
                        if (audio) {
                          setPlaying(true);
                        } else {
                          Swal.fire({
                            icon: 'info',
                            title: localizations?.MESSAGE_PLAY_WARNING_TITLE,
                            text: localizations?.MESSAGE_PLAY_WARNING_TEXT,
                            showCancelButton: false,
                            confirmButtonText:
                              localizations?.MESSAGE_CONFIRM_BTN,
                          });
                        }
                      }}
                    />
                  )}
                </ToolIconButton>
                {/* <ToolIconButton
                  size={40}
                  onButtonClick={(e) => e.preventDefault()}
                  color={
                    status === RecordStatusTypes.RECORDING
                      ? '#B3DEF6'
                      : '#67BCEC'
                  }
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  {status === RecordStatusTypes.IDLE ? (
                    <Microphone
                      className="icon-size20"
                      weight="duotone"
                      color="white"
                      onClick={onMicrophoneClick}
                    />
                  ) : (
                    <div
                      style={{
                        width: '20em',
                        height: '20em',
                        borderRadius: '10em',
                        backgroundColor: '#DD6363',
                      }}
                    >
                      <Record
                        className="icon-size20"
                        weight="duotone"
                        color="white"
                        onClick={onRecordClick}
                      />
                    </div>
                  )}
                </ToolIconButton> */}

                <CustomPopover
                  containerStyle={{ minWidth: '100em' }}
                  name="recorder"
                  setIsShown={setIsRecorderPopoverShown}
                  toggle={refreshForRecorder}
                  trigger={
                    <ToolIconButton
                      size={40}
                      // onButtonClick={(e) => e.preventDefault()}
                      color={
                        status === RecordStatusTypes.RECORDING
                          ? '#B3DEF6'
                          : '#67BCEC'
                      }
                      boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                      borderRadius="15em"
                    >
                      {status === RecordStatusTypes.IDLE ? (
                        <Microphone
                          className="icon-size20"
                          weight="duotone"
                          color="white"
                          onClick={onMicrophoneClick}
                        />
                      ) : (
                        <div
                          style={{
                            width: '20em',
                            height: '20em',
                            borderRadius: '10em',
                            backgroundColor: '#DD6363',
                          }}
                        >
                          <Record
                            className="icon-size20"
                            weight="duotone"
                            color="white"
                            // onClick={onRecordClick}
                          />
                        </div>
                      )}
                    </ToolIconButton>
                  }
                >
                  <div
                    className="recorder-popover"
                    style={{
                      fontSize: '12em',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      fontWeight: 600,
                      height: '3em',
                    }}
                  >
                    {isRecorderPopoverShown &&
                    status === RecordStatusTypes.RECORDING ? (
                      <div
                        aria-hidden
                        className="record-popover-icon-btn"
                        onClick={onPauseClick}
                      >
                        <PauseCircle
                          size="3em"
                          color="#67BCEC"
                          weight="duotone"
                        />
                      </div>
                    ) : null}
                    {isRecorderPopoverShown &&
                    status === RecordStatusTypes.PAUSED ? (
                      <div
                        aria-hidden
                        className="record-popover-icon-btn"
                        onClick={onResumeClick}
                      >
                        <PlayCircle
                          size="3em"
                          color="#67BCEC"
                          weight="duotone"
                        />
                      </div>
                    ) : null}

                    {isRecorderPopoverShown &&
                    status !== RecordStatusTypes.IDLE ? (
                      <div
                        aria-hidden
                        className="record-popover-icon-btn"
                        onClick={onStopClick}
                      >
                        <StopCircle
                          size="3em"
                          color="#67BCEC"
                          weight="duotone"
                        />
                      </div>
                    ) : null}
                  </div>
                </CustomPopover>

                <CustomPopover
                  name="vc"
                  setIsShown={setIsVolumeControlPopoverShown}
                  trigger={
                    <ToolIconButton
                      size={40}
                      color={
                        isVolumeControlPopoverShown ? '#B3DEF6' : '#67BCEC'
                      }
                      boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                      borderRadius="15em"
                    >
                      <SpeakerLow
                        className="icon-size20"
                        weight="duotone"
                        color="white"
                      />
                    </ToolIconButton>
                  }
                >
                  <div
                    className="volume-slider"
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '8em',
                    }}
                  >
                    <SpeakerNone
                      className="icon-size20"
                      weight="duotone"
                      color="#8A8B8D"
                    />
                    <div
                      className="slider"
                      id="sliderVolume"
                      style={{
                        flexGrow: 1,
                        padding: '.3em',
                        margin: '.4em',
                        marginRight: '.8em',
                        fontSize: '10em',
                      }}
                    />
                    <SpeakerHigh
                      className="icon-size20"
                      weight="duotone"
                      color="#8A8B8D"
                    />
                  </div>
                </CustomPopover>
                {user?.userType === 'STUDENT' ? null : (
                  <ToolIconButton
                    size={40}
                    onButtonClick={(e) => setIsTextOpened(!isTextOpened)}
                    color={!isTextOpened ? '#B3DEF6' : '#67BCEC'}
                    boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                    borderRadius="15em"
                  >
                    {isTextOpened ? (
                      <Eye
                        className="icon-size20"
                        weight="duotone"
                        color="white"
                      />
                    ) : (
                      <EyeClosed
                        className="icon-size20"
                        weight="duotone"
                        color="white"
                      />
                    )}
                  </ToolIconButton>
                )}
              </div>
              <div className="icon-button-group">
                <ToolIconButton
                  size={40}
                  onButtonClick={(e) => handlePrevPage()}
                  color="#E9912A"
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  <CaretLeft
                    className="icon-size20"
                    weight="duotone"
                    color="white"
                  />
                </ToolIconButton>
                <div className="tool-input">
                  <input
                    type="number"
                    min={1}
                    max={
                      book && book.pages && book.pages.length >= 4
                        ? book.pages.length - 4
                        : 1
                    }
                    value={pageNum}
                    onChange={(e) => setPageNum(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        handleGoPage(e);
                      }
                    }}
                    onFocus={(e) => (e.target.value = '')}
                  />
                </div>
                <ToolIconButton
                  size={40}
                  onButtonClick={(e) => handleNextPage()}
                  color="#E9912A"
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  <CaretRight
                    className="icon-size20"
                    weight="duotone"
                    color="white"
                  />
                </ToolIconButton>
              </div>
              <div className="icon-button-group">
                <ToolIconButton
                  size={40}
                  onButtonClick={(e) => setIsZoomOpened(!isZoomOpened)}
                  color={isZoomOpened ? '#DFAFDA' : '#BE5FB5'}
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  {!isZoomOpened ? (
                    <MagnifyingGlassPlus
                      className="icon-size20"
                      weight="duotone"
                      color="white"
                    />
                  ) : (
                    <MagnifyingGlassMinus
                      className="icon-size20"
                      weight="duotone"
                      color="white"
                    />
                  )}
                </ToolIconButton>
                <CustomPopover
                  name="bookmark"
                  setIsShown={setIsBookmarkPopoverShown}
                  toggle={refresh}
                  trigger={
                    <ToolIconButton
                      size={40}
                      color={isBookmarkPopoverShown ? '#DFAFDA' : '#BE5FB5'}
                      boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                      borderRadius="15em"
                    >
                      <BookmarkSimple
                        className="icon-size20"
                        weight="duotone"
                        color="white"
                      />
                    </ToolIconButton>
                  }
                >
                  <div className="bookmark-container">
                    <div className="bookmark-header">
                      <div className="bookmark-header-title">
                        <BookmarkSimple
                          style={{ width: '3em', height: '3em' }}
                          color="white"
                          weight="fill"
                        />
                        <span>{localizations?.BOOKMARKS}</span>
                      </div>
                      <div
                        className="bookmark-header-close"
                        onClick={() => {
                          changeBookmarkTypesToExisting();
                          setRefresh((prev) => prev + 1);
                        }}
                      >
                        <XCircle
                          style={{ width: '3em', height: '3em' }}
                          weight="fill"
                          color="white"
                        />
                      </div>
                    </div>
                    {checkAppendBookmarkButtonEnabled() ||
                    checkRemoveBookmarkButtonEnabled() ? (
                      <div className="bookmark-buttons">
                        {checkRemoveBookmarkButtonEnabled() ? (
                          <Button
                            className="remove-bookmark-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                currentLocation ===
                                book.pages.length / 2 - 1
                              ) {
                                const page = book.pages.find(
                                  (p) =>
                                    p.pageNumber === currentLocation * 2 - 2
                                );
                                if (page) {
                                  const removedBookmark = bookmarkList.find(
                                    (b) => b.pageId === page.id
                                  );
                                  if (removedBookmark) {
                                    removeUserBookmark({
                                      id: removedBookmark.id,
                                    });
                                  }
                                }
                              } else {
                                const page = book.pages.find(
                                  (p) =>
                                    p.pageNumber === currentLocation * 2 - 1
                                );
                                if (page) {
                                  // const newBookmarkList = bookmarkList.filter(
                                  //   (b) => b.pageId !== page.id
                                  // );
                                  // setBookmarkList(newBookmarkList);
                                  const removedBookmark = bookmarkList.find(
                                    (b) => b.pageId === page.id
                                  );
                                  if (removedBookmark) {
                                    removeUserBookmark({
                                      id: removedBookmark.id,
                                    });
                                  }
                                }
                              }
                            }}
                          >
                            {localizations?.REMOVE_BOOKMARK_PAGE_BTN}
                          </Button>
                        ) : null}
                        {checkAppendBookmarkButtonEnabled() ? (
                          <Button
                            className="append-bookmark-btn"
                            onClick={(e) => {
                              e.preventDefault();
                              let page;
                              if (
                                currentLocation ===
                                book.pages.length / 2 - 1
                              ) {
                                page = book.pages.find(
                                  (p) =>
                                    p.pageNumber === currentLocation * 2 - 2
                                );
                              } else {
                                page = book.pages.find(
                                  (p) =>
                                    p.pageNumber === currentLocation * 2 - 1
                                );
                              }
                              // find the index
                              let index = 0;
                              for (let i = 0; i < bookmarkList.length; i++) {
                                if (
                                  book.pages.find(
                                    (p) => p.id === bookmarkList[i].pageId
                                  ).pageNumber < page.pageNumber
                                ) {
                                  if (i !== bookmarkList.length - 1) {
                                    if (
                                      book.pages.find(
                                        (p) =>
                                          p.id === bookmarkList[i + 1].pageId
                                      ).pageNumber > page.pageNumber
                                    ) {
                                      index = i + 1;
                                    }
                                  } else {
                                    index = bookmarkList.length;
                                  }
                                }
                              }

                              const newBookmarkList = [
                                ...bookmarkList.slice(0, index),
                                {
                                  pageId: page.id,
                                  bookmarkText: '',
                                  type: 'new',
                                },
                                ...bookmarkList.slice(index),
                              ];

                              setBookmarkList(newBookmarkList);
                            }}
                          >
                            {localizations?.ADD_BOOKMARK_PAGE_BTN}
                          </Button>
                        ) : null}
                      </div>
                    ) : null}
                    <div className="bookmark-list">
                      {bookmarkList && bookmarkList.length > 0
                        ? bookmarkList.map((bookmark, index) => {
                            if (bookmark.type === 'existing') {
                              return (
                                <div
                                  key={bookmark.pageId}
                                  className={
                                    index === 0 &&
                                    index === bookmarkList.length - 1
                                      ? 'bookmark-list-item existing-item first-item last-item'
                                      : index === 0
                                      ? 'bookmark-list-item existing-item first-item'
                                      : index === bookmarkList.length - 1
                                      ? 'bookmark-list-item existing-item last-item'
                                      : 'bookmark-list-item existing-item'
                                  }
                                >
                                  <div className="btn-cell">
                                    <X
                                      weight="bold"
                                      color="#BE5FB5"
                                      style={{
                                        width: '1.8em',
                                        height: '1.8em',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        removeUserBookmark({ id: bookmark.id });
                                      }}
                                    />
                                  </div>
                                  <div className="btn-cell">
                                    <Pencil
                                      weight="bold"
                                      color="#BE5FB5"
                                      style={{
                                        width: '1.8em',
                                        height: '1.8em',
                                      }}
                                      onClick={() => {
                                        const copyOfBookmarks = [
                                          ...bookmarkList,
                                        ];
                                        copyOfBookmarks[index].type = 'new';
                                        setBookmarkList(copyOfBookmarks);
                                      }}
                                    />
                                  </div>
                                  <div className="page-number-cell">
                                    {'p' +
                                      book?.pages.find(
                                        (p) => p.id === bookmark.pageId
                                      )?.pageNumber}
                                  </div>
                                  <div
                                    className="item-title"
                                    style={{
                                      fontStyle:
                                        !bookmark.bookmarkText ||
                                        bookmark.bookmarkText === ''
                                          ? 'italic'
                                          : 'normal',
                                    }}
                                    onClick={() => {
                                      const pageN = book.pages.find(
                                        (p) => p.id === bookmark.pageId
                                      )?.pageNumber;

                                      if (pageN) {
                                        gotoPage(pageN);
                                      }
                                    }}
                                  >
                                    {bookmark.bookmarkText &&
                                    bookmark.bookmarkText !== ''
                                      ? bookmark.bookmarkText
                                      : `<${localizations?.UNTITLED}>`}
                                  </div>
                                </div>
                              );
                            }
                            return (
                              <div
                                key={index}
                                className={
                                  index === 0 &&
                                  index === bookmarkList.length - 1
                                    ? 'bookmark-list-item new-item first-item last-item'
                                    : index === 0
                                    ? 'bookmark-list-item new-item first-item'
                                    : index === bookmarkList.length - 1
                                    ? 'bookmark-list-item new-item last-item'
                                    : 'bookmark-list-item new-item'
                                }
                              >
                                <div className="btn-container"></div>
                                <div className="page-number-cell">
                                  {'p' +
                                    book.pages.find(
                                      (p) => p.id === bookmark.pageId
                                    )?.pageNumber}
                                </div>
                                <Input
                                  className="new-item-input"
                                  placeholder={
                                    localizations?.BOOKMARK_INPUT_PLACEHOLDER
                                  }
                                  value={bookmark.bookmarkText}
                                  onChange={(e) => {
                                    const copyBookmarks = [...bookmarkList];
                                    copyBookmarks[index].bookmarkText =
                                      e.target.value;
                                    setBookmarkList(copyBookmarks);
                                  }}
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                      const copyBookmarks = [...bookmarkList];
                                      copyBookmarks[index].type = 'existing';
                                      setBookmarkList(copyBookmarks);
                                      setUserBookmark({
                                        id: copyBookmarks[index].id,
                                        bookId: book.id,
                                        pageId: copyBookmarks[index].pageId,
                                        bookmarkText:
                                          copyBookmarks[index].bookmarkText,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </CustomPopover>

                {/* <ToolIconButton
                  size={40}
                  onButtonClick={(e) => e.preventDefault()}
                  color="#BE5FB5"
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  <Pencil
                    className="icon-size20"
                    weight="duotone"
                    color="white"
                  />
                </ToolIconButton> */}
                <ToolIconButton
                  size={40}
                  onButtonClick={(e) =>
                    setIsDictionaryOpened(!isDictionaryOpened)
                  }
                  color={!isDictionaryOpened ? '#DFAFDA' : '#BE5FB5'}
                  boxShadow="0em 4em 4em rgba(0,0,0,0.25)"
                  borderRadius="15em"
                >
                  <Binoculars
                    className="icon-size20"
                    weight="duotone"
                    color="white"
                  />
                </ToolIconButton>
              </div>
            </div>
            <CustomModal
              title={localizations?.MODAL_RECORD_TITLE}
              isOpen={soundRecordModal}
              toggle={() => setSoundRecordModal(false)}
              onCloseClick={() => setSoundRecordModal(false)}
              buttons={[
                {
                  name: 'download-record',
                  fullWidth: false,
                  color: '#67BCEC',
                  text: localizations?.MODAL_RECORD_DOWNLOAD_BTN ?? 'İndir',
                  icon: (
                    <DownloadSimple size={25} weight="duotone" color="white" />
                  ),
                  onClick: onDownloadSoundClick,
                },
                {
                  name: 'send-record',
                  fullWidth: false,
                  color: '#67BCEC',
                  text: localizations?.MODAL_RECORD_SEND_BTN,
                  icon: (
                    <PaperPlaneTilt size={25} weight="duotone" color="white" />
                  ),
                  onClick: onSendSoundClick,
                },
              ]}
            >
              <ReactAudioPlayer src={audioResult} controls />
            </CustomModal>
            <CustomModal
              title={
                location.state?.actionType === BookActionTypes.read
                  ? 'Opps! Çok hızlı okudun. Tekrar oku.'
                  : 'Opps! Dinlemeyi tamamlayamadın. Tekrar dinle.'
              }
              isOpen={retryModal}
              toggle={() => setRetryModal(false)}
              onCloseClick={() => setRetryModal(false)}
              buttons={[
                {
                  name: 'retry',
                  fullWidth: true,
                  color: '#67BCEC',
                  text: 'Tamam',
                  onClick: () => {
                    if (location.state?.actionType === BookActionTypes.read) {
                      gotoPage(book?.resumePageLocation * 2 - 1);
                    }

                    if (location.state?.actionType === BookActionTypes.listen) {
                      gotoPage(book?.resumeListenPageLocation * 2 - 1);
                      setActionType('LISTEN');
                    }
                    setRetryModal(false);
                  },
                },
              ]}
            />
            <CustomModal
              backdrop={false}
              keyboard={false}
              title={
                checkForResumeReading(book) && !checkForResumeListening(book)
                  ? localizations?.MODAL_RESUME_READING_TITLE
                  : !checkForResumeReading(book) &&
                    checkForResumeListening(book)
                  ? localizations?.MODAL_RESUME_LISTENING_TITLE
                  : localizations?.MODAL_RESUME_READING_AND_LISTENING_TITLE
              }
              isOpen={resumeBookModal}
              toggle={() => setResumeBookModal(false)}
              onCloseClick={() => setResumeBookModal(false)}
              buttons={[
                {
                  name: 'restart',
                  fullWidth: true,
                  color: '#B4B4B4',
                  text:
                    localizations?.MODAL_RESUME_BOOK_RESTART_BTN ??
                    'Baştan başla',
                  onClick: () => {
                    if (location.state?.actionType === BookActionTypes.listen) {
                      setPlaying(true);
                      setActionType(null);
                    }
                    setResumeBookModal(false);
                  },
                },
                checkForResumeReading(book)
                  ? {
                      name: 'resume-reading',
                      fullWidth: true,
                      color: '#BE5FB5',
                      text:
                        localizations?.MODAL_RESUME_BOOK_RESUME_BTN ??
                        'Okumaya devam et',
                      onClick: () => {
                        gotoPage(book?.resumePageLocation * 2 - 1);
                        setResumeBookModal(false);
                      },
                    }
                  : null,
                checkForResumeListening(book)
                  ? {
                      name: 'resume-listening',
                      fullWidth: true,
                      color: '#67BCEC',
                      text:
                        localizations?.MODAL_RESUME_BOOK_RESUME_LISTEN_BTN ??
                        'Dinlemeye devam et',
                      onClick: () => {
                        gotoPage(book?.resumeListenPageLocation * 2 - 1);
                        setActionType('LISTEN');
                        setResumeBookModal(false);
                      },
                    }
                  : null,
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.currentUser,
    book: state.data.book,
    loading: state.ui.loading,
    message: state.message[READ_BOOK],
    localizations: state.localization?.pairs,
    uploadParameters: state.data.uploadParameters,
  };
};

const mapDispatchToProps = {
  startReadBookPage,
  saveUserSoundUrl,
  setUserBookmark,
  removeUserBookmark,
  setBookLastPage,
  removeMessage,
  closeCurrentBook,
  getAudioUploadParams,
  removeAudioUploadParams,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireAuthentication(ReadBook));
