import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import Select from 'react-select';

import {
  User,
  Phone,
  Bank,
  MapPinLine,
  EnvelopeSimple,
  Fish,
} from 'phosphor-react';

import DropdownFixedNavbar from 'components/Custom/DropdownFixedNavbar';
import FooterBlack from 'components/Custom/FooterBlack';
import CustomAlert from './CustomAlert';
import CustomLoading from './CustomLoading';
import MessageTypes from 'enums/message-types';

import { connect } from 'react-redux';
import { SEND_BUY_NOW_FORM, sendBuyNowForm } from 'redux/actions/pages';
import { removeMessage } from 'redux/actions/message';

const cities = [
  'ADANA',
  'ADIYAMAN',
  'AFYONKARAHİSAR',
  'AĞRI',
  'AMASYA',
  'ANKARA',
  'ANTALYA',
  'ARTVİN',
  'AYDIN',
  'BALIKESİR',
  'BİLECİK',
  'BİNGÖL',
  'BİTLİS',
  'BOLU',
  'BURDUR',
  'BURSA',
  'ÇANAKKALE',
  'ÇANKIRI',
  'ÇORUM',
  'DENİZLİ',
  'DİYARBAKIR',
  'EDİRNE',
  'ELAZIĞ',
  'ERZİNCAN',
  'ERZURUM',
  'ESKİŞEHİR',
  'GAZİANTEP',
  'GİRESUN',
  'GÜMÜŞHANE',
  'HAKKARİ',
  'HATAY',
  'ISPARTA',
  'MERSİN',
  'İSTANBUL',
  'İZMİR',
  'KARS',
  'KASTAMONU',
  'KAYSERİ',
  'KIRKLARELİ',
  'KIRŞEHİR',
  'KOCAELİ',
  'KONYA',
  'KÜTAHYA',
  'MALATYA',
  'MANİSA',
  'KAHRAMANMARAŞ',
  'MARDİN',
  'MUĞLA',
  'MUŞ',
  'NEVŞEHİR',
  'NİĞDE',
  'ORDU',
  'RİZE',
  'SAKARYA',
  'SAMSUN',
  'SİİRT',
  'SİNOP',
  'SİVAS',
  'TEKİRDAĞ',
  'TOKAT',
  'TRABZON',
  'TUNCELİ',
  'ŞANLIURFA',
  'UŞAK',
  'VAN',
  'YOZGAT',
  'ZONGULDAK',
  'AKSARAY',
  'BAYBURT',
  'KARAMAN',
  'KIRIKKALE',
  'BATMAN',
  'ŞIRNAK',
  'BARTIN',
  'ARDAHAN',
  'IĞDIR',
  'YALOVA',
  'KARABÜK',
  'KİLİS',
  'OSMANİYE',
  'DÜZCE',
];

function BuyNowPage({
  backendMessage,
  sendBuyNowForm,
  removeMessage,
  localizations,
}) {
  const [nameFocus, setNameFocus] = useState(false);
  const [schoolFocus, setSchoolFocus] = useState(false);
  const [cityFocus, setCityFocus] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);

  const [rod, setRod] = useState('');
  const [name, setName] = useState('');
  const [school, setSchool] = useState('');
  const [city, setCity] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    document.body.classList.add('contact-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('contact-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  useEffect(() => {
    if (
      backendMessage &&
      backendMessage.messageContent !== undefined &&
      backendMessage.messageContent !== null &&
      backendMessage.messageContent !== ''
    ) {
      Swal.fire({
        icon: backendMessage.messageType || 'error',
        title:
          backendMessage.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: backendMessage.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN,
        confirmButtonColor: '#3BA8FF',
      }).then((_) => {
        removeMessage(SEND_BUY_NOW_FORM);
        if (backendMessage.messageType === MessageTypes.success) {
          setRod('');
          setName('');
          setSchool('');
          setCity('');
          setEmail('');
          setPhone('');
          setMessage('');
        }
      });
    }
    // eslint-disable-next-line
  }, [backendMessage]);

  return (
    <>
      <CustomAlert />
      <CustomLoading />
      <DropdownFixedNavbar />
      <div className="wrapper">
        <div className="section-space" />
        <div className="section">
          <div className="main">
            <div className="contact-content">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="6">
                    <h2 className="title">{localizations?.BUY_NOW_HEADER}</h2>
                    <p className="description">
                      {localizations?.BUY_NOW_DESC}
                      <br></br>
                      <br></br>
                    </p>
                    <Form
                      id="contact-form"
                      method="post"
                      role="form"
                      className="buy-now-form"
                    >
                      <label>
                        {localizations?.FORM_NAME}
                        <span> *</span>
                      </label>
                      <InputGroup style={{ display: 'none' }}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Fish color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label="Rod"
                          autoComplete="Rod"
                          placeholder="Fish Rod"
                          type="text"
                          value={rod}
                          onChange={(e) => setRod(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={nameFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <User color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label={localizations?.FORM_NAME}
                          autoComplete="name"
                          placeholder={localizations?.FORM_NAME}
                          type="text"
                          onFocus={() => setNameFocus(true)}
                          onBlur={() => setNameFocus(false)}
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>
                        {localizations?.FORM_SCHOOL}
                        <span> *</span>
                      </label>
                      <InputGroup
                        className={schoolFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Bank color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label={localizations?.FORM_SCHOOL}
                          autoComplete="school"
                          placeholder={localizations?.FORM_SCHOOL}
                          type="text"
                          onFocus={() => setSchoolFocus(true)}
                          onBlur={() => setSchoolFocus(false)}
                          value={school}
                          onChange={(e) => setSchool(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>
                        {localizations?.FORM_CITY}
                        <span> *</span>
                      </label>
                      <InputGroup
                        className={cityFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{ height: '40px' }}>
                            <MapPinLine color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <div className="custom-react-select">
                          <Select
                            className="react-select react-select-danger"
                            onChange={(value) => setCity(value)}
                            classNamePrefix="react-select"
                            placeholder={localizations?.FORM_CITY}
                            // menuColor="red"
                            value={city}
                            name="city"
                            onFocus={() => setCityFocus(true)}
                            onBlur={() => setCityFocus(false)}
                            options={cities.map((c) => {
                              return {
                                value: c,
                                label: c,
                              };
                            })}
                          ></Select>
                        </div>
                      </InputGroup>
                      <label>{localizations?.FORM_PHONE}</label>
                      <InputGroup
                        className={phoneFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Phone color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          autoComplete="number"
                          placeholder={localizations?.FORM_PHONE}
                          type="text"
                          onFocus={() => setPhoneFocus(true)}
                          onBlur={() => setPhoneFocus(false)}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <label>
                        {localizations?.FORM_EMAIL}
                        <span> *</span>
                      </label>
                      <InputGroup
                        className={emailFocus ? 'input-group-focus' : ''}
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <EnvelopeSimple color="black" weight="duotone" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          aria-label={localizations?.FORM_EMAIL}
                          autoComplete="email"
                          placeholder={localizations?.FORM_EMAIL}
                          type="email"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        ></Input>
                      </InputGroup>
                      <FormGroup>
                        <label>{localizations?.FORM_MESSAGE}</label>
                        <Input
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                          placeholder={localizations?.FORM_MESSAGE}
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        ></Input>
                      </FormGroup>
                      <div className="submit text-center">
                        <Button
                          className="btn-raised btn-round"
                          color="info"
                          defaultValue={localizations?.FORM_SEND_BTN}
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            if (rod === '') {
                              sendBuyNowForm({
                                name,
                                school,
                                city: city?.value,
                                email,
                                phone,
                                message,
                              });
                            }
                          }}
                        >
                          {localizations?.FORM_SEND_BTN}
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
        <FooterBlack />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  backendMessage: state.message[SEND_BUY_NOW_FORM],
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  sendBuyNowForm,
  removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyNowPage);
