import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Howl } from 'howler';
import { useReward } from 'react-rewards';

import { getQuizDetail, sendQuizResult } from 'redux/actions/pages';
import CloseWarningContent from './components/CloseWarningContent';
import QuestionContent from './components/QuestionContent';
import QuizReportContent from './components/QuizReportContent';
import QuestionAgainContent from './components/QuestionAgainContent';

const quizModalStateTypes = {
  showQuestion: 'QUESTION',
  showReport: 'REPORT',
  showQuestionAgain: 'AGAIN',
  showCloseWarning: 'WARNING',
};

const QuizModal = ({
  isOpen,
  toggle,
  quizId,
  quiz,
  getQuizDetail,
  // localizations,
  sendQuizResult,
  // myAssignedBooks,
}) => {
  const [currentState, setCurrentState] = useState({
    type: quizModalStateTypes.showQuestion,
    questionId: 0,
    question: null,
  });

  const [userAnswers, setUserAnswers] = useState({});

  const [audio, setAudio] = useState(null);
  const [currentAudioId, setCurrentAudioId] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const { reward: confettiReward, isAnimating: isConfettiAnimating } =
    useReward('confettiReward', 'confetti', {
      lifetime: 300,
      elementCount: 250,
    });

  useEffect(() => {
    if (isOpen && (!quiz || quizId !== quiz?.id)) {
      getQuizDetail({ quizId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizId, quiz?.id, isOpen]);

  useEffect(() => {
    if (quiz && quiz.questions.length > 0) {
      setCurrentState((c) => ({ ...c, questionId: quiz.questions[0].id }));
      setUserAnswers(
        Object.assign(
          {},
          ...quiz.questions.map((q) => ({
            [q.id]: {
              questionId: q.id,
              userAnswerId: null,
              correctAnswerId: q.choices?.find((ch) => ch.isCorrectAnswer)?.id,
            },
          }))
        )
      );
    } else {
      setCurrentState((c) => ({ ...c, questionId: 0 }));
    }
  }, [quiz, isOpen]);

  useEffect(() => {
    if (currentState.questionId > 0) {
      setCurrentState((c) => ({
        ...c,
        question:
          quiz?.questions?.find((q) => q.id === currentState.questionId) ??
          null,
      }));
    } else {
      setCurrentState((c) => ({
        ...c,
        question: null,
      }));
    }
  }, [quiz?.questions, currentState.questionId]);

  useEffect(() => {
    // If selected question changed, unload and reset about audio
    audio?.unload();
    setIsPlaying(false);
    setCurrentAudioId('');
    setAudio(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState.question]);

  useEffect(() => {
    if (isPlaying) {
      audio?.play();
    } else {
      audio?.pause();
    }
  }, [audio, isPlaying]);

  const onPlayClick = (audioId, url) => {
    if (audio && currentAudioId === audioId) {
      setIsPlaying(true);
    } else {
      // Varolanı Kaldır!
      audio?.unload();
      setIsPlaying(false);
      setCurrentAudioId('');
      setAudio(null);

      // Yeniden oluştur!
      const sound = new Howl({
        src: url,
      });

      sound.on('end', () => {
        audio?.unload();
        setIsPlaying(false);
        setCurrentAudioId('');
        setAudio(null);
      });

      setAudio(sound);
      setCurrentAudioId(audioId);
      setIsPlaying(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="xl"
      contentClassName=""
      backdropClassName=""
      backdrop="static"
      centered
    >
      {quizId === quiz?.id ? (
        <>
          <div className="quiz-modal-container">
            {currentState.type === quizModalStateTypes.showCloseWarning ? (
              <CloseWarningContent
                closeAction={(e) => {
                  e.preventDefault();
                  setCurrentState({
                    type: quizModalStateTypes.showQuestion,
                    questionId: 0,
                    question: null,
                  });
                  toggle();
                }}
                continueAction={(e) => {
                  e.preventDefault();
                  setCurrentState((c) => ({
                    ...c,
                    type: quizModalStateTypes.showQuestion,
                  }));
                }}
              />
            ) : currentState.type === quizModalStateTypes.showQuestion ? (
              <QuestionContent
                currentState={currentState}
                userAnswers={userAnswers}
                goReportAction={(_event) => {
                  setCurrentState({
                    type: quizModalStateTypes.showReport,
                    questionId: 0,
                    question: null,
                  });

                  if (
                    !isConfettiAnimating &&
                    Object.values(userAnswers).every(
                      (answer) => answer.userAnswerId === answer.correctAnswerId
                    )
                  ) {
                    confettiReward();
                  }
                }}
                nextQuestionAction={(_event) => {
                  const nextQuestionId = quiz.questions.find(
                    (q) =>
                      q.questionOrder ===
                      currentState.question.questionOrder + 1
                  ).id;

                  setCurrentState((c) => ({
                    ...c,
                    questionId: nextQuestionId,
                  }));
                }}
                xButtonAction={(e) => {
                  e.preventDefault();
                  setCurrentState((c) => ({
                    ...c,
                    type: quizModalStateTypes.showCloseWarning,
                  }));
                }}
                tabItemClickAction={(qId) => {
                  setCurrentState((c) => ({
                    ...c,
                    questionId: qId,
                  }));
                }}
                onPlayClick={onPlayClick}
                onPauseClick={(id) => {
                  if (currentAudioId === id) {
                    setIsPlaying(false);
                  }
                }}
                isPlaying={isPlaying}
                currentAudioId={currentAudioId}
                choiceSelectAction={(id) =>
                  setUserAnswers((ans) => {
                    return {
                      ...ans,
                      [currentState.question.id]: {
                        ...ans[currentState.question.id],
                        userAnswerId:
                          ans[currentState.question.id]?.userAnswerId === id
                            ? null
                            : id,
                      },
                    };
                  })
                }
              />
            ) : currentState.type === quizModalStateTypes.showReport ? (
              <QuizReportContent
                userAnswers={userAnswers}
                tabItemClickAction={(qId) => {
                  setCurrentState((c) => ({
                    ...c,
                    type: quizModalStateTypes.showQuestionAgain,
                    questionId: qId,
                  }));
                }}
                finishQuizAction={() => {
                  const results = Object.values(userAnswers).reduce(
                    (prev, current) => {
                      if (current.userAnswerId === null) {
                        return {
                          ...prev,
                          blank: prev.blank + 1,
                        };
                      }

                      if (current.userAnswerId === current.correctAnswerId) {
                        return {
                          ...prev,
                          correct: prev.correct + 1,
                        };
                      }

                      if (current.userAnswerId !== current.correctAnswerId) {
                        return {
                          ...prev,
                          wrong: prev.wrong + 1,
                        };
                      }

                      return prev;
                    },
                    { correct: 0, wrong: 0, blank: 0 }
                  );
                  sendQuizResult({
                    quizId: quiz?.id,
                    correctCount: results?.correct,
                    wrongCount: results?.wrong,
                    blankCount: results?.blank,
                    bookId: quiz?.book?.id,
                    isPrimary: quiz?.isPrimary,
                  });
                  setCurrentState({
                    type: quizModalStateTypes.showQuestion,
                    questionId: 0,
                    question: null,
                  });
                  toggle();
                }}
              />
            ) : currentState.type === quizModalStateTypes.showQuestionAgain ? (
              <QuestionAgainContent
                currentState={currentState}
                userAnswers={userAnswers}
                isPlaying={isPlaying}
                currentAudioId={currentAudioId}
                onPlayClick={onPlayClick}
                onPauseClick={(id) => {
                  if (currentAudioId === id) {
                    setIsPlaying(false);
                  }
                }}
                choiceSelectAction={(id) =>
                  setUserAnswers((ans) => {
                    return {
                      ...ans,
                      [currentState.question.id]: {
                        ...ans[currentState.question.id],
                        userAnswerId:
                          ans[currentState.question.id]?.userAnswerId === id
                            ? null
                            : id,
                      },
                    };
                  })
                }
                returnToReportAction={() => {
                  setCurrentState({
                    type: quizModalStateTypes.showReport,
                    questionId: 0,
                    question: null,
                  });
                  if (
                    !isConfettiAnimating &&
                    Object.values(userAnswers).every(
                      (answer) => answer.userAnswerId === answer.correctAnswerId
                    )
                  ) {
                    confettiReward();
                  }
                }}
              />
            ) : null}
            <span
              style={{ display: 'flex', justifyContent: 'center' }}
              id="confettiReward"
            />
          </div>
        </>
      ) : (
        <div style={{ height: '50px', minHeight: '50px', width: '100%' }} />
      )}
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  quiz: state.data.quiz,
  localizations: state.localization?.pairs,
  myAssignedBooks: state.data.myAssignedBooks,
});

const mapDispatchToProps = {
  getQuizDetail,
  sendQuizResult,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuizModal);
