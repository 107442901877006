import React, { useRef, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CaretLeft } from 'phosphor-react';

import {
  getStudentReports,
  startStudentReportsPage,
} from 'redux/actions/pages';
import FooterBlack from 'components/Custom/FooterBlack';
import requireSpecificUserAuthentication from 'hocs/require-specific-user-authentication';
import ReportSummary from './components/ReportSummary';
import ReportGeneralInfo from './components/ReportGeneralInfo';
import MontlyTaskComparisonChart from './components/MontlyTaskComparisonChart';
import MonthlyReadingTaskIncentiveOrNotComparison from './components/MonthlyReadingTaskIncentiveOrNotComparison';
import TaskCompletionSummary from './components/TaskCompletionSummary';
import QuizResults from './components/QuizResults';
import StudentLoadingIndicator from 'views/StudentLoadingIndicator';

const PageOrientationType = Object.freeze({
  portrait: 'portrait',
  landscape: 'landscape',
});

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const StudentReports = ({
  getStudentReports,
  // getStudentTotalPoint,
  userReport,
  localizations,
  startStudentReportsPage,
  loading,
}) => {
  const pdfContainerRef = useRef();
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    startStudentReportsPage();
    getStudentReports();
    // getStudentTotalPoint();

    function handleWindowHeight() {
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleWindowHeight);

    return () => {
      window.removeEventListener('resize', handleWindowHeight);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadPDFReport = async (e) => {
    e.preventDefault();
    const element = pdfContainerRef.current;
    element.style.display = 'block';

    await delay(2000);

    const { clientHeight, clientWidth } = element;

    const canvas = await html2canvas(element, { scale: 1 });
    const data = canvas.toDataURL('image/png');

    let pageOrientation = PageOrientationType.portrait;

    const pdf = new jsPDF({ orientation: pageOrientation });

    const originalPdfWidth = pdf.internal.pageSize.getWidth();

    let pdfWidth = 0;
    let pdfHeight = 0;

    // calculate belongs to width
    pdfWidth = originalPdfWidth;
    pdfHeight = (originalPdfWidth * clientHeight) / clientWidth;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
    element.style.display = 'none';
  };

  return (
    <>
      <StudentLoadingIndicator />
      <div className="go-back">
        <Link to={{ pathname: '/student' }}>
          <CaretLeft
            color="#124179"
            weight="bold"
            size={windowHeight <= 768 ? 40 : 80}
          />
        </Link>
      </div>
      {!loading ? (
        <div className="wrapper">
          <div className="section">
            <div className="main">
              <Container>
                {userReport !== null && userReport !== undefined ? (
                  <>
                    <div className="student-report-container">
                      <ReportSummary
                        avatarUrl={userReport.avatarUrl}
                        name={userReport.name}
                        surname={userReport.surname}
                        level={userReport.level}
                        totalPoint={userReport.totalPoint}
                      />
                      <div className="student-report-line" />
                      <ReportGeneralInfo
                        bookCount={userReport.generalInformation.reduce(
                          (total, current) => {
                            if (current.type === 'TOTAL') {
                              return total + current.count;
                            }
                            return total;
                          },
                          0
                        )}
                        readingCount={userReport.generalInformation.reduce(
                          (total, current) => {
                            if (current.type === 'READ') {
                              return total + current.count;
                            }
                            return total;
                          },
                          0
                        )}
                        listeningCount={userReport.generalInformation.reduce(
                          (total, current) => {
                            if (current.type === 'LISTEN') {
                              return total + current.count;
                            }
                            return total;
                          },
                          0
                        )}
                        recordingCount={userReport.generalInformation.reduce(
                          (total, current) => {
                            if (current.type === 'RECORD') {
                              return total + current.count;
                            }
                            return total;
                          },
                          0
                        )}
                        quizCount={userReport.generalInformation.reduce(
                          (total, current) => {
                            if (current.type === 'QUIZ') {
                              return total + current.count;
                            }
                            return total;
                          },
                          0
                        )}
                      />
                      <div className="charts-container">
                        <Row>
                          <Col xl={6}>
                            <MontlyTaskComparisonChart
                              monthlyResults={userReport.monthlyResults}
                            />
                          </Col>
                          <Col xl={6}>
                            <MonthlyReadingTaskIncentiveOrNotComparison
                              monthlyResults={userReport.monthlyResults}
                            />
                          </Col>

                          <Col xl={6}>
                            <TaskCompletionSummary
                              taskCompletion={userReport.taskCompletion}
                            />
                          </Col>
                          <Col xl={6}>
                            <QuizResults
                              quizResults={userReport.quizInformation[0]}
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button
                        size="lg"
                        style={{
                          fontWeight: 'bold',
                          backgroundColor:
                            localizations?.REPORTS_DOWNLOAD_PDF_BTN_COLOR ??
                            '#F40F02',
                        }}
                        onClick={downloadPDFReport}
                      >
                        {localizations?.REPORTS_DOWNLOAD_PDF_BTN_LABEL ??
                          'PDF İndir'}
                      </Button>
                    </div>
                  </>
                ) : (
                  <Row>
                    <Col
                      md={{ size: 8, offset: 2 }}
                      lg={{ size: 6, offset: 3 }}
                      xs={12}
                    >
                      <div className="no-reports">
                        {/* <img src={noMessageImage} alt="Mesaj Yok!" /> */}
                        <p>
                          {localizations?.REPORTS_NO_REPORT ?? 'RAPOR YOK!'}
                        </p>
                      </div>
                    </Col>
                  </Row>
                )}
              </Container>
            </div>
          </div>
          <FooterBlack />
        </div>
      ) : null}

      {/* This part will be created dynamically, after saving pdf this part will be hidden */}
      {userReport !== null && userReport !== undefined ? (
        <div
          className="student-report-container-to-pdf"
          id="to-pdf"
          ref={pdfContainerRef}
        >
          <ReportSummary
            avatarUrl={userReport.avatarUrl}
            name={userReport.name}
            surname={userReport.surname}
            level={userReport.level}
            totalPoint={userReport.totalPoint}
          />

          <div className="student-report-line" />

          <ReportGeneralInfo
            bookCount={userReport.generalInformation.reduce(
              (total, current) => {
                if (current.type === 'TOTAL') {
                  return total + current.count;
                }
                return total;
              },
              0
            )}
            readingCount={userReport.generalInformation.reduce(
              (total, current) => {
                if (current.type === 'READ') {
                  return total + current.count;
                }
                return total;
              },
              0
            )}
            listeningCount={userReport.generalInformation.reduce(
              (total, current) => {
                if (current.type === 'LISTEN') {
                  return total + current.count;
                }
                return total;
              },
              0
            )}
            recordingCount={userReport.generalInformation.reduce(
              (total, current) => {
                if (current.type === 'RECORD') {
                  return total + current.count;
                }
                return total;
              },
              0
            )}
            quizCount={userReport.generalInformation.reduce(
              (total, current) => {
                if (current.type === 'QUIZ') {
                  return total + current.count;
                }
                return total;
              },
              0
            )}
          />

          <div className="charts-container">
            <MontlyTaskComparisonChart
              monthlyResults={userReport.monthlyResults}
            />

            <MonthlyReadingTaskIncentiveOrNotComparison
              monthlyResults={userReport.monthlyResults}
            />

            <TaskCompletionSummary taskCompletion={userReport.taskCompletion} />

            <QuizResults quizResults={userReport.quizInformation[0]} />
          </div>
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  userReport: state.data.reports?.userReport,
  localizations: state.localization?.pairs,
  loading: state.ui.loading,
});

const mapDispatchToProps = {
  getStudentReports,
  startStudentReportsPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireSpecificUserAuthentication(['STUDENT'])(StudentReports));
