/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import { Container } from 'reactstrap';

import {
  FacebookLogo,
  // TwitterLogo,
  LinkedinLogo,
  // GithubLogo,
  InstagramLogo,
} from 'phosphor-react';
import { connect } from 'react-redux';

function FooterBlack({ localizations }) {
  return (
    <>
      <footer className="footer custom-footer" data-background-color="gray">
        <Container>
          <nav>
            <ul>
              <li>
                <Link to="/kvkk">{localizations?.FOOTER_KVKK_BTN}</Link>
              </li>
              <li>
                <Link to="/">{localizations?.FOOTER_TERMS_BTN}</Link>
              </li>
              {/* <li>
                <Link to="/">{localizations?.FOOTER_COPYRIGHT_BTN}</Link>
              </li> */}
            </ul>
          </nav>
          <div className="social-media">
            <ul>
              {/* <li>
                <div className="social-media-item">
                  <a href="https://twitter.com/EdictDigital" target="_blank">
                    <TwitterLogo weight="fill" color="#1DA1F2" size={24} />
                  </a>
                </div>
              </li> */}
              <li>
                <div className="social-media-item">
                  <a
                    href="https://www.linkedin.com/company/okuma-platformu"
                    target="_blank"
                  >
                    <LinkedinLogo weight="fill" color="#0072B1" size={24} />
                  </a>
                </div>
              </li>
              <li>
                <div className="social-media-item">
                  <a
                    href="https://www.facebook.com/okumaplatformu"
                    target="_blank"
                  >
                    <FacebookLogo weight="fill" color="#4267B2" size={24} />
                  </a>
                </div>
              </li>
              <li>
                <div className="social-media-item">
                  <a
                    href="https://www.instagram.com/okuma_platformu"
                    target="_blank"
                  >
                    <InstagramLogo weight="fill" color="#333333" size={24} />
                  </a>
                </div>
              </li>
              {/* <li>
                <div className="social-media-item">
                  <a href="https://github.com/edictdigital" target="_blank">
                    <GithubLogo weight="fill" color="#171515" size={24} />
                  </a>
                </div>
              </li> */}
              {/* <li>
                <div className="social-media-item">
                  <a
                    href="https://www.instagram.com/edictdigital"
                    target="_blank"
                  >
                    <InstagramLogo weight="fill" color="#BC2A8D" size={24} />
                  </a>
                </div>
              </li> */}
            </ul>
          </div>
          <hr />
          <div className="custom-copyright">
            © {new Date().getFullYear()}{' '}
            <a href="https://www.edictdigital.com/" target="_blank">
              Edict Digital
            </a>
            . {localizations?.FOOTER_COPYRIGHT_TEXT}
          </div>
        </Container>
      </footer>
    </>
  );
}

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(FooterBlack);
