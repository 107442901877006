import React from 'react';
import * as PhosphorIcons from 'phosphor-react';

// props can be object of
// size: number,
// color: string (rgb supported)
// weight: string for light || thin || regular || bold || fill || duotone

// How to use?
/**
 * const iconName = "AddressBook"
 * return (
 *   <>
 *     {iconMapper[iconName]({weight: "duotone", size: 68})}
 *   </>
 * );
 */

export const iconMapper = {
  Activity: (props) => <PhosphorIcons.Activity {...props} />,
  AddressBook: (props) => <PhosphorIcons.AddressBook {...props} />,
  Airplane: (props) => <PhosphorIcons.Airplane {...props} />,
  AirplaneInFlight: (props) => <PhosphorIcons.AirplaneInFlight {...props} />,
  AirplaneLanding: (props) => <PhosphorIcons.AirplaneLanding {...props} />,
  AirplaneTakeoff: (props) => <PhosphorIcons.AirplaneTakeoff {...props} />,
  AirplaneTilt: (props) => <PhosphorIcons.AirplaneTilt {...props} />,
  Airplay: (props) => <PhosphorIcons.Airplay {...props} />,
  Alarm: (props) => <PhosphorIcons.Alarm {...props} />,
  Alien: (props) => <PhosphorIcons.Alien {...props} />,
  AlignBottom: (props) => <PhosphorIcons.AlignBottom {...props} />,
  AlignBottomSimple: (props) => <PhosphorIcons.AlignBottomSimple {...props} />,
  AlignCenterHorizontal: (props) => (
    <PhosphorIcons.AlignCenterHorizontal {...props} />
  ),
  AlignCenterHorizontalSimple: (props) => (
    <PhosphorIcons.AlignCenterHorizontalSimple {...props} />
  ),
  AlignCenterVertical: (props) => (
    <PhosphorIcons.AlignCenterVertical {...props} />
  ),
  AlignCenterVerticalSimple: (props) => (
    <PhosphorIcons.AlignCenterVerticalSimple {...props} />
  ),
  AlignLeft: (props) => <PhosphorIcons.AlignLeft {...props} />,
  AlignLeftSimple: (props) => <PhosphorIcons.AlignLeftSimple {...props} />,
  AlignRight: (props) => <PhosphorIcons.AlignRight {...props} />,
  AlignRightSimple: (props) => <PhosphorIcons.AlignRightSimple {...props} />,
  AlignTop: (props) => <PhosphorIcons.AlignTop {...props} />,
  AlignTopSimple: (props) => <PhosphorIcons.AlignTopSimple {...props} />,
  Anchor: (props) => <PhosphorIcons.Anchor {...props} />,
  AnchorSimple: (props) => <PhosphorIcons.AnchorSimple {...props} />,
  AndroidLogo: (props) => <PhosphorIcons.AndroidLogo {...props} />,
  AngularLogo: (props) => <PhosphorIcons.AngularLogo {...props} />,
  Aperture: (props) => <PhosphorIcons.Aperture {...props} />,
  AppStoreLogo: (props) => <PhosphorIcons.AppStoreLogo {...props} />,
  AppWindow: (props) => <PhosphorIcons.AppWindow {...props} />,
  AppleLogo: (props) => <PhosphorIcons.AppleLogo {...props} />,
  ApplePodcastsLogo: (props) => <PhosphorIcons.ApplePodcastsLogo {...props} />,
  Archive: (props) => <PhosphorIcons.Archive {...props} />,
  ArchiveBox: (props) => <PhosphorIcons.ArchiveBox {...props} />,
  ArchiveTray: (props) => <PhosphorIcons.ArchiveTray {...props} />,
  Armchair: (props) => <PhosphorIcons.Armchair {...props} />,
  ArrowArcLeft: (props) => <PhosphorIcons.ArrowArcLeft {...props} />,
  ArrowArcRight: (props) => <PhosphorIcons.ArrowArcRight {...props} />,
  ArrowBendDoubleUpLeft: (props) => (
    <PhosphorIcons.ArrowBendDoubleUpLeft {...props} />
  ),
  ArrowBendDoubleUpRight: (props) => (
    <PhosphorIcons.ArrowBendDoubleUpRight {...props} />
  ),
  ArrowBendDownLeft: (props) => <PhosphorIcons.ArrowBendDownLeft {...props} />,
  ArrowBendDownRight: (props) => (
    <PhosphorIcons.ArrowBendDownRight {...props} />
  ),
  ArrowBendLeftDown: (props) => <PhosphorIcons.ArrowBendLeftDown {...props} />,
  ArrowBendLeftUp: (props) => <PhosphorIcons.ArrowBendLeftUp {...props} />,
  ArrowBendRightDown: (props) => (
    <PhosphorIcons.ArrowBendRightDown {...props} />
  ),
  ArrowBendRightUp: (props) => <PhosphorIcons.ArrowBendRightUp {...props} />,
  ArrowBendUpLeft: (props) => <PhosphorIcons.ArrowBendUpLeft {...props} />,
  ArrowBendUpRight: (props) => <PhosphorIcons.ArrowBendUpRight {...props} />,
  ArrowCircleDown: (props) => <PhosphorIcons.ArrowCircleDown {...props} />,
  ArrowCircleDownLeft: (props) => (
    <PhosphorIcons.ArrowCircleDownLeft {...props} />
  ),
  ArrowCircleDownRight: (props) => (
    <PhosphorIcons.ArrowCircleDownRight {...props} />
  ),
  ArrowCircleLeft: (props) => <PhosphorIcons.ArrowCircleLeft {...props} />,
  ArrowCircleRight: (props) => <PhosphorIcons.ArrowCircleRight {...props} />,
  ArrowCircleUp: (props) => <PhosphorIcons.ArrowCircleUp {...props} />,
  ArrowCircleUpLeft: (props) => <PhosphorIcons.ArrowCircleUpLeft {...props} />,
  ArrowCircleUpRight: (props) => (
    <PhosphorIcons.ArrowCircleUpRight {...props} />
  ),
  ArrowClockwise: (props) => <PhosphorIcons.ArrowClockwise {...props} />,
  ArrowCounterClockwise: (props) => (
    <PhosphorIcons.ArrowCounterClockwise {...props} />
  ),
  ArrowDown: (props) => <PhosphorIcons.ArrowDown {...props} />,
  ArrowDownLeft: (props) => <PhosphorIcons.ArrowDownLeft {...props} />,
  ArrowDownRight: (props) => <PhosphorIcons.ArrowDownRight {...props} />,
  ArrowElbowDownLeft: (props) => (
    <PhosphorIcons.ArrowElbowDownLeft {...props} />
  ),
  ArrowElbowDownRight: (props) => (
    <PhosphorIcons.ArrowElbowDownRight {...props} />
  ),
  ArrowElbowLeft: (props) => <PhosphorIcons.ArrowElbowLeft {...props} />,
  ArrowElbowLeftDown: (props) => (
    <PhosphorIcons.ArrowElbowLeftDown {...props} />
  ),
  ArrowElbowLeftUp: (props) => <PhosphorIcons.ArrowElbowLeftUp {...props} />,
  ArrowElbowRight: (props) => <PhosphorIcons.ArrowElbowRight {...props} />,
  ArrowElbowRightDown: (props) => (
    <PhosphorIcons.ArrowElbowRightDown {...props} />
  ),
  ArrowElbowRightUp: (props) => <PhosphorIcons.ArrowElbowRightUp {...props} />,
  ArrowElbowUpLeft: (props) => <PhosphorIcons.ArrowElbowUpLeft {...props} />,
  ArrowElbowUpRight: (props) => <PhosphorIcons.ArrowElbowUpRight {...props} />,
  ArrowFatDown: (props) => <PhosphorIcons.ArrowFatDown {...props} />,
  ArrowFatLeft: (props) => <PhosphorIcons.ArrowFatLeft {...props} />,
  ArrowFatLineDown: (props) => <PhosphorIcons.ArrowFatLineDown {...props} />,
  ArrowFatLineLeft: (props) => <PhosphorIcons.ArrowFatLineLeft {...props} />,
  ArrowFatLineRight: (props) => <PhosphorIcons.ArrowFatLineRight {...props} />,
  ArrowFatLineUp: (props) => <PhosphorIcons.ArrowFatLineUp {...props} />,
  ArrowFatLinesDown: (props) => <PhosphorIcons.ArrowFatLinesDown {...props} />,
  ArrowFatLinesLeft: (props) => <PhosphorIcons.ArrowFatLinesLeft {...props} />,
  ArrowFatLinesRight: (props) => (
    <PhosphorIcons.ArrowFatLinesRight {...props} />
  ),
  ArrowFatLinesUp: (props) => <PhosphorIcons.ArrowFatLinesUp {...props} />,
  ArrowFatRight: (props) => <PhosphorIcons.ArrowFatRight {...props} />,
  ArrowFatUp: (props) => <PhosphorIcons.ArrowFatUp {...props} />,
  ArrowLeft: (props) => <PhosphorIcons.ArrowLeft {...props} />,
  ArrowLineDown: (props) => <PhosphorIcons.ArrowLineDown {...props} />,
  ArrowLineDownLeft: (props) => <PhosphorIcons.ArrowLineDownLeft {...props} />,
  ArrowLineDownRight: (props) => (
    <PhosphorIcons.ArrowLineDownRight {...props} />
  ),
  ArrowLineLeft: (props) => <PhosphorIcons.ArrowLineLeft {...props} />,
  ArrowLineRight: (props) => <PhosphorIcons.ArrowLineRight {...props} />,
  ArrowLineUp: (props) => <PhosphorIcons.ArrowLineUp {...props} />,
  ArrowLineUpLeft: (props) => <PhosphorIcons.ArrowLineUpLeft {...props} />,
  ArrowLineUpRight: (props) => <PhosphorIcons.ArrowLineUpRight {...props} />,
  ArrowRight: (props) => <PhosphorIcons.ArrowRight {...props} />,
  ArrowSquareDown: (props) => <PhosphorIcons.ArrowSquareDown {...props} />,
  ArrowSquareDownLeft: (props) => (
    <PhosphorIcons.ArrowSquareDownLeft {...props} />
  ),
  ArrowSquareDownRight: (props) => (
    <PhosphorIcons.ArrowSquareDownRight {...props} />
  ),
  ArrowSquareIn: (props) => <PhosphorIcons.ArrowSquareIn {...props} />,
  ArrowSquareLeft: (props) => <PhosphorIcons.ArrowSquareLeft {...props} />,
  ArrowSquareOut: (props) => <PhosphorIcons.ArrowSquareOut {...props} />,
  ArrowSquareRight: (props) => <PhosphorIcons.ArrowSquareRight {...props} />,
  ArrowSquareUp: (props) => <PhosphorIcons.ArrowSquareUp {...props} />,
  ArrowSquareUpLeft: (props) => <PhosphorIcons.ArrowSquareUpLeft {...props} />,
  ArrowSquareUpRight: (props) => (
    <PhosphorIcons.ArrowSquareUpRight {...props} />
  ),
  ArrowUDownLeft: (props) => <PhosphorIcons.ArrowUDownLeft {...props} />,
  ArrowUDownRight: (props) => <PhosphorIcons.ArrowUDownRight {...props} />,
  ArrowULeftDown: (props) => <PhosphorIcons.ArrowULeftDown {...props} />,
  ArrowULeftUp: (props) => <PhosphorIcons.ArrowULeftUp {...props} />,
  ArrowURightDown: (props) => <PhosphorIcons.ArrowURightDown {...props} />,
  ArrowURightUp: (props) => <PhosphorIcons.ArrowURightUp {...props} />,
  ArrowUUpLeft: (props) => <PhosphorIcons.ArrowUUpLeft {...props} />,
  ArrowUUpRight: (props) => <PhosphorIcons.ArrowUUpRight {...props} />,
  ArrowUp: (props) => <PhosphorIcons.ArrowUp {...props} />,
  ArrowUpLeft: (props) => <PhosphorIcons.ArrowUpLeft {...props} />,
  ArrowUpRight: (props) => <PhosphorIcons.ArrowUpRight {...props} />,
  ArrowsClockwise: (props) => <PhosphorIcons.ArrowsClockwise {...props} />,
  ArrowsCounterClockwise: (props) => (
    <PhosphorIcons.ArrowsCounterClockwise {...props} />
  ),
  ArrowsDownUp: (props) => <PhosphorIcons.ArrowsDownUp {...props} />,
  ArrowsHorizontal: (props) => <PhosphorIcons.ArrowsHorizontal {...props} />,
  ArrowsIn: (props) => <PhosphorIcons.ArrowsIn {...props} />,
  ArrowsInCardinal: (props) => <PhosphorIcons.ArrowsInCardinal {...props} />,
  ArrowsInLineHorizontal: (props) => (
    <PhosphorIcons.ArrowsInLineHorizontal {...props} />
  ),
  ArrowsInLineVertical: (props) => (
    <PhosphorIcons.ArrowsInLineVertical {...props} />
  ),
  ArrowsInSimple: (props) => <PhosphorIcons.ArrowsInSimple {...props} />,
  ArrowsLeftRight: (props) => <PhosphorIcons.ArrowsLeftRight {...props} />,
  ArrowsOut: (props) => <PhosphorIcons.ArrowsOut {...props} />,
  ArrowsOutCardinal: (props) => <PhosphorIcons.ArrowsOutCardinal {...props} />,
  ArrowsOutLineHorizontal: (props) => (
    <PhosphorIcons.ArrowsOutLineHorizontal {...props} />
  ),
  ArrowsOutLineVertical: (props) => (
    <PhosphorIcons.ArrowsOutLineVertical {...props} />
  ),
  ArrowsOutSimple: (props) => <PhosphorIcons.ArrowsOutSimple {...props} />,
  ArrowsVertical: (props) => <PhosphorIcons.ArrowsVertical {...props} />,
  Article: (props) => <PhosphorIcons.Article {...props} />,
  ArticleMedium: (props) => <PhosphorIcons.ArticleMedium {...props} />,
  ArticleNyTimes: (props) => <PhosphorIcons.ArticleNyTimes {...props} />,
  Asterisk: (props) => <PhosphorIcons.Asterisk {...props} />,
  AsteriskSimple: (props) => <PhosphorIcons.AsteriskSimple {...props} />,
  At: (props) => <PhosphorIcons.At {...props} />,
  Atom: (props) => <PhosphorIcons.Atom {...props} />,
  Baby: (props) => <PhosphorIcons.Baby {...props} />,
  Backpack: (props) => <PhosphorIcons.Backpack {...props} />,
  Backspace: (props) => <PhosphorIcons.Backspace {...props} />,
  Bag: (props) => <PhosphorIcons.Bag {...props} />,
  BagSimple: (props) => <PhosphorIcons.BagSimple {...props} />,
  Balloon: (props) => <PhosphorIcons.Balloon {...props} />,
  Bandaids: (props) => <PhosphorIcons.Bandaids {...props} />,
  Bank: (props) => <PhosphorIcons.Bank {...props} />,
  Barbell: (props) => <PhosphorIcons.Barbell {...props} />,
  Barcode: (props) => <PhosphorIcons.Barcode {...props} />,
  Barricade: (props) => <PhosphorIcons.Barricade {...props} />,
  Baseball: (props) => <PhosphorIcons.Baseball {...props} />,
  Basketball: (props) => <PhosphorIcons.Basketball {...props} />,
  Bathtub: (props) => <PhosphorIcons.Bathtub {...props} />,
  BatteryCharging: (props) => <PhosphorIcons.BatteryCharging {...props} />,
  BatteryChargingVertical: (props) => (
    <PhosphorIcons.BatteryChargingVertical {...props} />
  ),
  BatteryEmpty: (props) => <PhosphorIcons.BatteryEmpty {...props} />,
  BatteryFull: (props) => <PhosphorIcons.BatteryFull {...props} />,
  BatteryHigh: (props) => <PhosphorIcons.BatteryHigh {...props} />,
  BatteryLow: (props) => <PhosphorIcons.BatteryLow {...props} />,
  BatteryMedium: (props) => <PhosphorIcons.BatteryMedium {...props} />,
  BatteryPlus: (props) => <PhosphorIcons.BatteryPlus {...props} />,
  BatteryWarning: (props) => <PhosphorIcons.BatteryWarning {...props} />,
  BatteryWarningVertical: (props) => (
    <PhosphorIcons.BatteryWarningVertical {...props} />
  ),
  Bed: (props) => <PhosphorIcons.Bed {...props} />,
  BeerBottle: (props) => <PhosphorIcons.BeerBottle {...props} />,
  BehanceLogo: (props) => <PhosphorIcons.BehanceLogo {...props} />,
  Bell: (props) => <PhosphorIcons.Bell {...props} />,
  BellRinging: (props) => <PhosphorIcons.BellRinging {...props} />,
  BellSimple: (props) => <PhosphorIcons.BellSimple {...props} />,
  BellSimpleRinging: (props) => <PhosphorIcons.BellSimpleRinging {...props} />,
  BellSimpleSlash: (props) => <PhosphorIcons.BellSimpleSlash {...props} />,
  BellSimpleZ: (props) => <PhosphorIcons.BellSimpleZ {...props} />,
  BellSlash: (props) => <PhosphorIcons.BellSlash {...props} />,
  BellZ: (props) => <PhosphorIcons.BellZ {...props} />,
  BezierCurve: (props) => <PhosphorIcons.BezierCurve {...props} />,
  Bicycle: (props) => <PhosphorIcons.Bicycle {...props} />,
  Binoculars: (props) => <PhosphorIcons.Binoculars {...props} />,
  Bird: (props) => <PhosphorIcons.Bird {...props} />,
  Bluetooth: (props) => <PhosphorIcons.Bluetooth {...props} />,
  BluetoothConnected: (props) => (
    <PhosphorIcons.BluetoothConnected {...props} />
  ),
  BluetoothSlash: (props) => <PhosphorIcons.BluetoothSlash {...props} />,
  BluetoothX: (props) => <PhosphorIcons.BluetoothX {...props} />,
  Boat: (props) => <PhosphorIcons.Boat {...props} />,
  Book: (props) => <PhosphorIcons.Book {...props} />,
  BookBookmark: (props) => <PhosphorIcons.BookBookmark {...props} />,
  BookOpen: (props) => <PhosphorIcons.BookOpen {...props} />,
  Bookmark: (props) => <PhosphorIcons.Bookmark {...props} />,
  BookmarkSimple: (props) => <PhosphorIcons.BookmarkSimple {...props} />,
  Bookmarks: (props) => <PhosphorIcons.Bookmarks {...props} />,
  BookmarksSimple: (props) => <PhosphorIcons.BookmarksSimple {...props} />,
  Books: (props) => <PhosphorIcons.Books {...props} />,
  BoundingBox: (props) => <PhosphorIcons.BoundingBox {...props} />,
  BracketsAngle: (props) => <PhosphorIcons.BracketsAngle {...props} />,
  BracketsCurly: (props) => <PhosphorIcons.BracketsCurly {...props} />,
  BracketsRound: (props) => <PhosphorIcons.BracketsRound {...props} />,
  BracketsSquare: (props) => <PhosphorIcons.BracketsSquare {...props} />,
  Brain: (props) => <PhosphorIcons.Brain {...props} />,
  Brandy: (props) => <PhosphorIcons.Brandy {...props} />,
  Briefcase: (props) => <PhosphorIcons.Briefcase {...props} />,
  BriefcaseMetal: (props) => <PhosphorIcons.BriefcaseMetal {...props} />,
  Broadcast: (props) => <PhosphorIcons.Broadcast {...props} />,
  Browser: (props) => <PhosphorIcons.Browser {...props} />,
  Browsers: (props) => <PhosphorIcons.Browsers {...props} />,
  Bug: (props) => <PhosphorIcons.Bug {...props} />,
  BugBeetle: (props) => <PhosphorIcons.BugBeetle {...props} />,
  BugDroid: (props) => <PhosphorIcons.BugDroid {...props} />,
  Buildings: (props) => <PhosphorIcons.Buildings {...props} />,
  Bus: (props) => <PhosphorIcons.Bus {...props} />,
  Butterfly: (props) => <PhosphorIcons.Butterfly {...props} />,
  Cactus: (props) => <PhosphorIcons.Cactus {...props} />,
  Cake: (props) => <PhosphorIcons.Cake {...props} />,
  Calculator: (props) => <PhosphorIcons.Calculator {...props} />,
  Calendar: (props) => <PhosphorIcons.Calendar {...props} />,
  CalendarBlank: (props) => <PhosphorIcons.CalendarBlank {...props} />,
  CalendarCheck: (props) => <PhosphorIcons.CalendarCheck {...props} />,
  CalendarPlus: (props) => <PhosphorIcons.CalendarPlus {...props} />,
  CalendarX: (props) => <PhosphorIcons.CalendarX {...props} />,
  Camera: (props) => <PhosphorIcons.Camera {...props} />,
  CameraRotate: (props) => <PhosphorIcons.CameraRotate {...props} />,
  CameraSlash: (props) => <PhosphorIcons.CameraSlash {...props} />,
  Campfire: (props) => <PhosphorIcons.Campfire {...props} />,
  Car: (props) => <PhosphorIcons.Car {...props} />,
  CarSimple: (props) => <PhosphorIcons.CarSimple {...props} />,
  Cardholder: (props) => <PhosphorIcons.Cardholder {...props} />,
  Cards: (props) => <PhosphorIcons.Cards {...props} />,
  CaretCircleDoubleDown: (props) => (
    <PhosphorIcons.CaretCircleDoubleDown {...props} />
  ),
  CaretCircleDoubleLeft: (props) => (
    <PhosphorIcons.CaretCircleDoubleLeft {...props} />
  ),
  CaretCircleDoubleRight: (props) => (
    <PhosphorIcons.CaretCircleDoubleRight {...props} />
  ),
  CaretCircleDoubleUp: (props) => (
    <PhosphorIcons.CaretCircleDoubleUp {...props} />
  ),
  CaretCircleDown: (props) => <PhosphorIcons.CaretCircleDown {...props} />,
  CaretCircleLeft: (props) => <PhosphorIcons.CaretCircleLeft {...props} />,
  CaretCircleRight: (props) => <PhosphorIcons.CaretCircleRight {...props} />,
  CaretCircleUp: (props) => <PhosphorIcons.CaretCircleUp {...props} />,
  CaretDoubleDown: (props) => <PhosphorIcons.CaretDoubleDown {...props} />,
  CaretDoubleLeft: (props) => <PhosphorIcons.CaretDoubleLeft {...props} />,
  CaretDoubleRight: (props) => <PhosphorIcons.CaretDoubleRight {...props} />,
  CaretDoubleUp: (props) => <PhosphorIcons.CaretDoubleUp {...props} />,
  CaretDown: (props) => <PhosphorIcons.CaretDown {...props} />,
  CaretLeft: (props) => <PhosphorIcons.CaretLeft {...props} />,
  CaretRight: (props) => <PhosphorIcons.CaretRight {...props} />,
  CaretUp: (props) => <PhosphorIcons.CaretUp {...props} />,
  Cat: (props) => <PhosphorIcons.Cat {...props} />,
  CellSignalFull: (props) => <PhosphorIcons.CellSignalFull {...props} />,
  CellSignalHigh: (props) => <PhosphorIcons.CellSignalHigh {...props} />,
  CellSignalLow: (props) => <PhosphorIcons.CellSignalLow {...props} />,
  CellSignalMedium: (props) => <PhosphorIcons.CellSignalMedium {...props} />,
  CellSignalNone: (props) => <PhosphorIcons.CellSignalNone {...props} />,
  CellSignalSlash: (props) => <PhosphorIcons.CellSignalSlash {...props} />,
  CellSignalX: (props) => <PhosphorIcons.CellSignalX {...props} />,
  Chalkboard: (props) => <PhosphorIcons.Chalkboard {...props} />,
  ChalkboardSimple: (props) => <PhosphorIcons.ChalkboardSimple {...props} />,
  ChalkboardTeacher: (props) => <PhosphorIcons.ChalkboardTeacher {...props} />,
  ChartBar: (props) => <PhosphorIcons.ChartBar {...props} />,
  ChartBarHorizontal: (props) => (
    <PhosphorIcons.ChartBarHorizontal {...props} />
  ),
  ChartLine: (props) => <PhosphorIcons.ChartLine {...props} />,
  ChartLineUp: (props) => <PhosphorIcons.ChartLineUp {...props} />,
  ChartPie: (props) => <PhosphorIcons.ChartPie {...props} />,
  ChartPieSlice: (props) => <PhosphorIcons.ChartPieSlice {...props} />,
  Chat: (props) => <PhosphorIcons.Chat {...props} />,
  ChatCentered: (props) => <PhosphorIcons.ChatCentered {...props} />,
  ChatCenteredDots: (props) => <PhosphorIcons.ChatCenteredDots {...props} />,
  ChatCenteredText: (props) => <PhosphorIcons.ChatCenteredText {...props} />,
  ChatCircle: (props) => <PhosphorIcons.ChatCircle {...props} />,
  ChatCircleDots: (props) => <PhosphorIcons.ChatCircleDots {...props} />,
  ChatCircleText: (props) => <PhosphorIcons.ChatCircleText {...props} />,
  ChatDots: (props) => <PhosphorIcons.ChatDots {...props} />,
  ChatTeardrop: (props) => <PhosphorIcons.ChatTeardrop {...props} />,
  ChatTeardropDots: (props) => <PhosphorIcons.ChatTeardropDots {...props} />,
  ChatTeardropText: (props) => <PhosphorIcons.ChatTeardropText {...props} />,
  ChatText: (props) => <PhosphorIcons.ChatText {...props} />,
  Chats: (props) => <PhosphorIcons.Chats {...props} />,
  ChatsCircle: (props) => <PhosphorIcons.ChatsCircle {...props} />,
  ChatsTeardrop: (props) => <PhosphorIcons.ChatsTeardrop {...props} />,
  Check: (props) => <PhosphorIcons.Check {...props} />,
  CheckCircle: (props) => <PhosphorIcons.CheckCircle {...props} />,
  CheckSquare: (props) => <PhosphorIcons.CheckSquare {...props} />,
  CheckSquareOffset: (props) => <PhosphorIcons.CheckSquareOffset {...props} />,
  Checks: (props) => <PhosphorIcons.Checks {...props} />,
  Circle: (props) => <PhosphorIcons.Circle {...props} />,
  CircleDashed: (props) => <PhosphorIcons.CircleDashed {...props} />,
  CircleHalf: (props) => <PhosphorIcons.CircleHalf {...props} />,
  CircleHalfTilt: (props) => <PhosphorIcons.CircleHalfTilt {...props} />,
  CircleNotch: (props) => <PhosphorIcons.CircleNotch {...props} />,
  CircleWavy: (props) => <PhosphorIcons.CircleWavy {...props} />,
  CircleWavyCheck: (props) => <PhosphorIcons.CircleWavyCheck {...props} />,
  CircleWavyQuestion: (props) => (
    <PhosphorIcons.CircleWavyQuestion {...props} />
  ),
  CircleWavyWarning: (props) => <PhosphorIcons.CircleWavyWarning {...props} />,
  CirclesFour: (props) => <PhosphorIcons.CirclesFour {...props} />,
  CirclesThree: (props) => <PhosphorIcons.CirclesThree {...props} />,
  CirclesThreePlus: (props) => <PhosphorIcons.CirclesThreePlus {...props} />,
  Clipboard: (props) => <PhosphorIcons.Clipboard {...props} />,
  ClipboardText: (props) => <PhosphorIcons.ClipboardText {...props} />,
  Clock: (props) => <PhosphorIcons.Clock {...props} />,
  ClockAfternoon: (props) => <PhosphorIcons.ClockAfternoon {...props} />,
  ClockClockwise: (props) => <PhosphorIcons.ClockClockwise {...props} />,
  ClockCounterClockwise: (props) => (
    <PhosphorIcons.ClockCounterClockwise {...props} />
  ),
  ClosedCaptioning: (props) => <PhosphorIcons.ClosedCaptioning {...props} />,
  Cloud: (props) => <PhosphorIcons.Cloud {...props} />,
  CloudArrowDown: (props) => <PhosphorIcons.CloudArrowDown {...props} />,
  CloudArrowUp: (props) => <PhosphorIcons.CloudArrowUp {...props} />,
  CloudCheck: (props) => <PhosphorIcons.CloudCheck {...props} />,
  CloudFog: (props) => <PhosphorIcons.CloudFog {...props} />,
  CloudLightning: (props) => <PhosphorIcons.CloudLightning {...props} />,
  CloudMoon: (props) => <PhosphorIcons.CloudMoon {...props} />,
  CloudRain: (props) => <PhosphorIcons.CloudRain {...props} />,
  CloudSlash: (props) => <PhosphorIcons.CloudSlash {...props} />,
  CloudSnow: (props) => <PhosphorIcons.CloudSnow {...props} />,
  CloudSun: (props) => <PhosphorIcons.CloudSun {...props} />,
  Club: (props) => <PhosphorIcons.Club {...props} />,
  CoatHanger: (props) => <PhosphorIcons.CoatHanger {...props} />,
  Code: (props) => <PhosphorIcons.Code {...props} />,
  CodeSimple: (props) => <PhosphorIcons.CodeSimple {...props} />,
  CodepenLogo: (props) => <PhosphorIcons.CodepenLogo {...props} />,
  CodesandboxLogo: (props) => <PhosphorIcons.CodesandboxLogo {...props} />,
  Coffee: (props) => <PhosphorIcons.Coffee {...props} />,
  Coin: (props) => <PhosphorIcons.Coin {...props} />,
  CoinVertical: (props) => <PhosphorIcons.CoinVertical {...props} />,
  Coins: (props) => <PhosphorIcons.Coins {...props} />,
  Columns: (props) => <PhosphorIcons.Columns {...props} />,
  Command: (props) => <PhosphorIcons.Command {...props} />,
  Compass: (props) => <PhosphorIcons.Compass {...props} />,
  ComputerTower: (props) => <PhosphorIcons.ComputerTower {...props} />,
  Confetti: (props) => <PhosphorIcons.Confetti {...props} />,
  Cookie: (props) => <PhosphorIcons.Cookie {...props} />,
  CookingPot: (props) => <PhosphorIcons.CookingPot {...props} />,
  Copy: (props) => <PhosphorIcons.Copy {...props} />,
  CopySimple: (props) => <PhosphorIcons.CopySimple {...props} />,
  Copyleft: (props) => <PhosphorIcons.Copyleft {...props} />,
  Copyright: (props) => <PhosphorIcons.Copyright {...props} />,
  CornersIn: (props) => <PhosphorIcons.CornersIn {...props} />,
  CornersOut: (props) => <PhosphorIcons.CornersOut {...props} />,
  Cpu: (props) => <PhosphorIcons.Cpu {...props} />,
  CreditCard: (props) => <PhosphorIcons.CreditCard {...props} />,
  Crop: (props) => <PhosphorIcons.Crop {...props} />,
  Crosshair: (props) => <PhosphorIcons.Crosshair {...props} />,
  CrosshairSimple: (props) => <PhosphorIcons.CrosshairSimple {...props} />,
  Crown: (props) => <PhosphorIcons.Crown {...props} />,
  CrownSimple: (props) => <PhosphorIcons.CrownSimple {...props} />,
  Cube: (props) => <PhosphorIcons.Cube {...props} />,
  CurrencyBtc: (props) => <PhosphorIcons.CurrencyBtc {...props} />,
  CurrencyCircleDollar: (props) => (
    <PhosphorIcons.CurrencyCircleDollar {...props} />
  ),
  CurrencyCny: (props) => <PhosphorIcons.CurrencyCny {...props} />,
  CurrencyDollar: (props) => <PhosphorIcons.CurrencyDollar {...props} />,
  CurrencyDollarSimple: (props) => (
    <PhosphorIcons.CurrencyDollarSimple {...props} />
  ),
  CurrencyEth: (props) => <PhosphorIcons.CurrencyEth {...props} />,
  CurrencyEur: (props) => <PhosphorIcons.CurrencyEur {...props} />,
  CurrencyGbp: (props) => <PhosphorIcons.CurrencyGbp {...props} />,
  CurrencyInr: (props) => <PhosphorIcons.CurrencyInr {...props} />,
  CurrencyJpy: (props) => <PhosphorIcons.CurrencyJpy {...props} />,
  CurrencyKrw: (props) => <PhosphorIcons.CurrencyKrw {...props} />,
  CurrencyKzt: (props) => <PhosphorIcons.CurrencyKzt {...props} />,
  CurrencyNgn: (props) => <PhosphorIcons.CurrencyNgn {...props} />,
  CurrencyRub: (props) => <PhosphorIcons.CurrencyRub {...props} />,
  Cursor: (props) => <PhosphorIcons.Cursor {...props} />,
  CursorText: (props) => <PhosphorIcons.CursorText {...props} />,
  Cylinder: (props) => <PhosphorIcons.Cylinder {...props} />,
  Database: (props) => <PhosphorIcons.Database {...props} />,
  Desktop: (props) => <PhosphorIcons.Desktop {...props} />,
  DesktopTower: (props) => <PhosphorIcons.DesktopTower {...props} />,
  Detective: (props) => <PhosphorIcons.Detective {...props} />,
  DeviceMobile: (props) => <PhosphorIcons.DeviceMobile {...props} />,
  DeviceMobileCamera: (props) => (
    <PhosphorIcons.DeviceMobileCamera {...props} />
  ),
  DeviceMobileSpeaker: (props) => (
    <PhosphorIcons.DeviceMobileSpeaker {...props} />
  ),
  DeviceTablet: (props) => <PhosphorIcons.DeviceTablet {...props} />,
  DeviceTabletCamera: (props) => (
    <PhosphorIcons.DeviceTabletCamera {...props} />
  ),
  DeviceTabletSpeaker: (props) => (
    <PhosphorIcons.DeviceTabletSpeaker {...props} />
  ),
  Diamond: (props) => <PhosphorIcons.Diamond {...props} />,
  DiamondsFour: (props) => <PhosphorIcons.DiamondsFour {...props} />,
  DiceFive: (props) => <PhosphorIcons.DiceFive {...props} />,
  DiceFour: (props) => <PhosphorIcons.DiceFour {...props} />,
  DiceOne: (props) => <PhosphorIcons.DiceOne {...props} />,
  DiceSix: (props) => <PhosphorIcons.DiceSix {...props} />,
  DiceThree: (props) => <PhosphorIcons.DiceThree {...props} />,
  DiceTwo: (props) => <PhosphorIcons.DiceTwo {...props} />,
  Disc: (props) => <PhosphorIcons.Disc {...props} />,
  DiscordLogo: (props) => <PhosphorIcons.DiscordLogo {...props} />,
  Divide: (props) => <PhosphorIcons.Divide {...props} />,
  Dog: (props) => <PhosphorIcons.Dog {...props} />,
  Door: (props) => <PhosphorIcons.Door {...props} />,
  DotsNine: (props) => <PhosphorIcons.DotsNine {...props} />,
  DotsSix: (props) => <PhosphorIcons.DotsSix {...props} />,
  DotsSixVertical: (props) => <PhosphorIcons.DotsSixVertical {...props} />,
  DotsThree: (props) => <PhosphorIcons.DotsThree {...props} />,
  DotsThreeCircle: (props) => <PhosphorIcons.DotsThreeCircle {...props} />,
  DotsThreeCircleVertical: (props) => (
    <PhosphorIcons.DotsThreeCircleVertical {...props} />
  ),
  DotsThreeOutline: (props) => <PhosphorIcons.DotsThreeOutline {...props} />,
  DotsThreeOutlineVertical: (props) => (
    <PhosphorIcons.DotsThreeOutlineVertical {...props} />
  ),
  DotsThreeVertical: (props) => <PhosphorIcons.DotsThreeVertical {...props} />,
  Download: (props) => <PhosphorIcons.Download {...props} />,
  DownloadSimple: (props) => <PhosphorIcons.DownloadSimple {...props} />,
  DribbbleLogo: (props) => <PhosphorIcons.DribbbleLogo {...props} />,
  Drop: (props) => <PhosphorIcons.Drop {...props} />,
  DropHalf: (props) => <PhosphorIcons.DropHalf {...props} />,
  DropHalfBottom: (props) => <PhosphorIcons.DropHalfBottom {...props} />,
  Ear: (props) => <PhosphorIcons.Ear {...props} />,
  EarSlash: (props) => <PhosphorIcons.EarSlash {...props} />,
  Egg: (props) => <PhosphorIcons.Egg {...props} />,
  EggCrack: (props) => <PhosphorIcons.EggCrack {...props} />,
  Eject: (props) => <PhosphorIcons.Eject {...props} />,
  EjectSimple: (props) => <PhosphorIcons.EjectSimple {...props} />,
  Envelope: (props) => <PhosphorIcons.Envelope {...props} />,
  EnvelopeOpen: (props) => <PhosphorIcons.EnvelopeOpen {...props} />,
  EnvelopeSimple: (props) => <PhosphorIcons.EnvelopeSimple {...props} />,
  EnvelopeSimpleOpen: (props) => (
    <PhosphorIcons.EnvelopeSimpleOpen {...props} />
  ),
  Equalizer: (props) => <PhosphorIcons.Equalizer {...props} />,
  Equals: (props) => <PhosphorIcons.Equals {...props} />,
  Eraser: (props) => <PhosphorIcons.Eraser {...props} />,
  Exam: (props) => <PhosphorIcons.Exam {...props} />,
  Export: (props) => <PhosphorIcons.Export {...props} />,
  Eye: (props) => <PhosphorIcons.Eye {...props} />,
  EyeClosed: (props) => <PhosphorIcons.EyeClosed {...props} />,
  EyeSlash: (props) => <PhosphorIcons.EyeSlash {...props} />,
  Eyedropper: (props) => <PhosphorIcons.Eyedropper {...props} />,
  EyedropperSample: (props) => <PhosphorIcons.EyedropperSample {...props} />,
  Eyeglasses: (props) => <PhosphorIcons.Eyeglasses {...props} />,
  FaceMask: (props) => <PhosphorIcons.FaceMask {...props} />,
  FacebookLogo: (props) => <PhosphorIcons.FacebookLogo {...props} />,
  Factory: (props) => <PhosphorIcons.Factory {...props} />,
  Faders: (props) => <PhosphorIcons.Faders {...props} />,
  FadersHorizontal: (props) => <PhosphorIcons.FadersHorizontal {...props} />,
  FastForward: (props) => <PhosphorIcons.FastForward {...props} />,
  FastForwardCircle: (props) => <PhosphorIcons.FastForwardCircle {...props} />,
  FigmaLogo: (props) => <PhosphorIcons.FigmaLogo {...props} />,
  File: (props) => <PhosphorIcons.File {...props} />,
  FileArrowDown: (props) => <PhosphorIcons.FileArrowDown {...props} />,
  FileArrowUp: (props) => <PhosphorIcons.FileArrowUp {...props} />,
  FileAudio: (props) => <PhosphorIcons.FileAudio {...props} />,
  FileCloud: (props) => <PhosphorIcons.FileCloud {...props} />,
  FileCode: (props) => <PhosphorIcons.FileCode {...props} />,
  FileCss: (props) => <PhosphorIcons.FileCss {...props} />,
  FileCsv: (props) => <PhosphorIcons.FileCsv {...props} />,
  FileDoc: (props) => <PhosphorIcons.FileDoc {...props} />,
  FileDotted: (props) => <PhosphorIcons.FileDotted {...props} />,
  FileHtml: (props) => <PhosphorIcons.FileHtml {...props} />,
  FileImage: (props) => <PhosphorIcons.FileImage {...props} />,
  FileJpg: (props) => <PhosphorIcons.FileJpg {...props} />,
  FileJs: (props) => <PhosphorIcons.FileJs {...props} />,
  FileJsx: (props) => <PhosphorIcons.FileJsx {...props} />,
  FileLock: (props) => <PhosphorIcons.FileLock {...props} />,
  FileMinus: (props) => <PhosphorIcons.FileMinus {...props} />,
  FilePdf: (props) => <PhosphorIcons.FilePdf {...props} />,
  FilePlus: (props) => <PhosphorIcons.FilePlus {...props} />,
  FilePng: (props) => <PhosphorIcons.FilePng {...props} />,
  FilePpt: (props) => <PhosphorIcons.FilePpt {...props} />,
  FileRs: (props) => <PhosphorIcons.FileRs {...props} />,
  FileSearch: (props) => <PhosphorIcons.FileSearch {...props} />,
  FileText: (props) => <PhosphorIcons.FileText {...props} />,
  FileTs: (props) => <PhosphorIcons.FileTs {...props} />,
  FileTsx: (props) => <PhosphorIcons.FileTsx {...props} />,
  FileVideo: (props) => <PhosphorIcons.FileVideo {...props} />,
  FileVue: (props) => <PhosphorIcons.FileVue {...props} />,
  FileX: (props) => <PhosphorIcons.FileX {...props} />,
  FileXls: (props) => <PhosphorIcons.FileXls {...props} />,
  FileZip: (props) => <PhosphorIcons.FileZip {...props} />,
  Files: (props) => <PhosphorIcons.Files {...props} />,
  FilmScript: (props) => <PhosphorIcons.FilmScript {...props} />,
  FilmSlate: (props) => <PhosphorIcons.FilmSlate {...props} />,
  FilmStrip: (props) => <PhosphorIcons.FilmStrip {...props} />,
  Fingerprint: (props) => <PhosphorIcons.Fingerprint {...props} />,
  FingerprintSimple: (props) => <PhosphorIcons.FingerprintSimple {...props} />,
  FinnTheHuman: (props) => <PhosphorIcons.FinnTheHuman {...props} />,
  Fire: (props) => <PhosphorIcons.Fire {...props} />,
  FireSimple: (props) => <PhosphorIcons.FireSimple {...props} />,
  FirstAid: (props) => <PhosphorIcons.FirstAid {...props} />,
  FirstAidKit: (props) => <PhosphorIcons.FirstAidKit {...props} />,
  Fish: (props) => <PhosphorIcons.Fish {...props} />,
  FishSimple: (props) => <PhosphorIcons.FishSimple {...props} />,
  Flag: (props) => <PhosphorIcons.Flag {...props} />,
  FlagBanner: (props) => <PhosphorIcons.FlagBanner {...props} />,
  FlagCheckered: (props) => <PhosphorIcons.FlagCheckered {...props} />,
  Flame: (props) => <PhosphorIcons.Flame {...props} />,
  Flashlight: (props) => <PhosphorIcons.Flashlight {...props} />,
  Flask: (props) => <PhosphorIcons.Flask {...props} />,
  FloppyDisk: (props) => <PhosphorIcons.FloppyDisk {...props} />,
  FloppyDiskBack: (props) => <PhosphorIcons.FloppyDiskBack {...props} />,
  FlowArrow: (props) => <PhosphorIcons.FlowArrow {...props} />,
  Flower: (props) => <PhosphorIcons.Flower {...props} />,
  FlowerLotus: (props) => <PhosphorIcons.FlowerLotus {...props} />,
  FlyingSaucer: (props) => <PhosphorIcons.FlyingSaucer {...props} />,
  Folder: (props) => <PhosphorIcons.Folder {...props} />,
  FolderDotted: (props) => <PhosphorIcons.FolderDotted {...props} />,
  FolderLock: (props) => <PhosphorIcons.FolderLock {...props} />,
  FolderMinus: (props) => <PhosphorIcons.FolderMinus {...props} />,
  FolderNotch: (props) => <PhosphorIcons.FolderNotch {...props} />,
  FolderNotchMinus: (props) => <PhosphorIcons.FolderNotchMinus {...props} />,
  FolderNotchOpen: (props) => <PhosphorIcons.FolderNotchOpen {...props} />,
  FolderNotchPlus: (props) => <PhosphorIcons.FolderNotchPlus {...props} />,
  FolderOpen: (props) => <PhosphorIcons.FolderOpen {...props} />,
  FolderPlus: (props) => <PhosphorIcons.FolderPlus {...props} />,
  FolderSimple: (props) => <PhosphorIcons.FolderSimple {...props} />,
  FolderSimpleDotted: (props) => (
    <PhosphorIcons.FolderSimpleDotted {...props} />
  ),
  FolderSimpleLock: (props) => <PhosphorIcons.FolderSimpleLock {...props} />,
  FolderSimpleMinus: (props) => <PhosphorIcons.FolderSimpleMinus {...props} />,
  FolderSimplePlus: (props) => <PhosphorIcons.FolderSimplePlus {...props} />,
  FolderSimpleStar: (props) => <PhosphorIcons.FolderSimpleStar {...props} />,
  FolderSimpleUser: (props) => <PhosphorIcons.FolderSimpleUser {...props} />,
  FolderStar: (props) => <PhosphorIcons.FolderStar {...props} />,
  FolderUser: (props) => <PhosphorIcons.FolderUser {...props} />,
  Folders: (props) => <PhosphorIcons.Folders {...props} />,
  Football: (props) => <PhosphorIcons.Football {...props} />,
  ForkKnife: (props) => <PhosphorIcons.ForkKnife {...props} />,
  FrameCorners: (props) => <PhosphorIcons.FrameCorners {...props} />,
  FramerLogo: (props) => <PhosphorIcons.FramerLogo {...props} />,
  Function: (props) => <PhosphorIcons.Function {...props} />,
  Funnel: (props) => <PhosphorIcons.Funnel {...props} />,
  FunnelSimple: (props) => <PhosphorIcons.FunnelSimple {...props} />,
  GameController: (props) => <PhosphorIcons.GameController {...props} />,
  GasPump: (props) => <PhosphorIcons.GasPump {...props} />,
  Gauge: (props) => <PhosphorIcons.Gauge {...props} />,
  Gear: (props) => <PhosphorIcons.Gear {...props} />,
  GearSix: (props) => <PhosphorIcons.GearSix {...props} />,
  GenderFemale: (props) => <PhosphorIcons.GenderFemale {...props} />,
  GenderIntersex: (props) => <PhosphorIcons.GenderIntersex {...props} />,
  GenderMale: (props) => <PhosphorIcons.GenderMale {...props} />,
  GenderNeuter: (props) => <PhosphorIcons.GenderNeuter {...props} />,
  GenderNonbinary: (props) => <PhosphorIcons.GenderNonbinary {...props} />,
  GenderTransgender: (props) => <PhosphorIcons.GenderTransgender {...props} />,
  Ghost: (props) => <PhosphorIcons.Ghost {...props} />,
  Gif: (props) => <PhosphorIcons.Gif {...props} />,
  Gift: (props) => <PhosphorIcons.Gift {...props} />,
  GitBranch: (props) => <PhosphorIcons.GitBranch {...props} />,
  GitCommit: (props) => <PhosphorIcons.GitCommit {...props} />,
  GitDiff: (props) => <PhosphorIcons.GitDiff {...props} />,
  GitFork: (props) => <PhosphorIcons.GitFork {...props} />,
  GitMerge: (props) => <PhosphorIcons.GitMerge {...props} />,
  GitPullRequest: (props) => <PhosphorIcons.GitPullRequest {...props} />,
  GithubLogo: (props) => <PhosphorIcons.GithubLogo {...props} />,
  GitlabLogo: (props) => <PhosphorIcons.GitlabLogo {...props} />,
  GitlabLogoSimple: (props) => <PhosphorIcons.GitlabLogoSimple {...props} />,
  Globe: (props) => <PhosphorIcons.Globe {...props} />,
  GlobeHemisphereEast: (props) => (
    <PhosphorIcons.GlobeHemisphereEast {...props} />
  ),
  GlobeHemisphereWest: (props) => (
    <PhosphorIcons.GlobeHemisphereWest {...props} />
  ),
  GlobeSimple: (props) => <PhosphorIcons.GlobeSimple {...props} />,
  GlobeStand: (props) => <PhosphorIcons.GlobeStand {...props} />,
  GoogleChromeLogo: (props) => <PhosphorIcons.GoogleChromeLogo {...props} />,
  GoogleLogo: (props) => <PhosphorIcons.GoogleLogo {...props} />,
  GooglePhotosLogo: (props) => <PhosphorIcons.GooglePhotosLogo {...props} />,
  GooglePlayLogo: (props) => <PhosphorIcons.GooglePlayLogo {...props} />,
  GooglePodcastsLogo: (props) => (
    <PhosphorIcons.GooglePodcastsLogo {...props} />
  ),
  Gradient: (props) => <PhosphorIcons.Gradient {...props} />,
  GraduationCap: (props) => <PhosphorIcons.GraduationCap {...props} />,
  Graph: (props) => <PhosphorIcons.Graph {...props} />,
  GridFour: (props) => <PhosphorIcons.GridFour {...props} />,
  Hamburger: (props) => <PhosphorIcons.Hamburger {...props} />,
  Hand: (props) => <PhosphorIcons.Hand {...props} />,
  HandEye: (props) => <PhosphorIcons.HandEye {...props} />,
  HandFist: (props) => <PhosphorIcons.HandFist {...props} />,
  HandGrabbing: (props) => <PhosphorIcons.HandGrabbing {...props} />,
  HandPalm: (props) => <PhosphorIcons.HandPalm {...props} />,
  HandPointing: (props) => <PhosphorIcons.HandPointing {...props} />,
  HandSoap: (props) => <PhosphorIcons.HandSoap {...props} />,
  HandWaving: (props) => <PhosphorIcons.HandWaving {...props} />,
  Handbag: (props) => <PhosphorIcons.Handbag {...props} />,
  HandbagSimple: (props) => <PhosphorIcons.HandbagSimple {...props} />,
  HandsClapping: (props) => <PhosphorIcons.HandsClapping {...props} />,
  Handshake: (props) => <PhosphorIcons.Handshake {...props} />,
  HardDrive: (props) => <PhosphorIcons.HardDrive {...props} />,
  HardDrives: (props) => <PhosphorIcons.HardDrives {...props} />,
  Hash: (props) => <PhosphorIcons.Hash {...props} />,
  HashStraight: (props) => <PhosphorIcons.HashStraight {...props} />,
  Headlights: (props) => <PhosphorIcons.Headlights {...props} />,
  Headphones: (props) => <PhosphorIcons.Headphones {...props} />,
  Headset: (props) => <PhosphorIcons.Headset {...props} />,
  Heart: (props) => <PhosphorIcons.Heart {...props} />,
  HeartBreak: (props) => <PhosphorIcons.HeartBreak {...props} />,
  HeartStraight: (props) => <PhosphorIcons.HeartStraight {...props} />,
  HeartStraightBreak: (props) => (
    <PhosphorIcons.HeartStraightBreak {...props} />
  ),
  Heartbeat: (props) => <PhosphorIcons.Heartbeat {...props} />,
  Hexagon: (props) => <PhosphorIcons.Hexagon {...props} />,
  HighlighterCircle: (props) => <PhosphorIcons.HighlighterCircle {...props} />,
  Horse: (props) => <PhosphorIcons.Horse {...props} />,
  Hourglass: (props) => <PhosphorIcons.Hourglass {...props} />,
  HourglassHigh: (props) => <PhosphorIcons.HourglassHigh {...props} />,
  HourglassLow: (props) => <PhosphorIcons.HourglassLow {...props} />,
  HourglassMedium: (props) => <PhosphorIcons.HourglassMedium {...props} />,
  HourglassSimple: (props) => <PhosphorIcons.HourglassSimple {...props} />,
  HourglassSimpleHigh: (props) => (
    <PhosphorIcons.HourglassSimpleHigh {...props} />
  ),
  HourglassSimpleLow: (props) => (
    <PhosphorIcons.HourglassSimpleLow {...props} />
  ),
  HourglassSimpleMedium: (props) => (
    <PhosphorIcons.HourglassSimpleMedium {...props} />
  ),
  House: (props) => <PhosphorIcons.House {...props} />,
  HouseLine: (props) => <PhosphorIcons.HouseLine {...props} />,
  HouseSimple: (props) => <PhosphorIcons.HouseSimple {...props} />,
  IdentificationBadge: (props) => (
    <PhosphorIcons.IdentificationBadge {...props} />
  ),
  IdentificationCard: (props) => (
    <PhosphorIcons.IdentificationCard {...props} />
  ),
  Image: (props) => <PhosphorIcons.Image {...props} />,
  ImageSquare: (props) => <PhosphorIcons.ImageSquare {...props} />,
  Infinity: (props) => <PhosphorIcons.Infinity {...props} />,
  Info: (props) => <PhosphorIcons.Info {...props} />,
  InstagramLogo: (props) => <PhosphorIcons.InstagramLogo {...props} />,
  Intersect: (props) => <PhosphorIcons.Intersect {...props} />,
  Jeep: (props) => <PhosphorIcons.Jeep {...props} />,
  Kanban: (props) => <PhosphorIcons.Kanban {...props} />,
  Key: (props) => <PhosphorIcons.Key {...props} />,
  KeyReturn: (props) => <PhosphorIcons.KeyReturn {...props} />,
  Keyboard: (props) => <PhosphorIcons.Keyboard {...props} />,
  Keyhole: (props) => <PhosphorIcons.Keyhole {...props} />,
  Knife: (props) => <PhosphorIcons.Knife {...props} />,
  Ladder: (props) => <PhosphorIcons.Ladder {...props} />,
  LadderSimple: (props) => <PhosphorIcons.LadderSimple {...props} />,
  Lamp: (props) => <PhosphorIcons.Lamp {...props} />,
  Laptop: (props) => <PhosphorIcons.Laptop {...props} />,
  Layout: (props) => <PhosphorIcons.Layout {...props} />,
  Leaf: (props) => <PhosphorIcons.Leaf {...props} />,
  Lifebuoy: (props) => <PhosphorIcons.Lifebuoy {...props} />,
  Lightbulb: (props) => <PhosphorIcons.Lightbulb {...props} />,
  LightbulbFilament: (props) => <PhosphorIcons.LightbulbFilament {...props} />,
  Lightning: (props) => <PhosphorIcons.Lightning {...props} />,
  LightningSlash: (props) => <PhosphorIcons.LightningSlash {...props} />,
  LineSegment: (props) => <PhosphorIcons.LineSegment {...props} />,
  LineSegments: (props) => <PhosphorIcons.LineSegments {...props} />,
  Link: (props) => <PhosphorIcons.Link {...props} />,
  LinkBreak: (props) => <PhosphorIcons.LinkBreak {...props} />,
  LinkSimple: (props) => <PhosphorIcons.LinkSimple {...props} />,
  LinkSimpleBreak: (props) => <PhosphorIcons.LinkSimpleBreak {...props} />,
  LinkSimpleHorizontal: (props) => (
    <PhosphorIcons.LinkSimpleHorizontal {...props} />
  ),
  LinkSimpleHorizontalBreak: (props) => (
    <PhosphorIcons.LinkSimpleHorizontalBreak {...props} />
  ),
  LinkedinLogo: (props) => <PhosphorIcons.LinkedinLogo {...props} />,
  LinuxLogo: (props) => <PhosphorIcons.LinuxLogo {...props} />,
  List: (props) => <PhosphorIcons.List {...props} />,
  ListBullets: (props) => <PhosphorIcons.ListBullets {...props} />,
  ListChecks: (props) => <PhosphorIcons.ListChecks {...props} />,
  ListDashes: (props) => <PhosphorIcons.ListDashes {...props} />,
  ListNumbers: (props) => <PhosphorIcons.ListNumbers {...props} />,
  ListPlus: (props) => <PhosphorIcons.ListPlus {...props} />,
  Lock: (props) => <PhosphorIcons.Lock {...props} />,
  LockKey: (props) => <PhosphorIcons.LockKey {...props} />,
  LockKeyOpen: (props) => <PhosphorIcons.LockKeyOpen {...props} />,
  LockLaminated: (props) => <PhosphorIcons.LockLaminated {...props} />,
  LockLaminatedOpen: (props) => <PhosphorIcons.LockLaminatedOpen {...props} />,
  LockOpen: (props) => <PhosphorIcons.LockOpen {...props} />,
  LockSimple: (props) => <PhosphorIcons.LockSimple {...props} />,
  LockSimpleOpen: (props) => <PhosphorIcons.LockSimpleOpen {...props} />,
  MagicWand: (props) => <PhosphorIcons.MagicWand {...props} />,
  Magnet: (props) => <PhosphorIcons.Magnet {...props} />,
  MagnetStraight: (props) => <PhosphorIcons.MagnetStraight {...props} />,
  MagnifyingGlass: (props) => <PhosphorIcons.MagnifyingGlass {...props} />,
  MagnifyingGlassMinus: (props) => (
    <PhosphorIcons.MagnifyingGlassMinus {...props} />
  ),
  MagnifyingGlassPlus: (props) => (
    <PhosphorIcons.MagnifyingGlassPlus {...props} />
  ),
  MapPin: (props) => <PhosphorIcons.MapPin {...props} />,
  MapPinLine: (props) => <PhosphorIcons.MapPinLine {...props} />,
  MapTrifold: (props) => <PhosphorIcons.MapTrifold {...props} />,
  MarkerCircle: (props) => <PhosphorIcons.MarkerCircle {...props} />,
  Martini: (props) => <PhosphorIcons.Martini {...props} />,
  MaskHappy: (props) => <PhosphorIcons.MaskHappy {...props} />,
  MaskSad: (props) => <PhosphorIcons.MaskSad {...props} />,
  MathOperations: (props) => <PhosphorIcons.MathOperations {...props} />,
  Medal: (props) => <PhosphorIcons.Medal {...props} />,
  MediumLogo: (props) => <PhosphorIcons.MediumLogo {...props} />,
  Megaphone: (props) => <PhosphorIcons.Megaphone {...props} />,
  MegaphoneSimple: (props) => <PhosphorIcons.MegaphoneSimple {...props} />,
  MessengerLogo: (props) => <PhosphorIcons.MessengerLogo {...props} />,
  Microphone: (props) => <PhosphorIcons.Microphone {...props} />,
  MicrophoneSlash: (props) => <PhosphorIcons.MicrophoneSlash {...props} />,
  MicrophoneStage: (props) => <PhosphorIcons.MicrophoneStage {...props} />,
  MicrosoftExcelLogo: (props) => (
    <PhosphorIcons.MicrosoftExcelLogo {...props} />
  ),
  MicrosoftPowerpointLogo: (props) => (
    <PhosphorIcons.MicrosoftPowerpointLogo {...props} />
  ),
  MicrosoftTeamsLogo: (props) => (
    <PhosphorIcons.MicrosoftTeamsLogo {...props} />
  ),
  MicrosoftWordLogo: (props) => <PhosphorIcons.MicrosoftWordLogo {...props} />,
  Minus: (props) => <PhosphorIcons.Minus {...props} />,
  MinusCircle: (props) => <PhosphorIcons.MinusCircle {...props} />,
  Money: (props) => <PhosphorIcons.Money {...props} />,
  Monitor: (props) => <PhosphorIcons.Monitor {...props} />,
  MonitorPlay: (props) => <PhosphorIcons.MonitorPlay {...props} />,
  Moon: (props) => <PhosphorIcons.Moon {...props} />,
  MoonStars: (props) => <PhosphorIcons.MoonStars {...props} />,
  Mountains: (props) => <PhosphorIcons.Mountains {...props} />,
  Mouse: (props) => <PhosphorIcons.Mouse {...props} />,
  MouseSimple: (props) => <PhosphorIcons.MouseSimple {...props} />,
  MusicNote: (props) => <PhosphorIcons.MusicNote {...props} />,
  MusicNoteSimple: (props) => <PhosphorIcons.MusicNoteSimple {...props} />,
  MusicNotes: (props) => <PhosphorIcons.MusicNotes {...props} />,
  MusicNotesPlus: (props) => <PhosphorIcons.MusicNotesPlus {...props} />,
  MusicNotesSimple: (props) => <PhosphorIcons.MusicNotesSimple {...props} />,
  NavigationArrow: (props) => <PhosphorIcons.NavigationArrow {...props} />,
  Needle: (props) => <PhosphorIcons.Needle {...props} />,
  Newspaper: (props) => <PhosphorIcons.Newspaper {...props} />,
  NewspaperClipping: (props) => <PhosphorIcons.NewspaperClipping {...props} />,
  Note: (props) => <PhosphorIcons.Note {...props} />,
  NoteBlank: (props) => <PhosphorIcons.NoteBlank {...props} />,
  NotePencil: (props) => <PhosphorIcons.NotePencil {...props} />,
  Notebook: (props) => <PhosphorIcons.Notebook {...props} />,
  Notepad: (props) => <PhosphorIcons.Notepad {...props} />,
  Notification: (props) => <PhosphorIcons.Notification {...props} />,
  NumberCircleEight: (props) => <PhosphorIcons.NumberCircleEight {...props} />,
  NumberCircleFive: (props) => <PhosphorIcons.NumberCircleFive {...props} />,
  NumberCircleFour: (props) => <PhosphorIcons.NumberCircleFour {...props} />,
  NumberCircleNine: (props) => <PhosphorIcons.NumberCircleNine {...props} />,
  NumberCircleOne: (props) => <PhosphorIcons.NumberCircleOne {...props} />,
  NumberCircleSeven: (props) => <PhosphorIcons.NumberCircleSeven {...props} />,
  NumberCircleSix: (props) => <PhosphorIcons.NumberCircleSix {...props} />,
  NumberCircleThree: (props) => <PhosphorIcons.NumberCircleThree {...props} />,
  NumberCircleTwo: (props) => <PhosphorIcons.NumberCircleTwo {...props} />,
  NumberCircleZero: (props) => <PhosphorIcons.NumberCircleZero {...props} />,
  NumberEight: (props) => <PhosphorIcons.NumberEight {...props} />,
  NumberFive: (props) => <PhosphorIcons.NumberFive {...props} />,
  NumberFour: (props) => <PhosphorIcons.NumberFour {...props} />,
  NumberNine: (props) => <PhosphorIcons.NumberNine {...props} />,
  NumberOne: (props) => <PhosphorIcons.NumberOne {...props} />,
  NumberSeven: (props) => <PhosphorIcons.NumberSeven {...props} />,
  NumberSix: (props) => <PhosphorIcons.NumberSix {...props} />,
  NumberSquareEight: (props) => <PhosphorIcons.NumberSquareEight {...props} />,
  NumberSquareFive: (props) => <PhosphorIcons.NumberSquareFive {...props} />,
  NumberSquareFour: (props) => <PhosphorIcons.NumberSquareFour {...props} />,
  NumberSquareNine: (props) => <PhosphorIcons.NumberSquareNine {...props} />,
  NumberSquareOne: (props) => <PhosphorIcons.NumberSquareOne {...props} />,
  NumberSquareSeven: (props) => <PhosphorIcons.NumberSquareSeven {...props} />,
  NumberSquareSix: (props) => <PhosphorIcons.NumberSquareSix {...props} />,
  NumberSquareThree: (props) => <PhosphorIcons.NumberSquareThree {...props} />,
  NumberSquareTwo: (props) => <PhosphorIcons.NumberSquareTwo {...props} />,
  NumberSquareZero: (props) => <PhosphorIcons.NumberSquareZero {...props} />,
  NumberThree: (props) => <PhosphorIcons.NumberThree {...props} />,
  NumberTwo: (props) => <PhosphorIcons.NumberTwo {...props} />,
  NumberZero: (props) => <PhosphorIcons.NumberZero {...props} />,
  Nut: (props) => <PhosphorIcons.Nut {...props} />,
  NyTimesLogo: (props) => <PhosphorIcons.NyTimesLogo {...props} />,
  Octagon: (props) => <PhosphorIcons.Octagon {...props} />,
  Option: (props) => <PhosphorIcons.Option {...props} />,
  Package: (props) => <PhosphorIcons.Package {...props} />,
  PaintBrush: (props) => <PhosphorIcons.PaintBrush {...props} />,
  PaintBrushBroad: (props) => <PhosphorIcons.PaintBrushBroad {...props} />,
  PaintBrushHousehold: (props) => (
    <PhosphorIcons.PaintBrushHousehold {...props} />
  ),
  PaintBucket: (props) => <PhosphorIcons.PaintBucket {...props} />,
  PaintRoller: (props) => <PhosphorIcons.PaintRoller {...props} />,
  Palette: (props) => <PhosphorIcons.Palette {...props} />,
  PaperPlane: (props) => <PhosphorIcons.PaperPlane {...props} />,
  PaperPlaneRight: (props) => <PhosphorIcons.PaperPlaneRight {...props} />,
  PaperPlaneTilt: (props) => <PhosphorIcons.PaperPlaneTilt {...props} />,
  Paperclip: (props) => <PhosphorIcons.Paperclip {...props} />,
  PaperclipHorizontal: (props) => (
    <PhosphorIcons.PaperclipHorizontal {...props} />
  ),
  Parachute: (props) => <PhosphorIcons.Parachute {...props} />,
  Password: (props) => <PhosphorIcons.Password {...props} />,
  Path: (props) => <PhosphorIcons.Path {...props} />,
  Pause: (props) => <PhosphorIcons.Pause {...props} />,
  PauseCircle: (props) => <PhosphorIcons.PauseCircle {...props} />,
  PawPrint: (props) => <PhosphorIcons.PawPrint {...props} />,
  Peace: (props) => <PhosphorIcons.Peace {...props} />,
  Pen: (props) => <PhosphorIcons.Pen {...props} />,
  PenNib: (props) => <PhosphorIcons.PenNib {...props} />,
  PenNibStraight: (props) => <PhosphorIcons.PenNibStraight {...props} />,
  Pencil: (props) => <PhosphorIcons.Pencil {...props} />,
  PencilCircle: (props) => <PhosphorIcons.PencilCircle {...props} />,
  PencilLine: (props) => <PhosphorIcons.PencilLine {...props} />,
  PencilSimple: (props) => <PhosphorIcons.PencilSimple {...props} />,
  PencilSimpleLine: (props) => <PhosphorIcons.PencilSimpleLine {...props} />,
  Percent: (props) => <PhosphorIcons.Percent {...props} />,
  Person: (props) => <PhosphorIcons.Person {...props} />,
  PersonSimple: (props) => <PhosphorIcons.PersonSimple {...props} />,
  PersonSimpleRun: (props) => <PhosphorIcons.PersonSimpleRun {...props} />,
  PersonSimpleWalk: (props) => <PhosphorIcons.PersonSimpleWalk {...props} />,
  Perspective: (props) => <PhosphorIcons.Perspective {...props} />,
  Phone: (props) => <PhosphorIcons.Phone {...props} />,
  PhoneCall: (props) => <PhosphorIcons.PhoneCall {...props} />,
  PhoneDisconnect: (props) => <PhosphorIcons.PhoneDisconnect {...props} />,
  PhoneIncoming: (props) => <PhosphorIcons.PhoneIncoming {...props} />,
  PhoneOutgoing: (props) => <PhosphorIcons.PhoneOutgoing {...props} />,
  PhoneSlash: (props) => <PhosphorIcons.PhoneSlash {...props} />,
  PhoneX: (props) => <PhosphorIcons.PhoneX {...props} />,
  PhosphorLogo: (props) => <PhosphorIcons.PhosphorLogo {...props} />,
  PianoKeys: (props) => <PhosphorIcons.PianoKeys {...props} />,
  PictureInPicture: (props) => <PhosphorIcons.PictureInPicture {...props} />,
  Pill: (props) => <PhosphorIcons.Pill {...props} />,
  PinterestLogo: (props) => <PhosphorIcons.PinterestLogo {...props} />,
  Pinwheel: (props) => <PhosphorIcons.Pinwheel {...props} />,
  Pizza: (props) => <PhosphorIcons.Pizza {...props} />,
  Placeholder: (props) => <PhosphorIcons.Placeholder {...props} />,
  Planet: (props) => <PhosphorIcons.Planet {...props} />,
  Play: (props) => <PhosphorIcons.Play {...props} />,
  PlayCircle: (props) => <PhosphorIcons.PlayCircle {...props} />,
  Playlist: (props) => <PhosphorIcons.Playlist {...props} />,
  Plug: (props) => <PhosphorIcons.Plug {...props} />,
  Plugs: (props) => <PhosphorIcons.Plugs {...props} />,
  PlugsConnected: (props) => <PhosphorIcons.PlugsConnected {...props} />,
  Plus: (props) => <PhosphorIcons.Plus {...props} />,
  PlusCircle: (props) => <PhosphorIcons.PlusCircle {...props} />,
  PlusMinus: (props) => <PhosphorIcons.PlusMinus {...props} />,
  PokerChip: (props) => <PhosphorIcons.PokerChip {...props} />,
  PoliceCar: (props) => <PhosphorIcons.PoliceCar {...props} />,
  Polygon: (props) => <PhosphorIcons.Polygon {...props} />,
  Popcorn: (props) => <PhosphorIcons.Popcorn {...props} />,
  Power: (props) => <PhosphorIcons.Power {...props} />,
  Prescription: (props) => <PhosphorIcons.Prescription {...props} />,
  Presentation: (props) => <PhosphorIcons.Presentation {...props} />,
  PresentationChart: (props) => <PhosphorIcons.PresentationChart {...props} />,
  Printer: (props) => <PhosphorIcons.Printer {...props} />,
  Prohibit: (props) => <PhosphorIcons.Prohibit {...props} />,
  ProhibitInset: (props) => <PhosphorIcons.ProhibitInset {...props} />,
  ProjectorScreen: (props) => <PhosphorIcons.ProjectorScreen {...props} />,
  ProjectorScreenChart: (props) => (
    <PhosphorIcons.ProjectorScreenChart {...props} />
  ),
  PushPin: (props) => <PhosphorIcons.PushPin {...props} />,
  PushPinSimple: (props) => <PhosphorIcons.PushPinSimple {...props} />,
  PushPinSimpleSlash: (props) => (
    <PhosphorIcons.PushPinSimpleSlash {...props} />
  ),
  PushPinSlash: (props) => <PhosphorIcons.PushPinSlash {...props} />,
  PuzzlePiece: (props) => <PhosphorIcons.PuzzlePiece {...props} />,
  QrCode: (props) => <PhosphorIcons.QrCode {...props} />,
  Question: (props) => <PhosphorIcons.Question {...props} />,
  Queue: (props) => <PhosphorIcons.Queue {...props} />,
  Quotes: (props) => <PhosphorIcons.Quotes {...props} />,
  Radical: (props) => <PhosphorIcons.Radical {...props} />,
  Radio: (props) => <PhosphorIcons.Radio {...props} />,
  RadioButton: (props) => <PhosphorIcons.RadioButton {...props} />,
  Rainbow: (props) => <PhosphorIcons.Rainbow {...props} />,
  RainbowCloud: (props) => <PhosphorIcons.RainbowCloud {...props} />,
  Receipt: (props) => <PhosphorIcons.Receipt {...props} />,
  Record: (props) => <PhosphorIcons.Record {...props} />,
  Rectangle: (props) => <PhosphorIcons.Rectangle {...props} />,
  Recycle: (props) => <PhosphorIcons.Recycle {...props} />,
  RedditLogo: (props) => <PhosphorIcons.RedditLogo {...props} />,
  Repeat: (props) => <PhosphorIcons.Repeat {...props} />,
  RepeatOnce: (props) => <PhosphorIcons.RepeatOnce {...props} />,
  Rewind: (props) => <PhosphorIcons.Rewind {...props} />,
  RewindCircle: (props) => <PhosphorIcons.RewindCircle {...props} />,
  Robot: (props) => <PhosphorIcons.Robot {...props} />,
  Rocket: (props) => <PhosphorIcons.Rocket {...props} />,
  RocketLaunch: (props) => <PhosphorIcons.RocketLaunch {...props} />,
  Rows: (props) => <PhosphorIcons.Rows {...props} />,
  Rss: (props) => <PhosphorIcons.Rss {...props} />,
  RssSimple: (props) => <PhosphorIcons.RssSimple {...props} />,
  Rug: (props) => <PhosphorIcons.Rug {...props} />,
  Ruler: (props) => <PhosphorIcons.Ruler {...props} />,
  Scales: (props) => <PhosphorIcons.Scales {...props} />,
  Scan: (props) => <PhosphorIcons.Scan {...props} />,
  Scissors: (props) => <PhosphorIcons.Scissors {...props} />,
  Screencast: (props) => <PhosphorIcons.Screencast {...props} />,
  ScribbleLoop: (props) => <PhosphorIcons.ScribbleLoop {...props} />,
  Scroll: (props) => <PhosphorIcons.Scroll {...props} />,
  Selection: (props) => <PhosphorIcons.Selection {...props} />,
  SelectionAll: (props) => <PhosphorIcons.SelectionAll {...props} />,
  SelectionBackground: (props) => (
    <PhosphorIcons.SelectionBackground {...props} />
  ),
  SelectionForeground: (props) => (
    <PhosphorIcons.SelectionForeground {...props} />
  ),
  SelectionInverse: (props) => <PhosphorIcons.SelectionInverse {...props} />,
  SelectionPlus: (props) => <PhosphorIcons.SelectionPlus {...props} />,
  SelectionSlash: (props) => <PhosphorIcons.SelectionSlash {...props} />,
  Share: (props) => <PhosphorIcons.Share {...props} />,
  ShareNetwork: (props) => <PhosphorIcons.ShareNetwork {...props} />,
  Shield: (props) => <PhosphorIcons.Shield {...props} />,
  ShieldCheck: (props) => <PhosphorIcons.ShieldCheck {...props} />,
  ShieldCheckered: (props) => <PhosphorIcons.ShieldCheckered {...props} />,
  ShieldChevron: (props) => <PhosphorIcons.ShieldChevron {...props} />,
  ShieldPlus: (props) => <PhosphorIcons.ShieldPlus {...props} />,
  ShieldSlash: (props) => <PhosphorIcons.ShieldSlash {...props} />,
  ShieldStar: (props) => <PhosphorIcons.ShieldStar {...props} />,
  ShieldWarning: (props) => <PhosphorIcons.ShieldWarning {...props} />,
  ShoppingBag: (props) => <PhosphorIcons.ShoppingBag {...props} />,
  ShoppingBagOpen: (props) => <PhosphorIcons.ShoppingBagOpen {...props} />,
  ShoppingCart: (props) => <PhosphorIcons.ShoppingCart {...props} />,
  ShoppingCartSimple: (props) => (
    <PhosphorIcons.ShoppingCartSimple {...props} />
  ),
  Shower: (props) => <PhosphorIcons.Shower {...props} />,
  Shuffle: (props) => <PhosphorIcons.Shuffle {...props} />,
  ShuffleAngular: (props) => <PhosphorIcons.ShuffleAngular {...props} />,
  ShuffleSimple: (props) => <PhosphorIcons.ShuffleSimple {...props} />,
  Sidebar: (props) => <PhosphorIcons.Sidebar {...props} />,
  SidebarSimple: (props) => <PhosphorIcons.SidebarSimple {...props} />,
  SignIn: (props) => <PhosphorIcons.SignIn {...props} />,
  SignOut: (props) => <PhosphorIcons.SignOut {...props} />,
  Signpost: (props) => <PhosphorIcons.Signpost {...props} />,
  SimCard: (props) => <PhosphorIcons.SimCard {...props} />,
  SketchLogo: (props) => <PhosphorIcons.SketchLogo {...props} />,
  SkipBack: (props) => <PhosphorIcons.SkipBack {...props} />,
  SkipBackCircle: (props) => <PhosphorIcons.SkipBackCircle {...props} />,
  SkipForward: (props) => <PhosphorIcons.SkipForward {...props} />,
  SkipForwardCircle: (props) => <PhosphorIcons.SkipForwardCircle {...props} />,
  Skull: (props) => <PhosphorIcons.Skull {...props} />,
  SlackLogo: (props) => <PhosphorIcons.SlackLogo {...props} />,
  Sliders: (props) => <PhosphorIcons.Sliders {...props} />,
  SlidersHorizontal: (props) => <PhosphorIcons.SlidersHorizontal {...props} />,
  Smiley: (props) => <PhosphorIcons.Smiley {...props} />,
  SmileyBlank: (props) => <PhosphorIcons.SmileyBlank {...props} />,
  SmileyMeh: (props) => <PhosphorIcons.SmileyMeh {...props} />,
  SmileyNervous: (props) => <PhosphorIcons.SmileyNervous {...props} />,
  SmileySad: (props) => <PhosphorIcons.SmileySad {...props} />,
  SmileySticker: (props) => <PhosphorIcons.SmileySticker {...props} />,
  SmileyWink: (props) => <PhosphorIcons.SmileyWink {...props} />,
  SmileyXEyes: (props) => <PhosphorIcons.SmileyXEyes {...props} />,
  SnapchatLogo: (props) => <PhosphorIcons.SnapchatLogo {...props} />,
  Snowflake: (props) => <PhosphorIcons.Snowflake {...props} />,
  SoccerBall: (props) => <PhosphorIcons.SoccerBall {...props} />,
  SortAscending: (props) => <PhosphorIcons.SortAscending {...props} />,
  SortDescending: (props) => <PhosphorIcons.SortDescending {...props} />,
  Spade: (props) => <PhosphorIcons.Spade {...props} />,
  Sparkle: (props) => <PhosphorIcons.Sparkle {...props} />,
  SpeakerHigh: (props) => <PhosphorIcons.SpeakerHigh {...props} />,
  SpeakerLow: (props) => <PhosphorIcons.SpeakerLow {...props} />,
  SpeakerNone: (props) => <PhosphorIcons.SpeakerNone {...props} />,
  SpeakerSimpleHigh: (props) => <PhosphorIcons.SpeakerSimpleHigh {...props} />,
  SpeakerSimpleLow: (props) => <PhosphorIcons.SpeakerSimpleLow {...props} />,
  SpeakerSimpleNone: (props) => <PhosphorIcons.SpeakerSimpleNone {...props} />,
  SpeakerSimpleSlash: (props) => (
    <PhosphorIcons.SpeakerSimpleSlash {...props} />
  ),
  SpeakerSimpleX: (props) => <PhosphorIcons.SpeakerSimpleX {...props} />,
  SpeakerSlash: (props) => <PhosphorIcons.SpeakerSlash {...props} />,
  SpeakerX: (props) => <PhosphorIcons.SpeakerX {...props} />,
  Spinner: (props) => <PhosphorIcons.Spinner {...props} />,
  SpinnerGap: (props) => <PhosphorIcons.SpinnerGap {...props} />,
  Spiral: (props) => <PhosphorIcons.Spiral {...props} />,
  SpotifyLogo: (props) => <PhosphorIcons.SpotifyLogo {...props} />,
  Square: (props) => <PhosphorIcons.Square {...props} />,
  SquareHalf: (props) => <PhosphorIcons.SquareHalf {...props} />,
  SquareHalfBottom: (props) => <PhosphorIcons.SquareHalfBottom {...props} />,
  SquareLogo: (props) => <PhosphorIcons.SquareLogo {...props} />,
  SquaresFour: (props) => <PhosphorIcons.SquaresFour {...props} />,
  Stack: (props) => <PhosphorIcons.Stack {...props} />,
  StackOverflowLogo: (props) => <PhosphorIcons.StackOverflowLogo {...props} />,
  StackSimple: (props) => <PhosphorIcons.StackSimple {...props} />,
  Stamp: (props) => <PhosphorIcons.Stamp {...props} />,
  Star: (props) => <PhosphorIcons.Star {...props} />,
  StarFour: (props) => <PhosphorIcons.StarFour {...props} />,
  StarHalf: (props) => <PhosphorIcons.StarHalf {...props} />,
  Sticker: (props) => <PhosphorIcons.Sticker {...props} />,
  Stop: (props) => <PhosphorIcons.Stop {...props} />,
  StopCircle: (props) => <PhosphorIcons.StopCircle {...props} />,
  Storefront: (props) => <PhosphorIcons.Storefront {...props} />,
  Strategy: (props) => <PhosphorIcons.Strategy {...props} />,
  StripeLogo: (props) => <PhosphorIcons.StripeLogo {...props} />,
  Student: (props) => <PhosphorIcons.Student {...props} />,
  Suitcase: (props) => <PhosphorIcons.Suitcase {...props} />,
  SuitcaseSimple: (props) => <PhosphorIcons.SuitcaseSimple {...props} />,
  Sun: (props) => <PhosphorIcons.Sun {...props} />,
  SunDim: (props) => <PhosphorIcons.SunDim {...props} />,
  SunHorizon: (props) => <PhosphorIcons.SunHorizon {...props} />,
  Sunglasses: (props) => <PhosphorIcons.Sunglasses {...props} />,
  Swap: (props) => <PhosphorIcons.Swap {...props} />,
  Swatches: (props) => <PhosphorIcons.Swatches {...props} />,
  Sword: (props) => <PhosphorIcons.Sword {...props} />,
  Syringe: (props) => <PhosphorIcons.Syringe {...props} />,
  TShirt: (props) => <PhosphorIcons.TShirt {...props} />,
  Table: (props) => <PhosphorIcons.Table {...props} />,
  Tabs: (props) => <PhosphorIcons.Tabs {...props} />,
  Tag: (props) => <PhosphorIcons.Tag {...props} />,
  TagChevron: (props) => <PhosphorIcons.TagChevron {...props} />,
  TagSimple: (props) => <PhosphorIcons.TagSimple {...props} />,
  Target: (props) => <PhosphorIcons.Target {...props} />,
  Taxi: (props) => <PhosphorIcons.Taxi {...props} />,
  TelegramLogo: (props) => <PhosphorIcons.TelegramLogo {...props} />,
  Television: (props) => <PhosphorIcons.Television {...props} />,
  TelevisionSimple: (props) => <PhosphorIcons.TelevisionSimple {...props} />,
  TennisBall: (props) => <PhosphorIcons.TennisBall {...props} />,
  Terminal: (props) => <PhosphorIcons.Terminal {...props} />,
  TerminalWindow: (props) => <PhosphorIcons.TerminalWindow {...props} />,
  TestTube: (props) => <PhosphorIcons.TestTube {...props} />,
  TextAa: (props) => <PhosphorIcons.TextAa {...props} />,
  TextAlignCenter: (props) => <PhosphorIcons.TextAlignCenter {...props} />,
  TextAlignJustify: (props) => <PhosphorIcons.TextAlignJustify {...props} />,
  TextAlignLeft: (props) => <PhosphorIcons.TextAlignLeft {...props} />,
  TextAlignRight: (props) => <PhosphorIcons.TextAlignRight {...props} />,
  TextBolder: (props) => <PhosphorIcons.TextBolder {...props} />,
  TextH: (props) => <PhosphorIcons.TextH {...props} />,
  TextHFive: (props) => <PhosphorIcons.TextHFive {...props} />,
  TextHFour: (props) => <PhosphorIcons.TextHFour {...props} />,
  TextHOne: (props) => <PhosphorIcons.TextHOne {...props} />,
  TextHSix: (props) => <PhosphorIcons.TextHSix {...props} />,
  TextHThree: (props) => <PhosphorIcons.TextHThree {...props} />,
  TextHTwo: (props) => <PhosphorIcons.TextHTwo {...props} />,
  TextIndent: (props) => <PhosphorIcons.TextIndent {...props} />,
  TextItalic: (props) => <PhosphorIcons.TextItalic {...props} />,
  TextOutdent: (props) => <PhosphorIcons.TextOutdent {...props} />,
  TextStrikethrough: (props) => <PhosphorIcons.TextStrikethrough {...props} />,
  TextT: (props) => <PhosphorIcons.TextT {...props} />,
  TextUnderline: (props) => <PhosphorIcons.TextUnderline {...props} />,
  Textbox: (props) => <PhosphorIcons.Textbox {...props} />,
  Thermometer: (props) => <PhosphorIcons.Thermometer {...props} />,
  ThermometerCold: (props) => <PhosphorIcons.ThermometerCold {...props} />,
  ThermometerHot: (props) => <PhosphorIcons.ThermometerHot {...props} />,
  ThermometerSimple: (props) => <PhosphorIcons.ThermometerSimple {...props} />,
  ThumbsDown: (props) => <PhosphorIcons.ThumbsDown {...props} />,
  ThumbsUp: (props) => <PhosphorIcons.ThumbsUp {...props} />,
  Ticket: (props) => <PhosphorIcons.Ticket {...props} />,
  TiktokLogo: (props) => <PhosphorIcons.TiktokLogo {...props} />,
  Timer: (props) => <PhosphorIcons.Timer {...props} />,
  ToggleLeft: (props) => <PhosphorIcons.ToggleLeft {...props} />,
  ToggleRight: (props) => <PhosphorIcons.ToggleRight {...props} />,
  Toilet: (props) => <PhosphorIcons.Toilet {...props} />,
  ToiletPaper: (props) => <PhosphorIcons.ToiletPaper {...props} />,
  Tote: (props) => <PhosphorIcons.Tote {...props} />,
  ToteSimple: (props) => <PhosphorIcons.ToteSimple {...props} />,
  TrademarkRegistered: (props) => (
    <PhosphorIcons.TrademarkRegistered {...props} />
  ),
  TrafficCone: (props) => <PhosphorIcons.TrafficCone {...props} />,
  TrafficSign: (props) => <PhosphorIcons.TrafficSign {...props} />,
  TrafficSignal: (props) => <PhosphorIcons.TrafficSignal {...props} />,
  Train: (props) => <PhosphorIcons.Train {...props} />,
  TrainRegional: (props) => <PhosphorIcons.TrainRegional {...props} />,
  TrainSimple: (props) => <PhosphorIcons.TrainSimple {...props} />,
  Translate: (props) => <PhosphorIcons.Translate {...props} />,
  Trash: (props) => <PhosphorIcons.Trash {...props} />,
  TrashSimple: (props) => <PhosphorIcons.TrashSimple {...props} />,
  Tray: (props) => <PhosphorIcons.Tray {...props} />,
  Tree: (props) => <PhosphorIcons.Tree {...props} />,
  TreeEvergreen: (props) => <PhosphorIcons.TreeEvergreen {...props} />,
  TreeStructure: (props) => <PhosphorIcons.TreeStructure {...props} />,
  TrendDown: (props) => <PhosphorIcons.TrendDown {...props} />,
  TrendUp: (props) => <PhosphorIcons.TrendUp {...props} />,
  Triangle: (props) => <PhosphorIcons.Triangle {...props} />,
  Trophy: (props) => <PhosphorIcons.Trophy {...props} />,
  Truck: (props) => <PhosphorIcons.Truck {...props} />,
  TwitchLogo: (props) => <PhosphorIcons.TwitchLogo {...props} />,
  TwitterLogo: (props) => <PhosphorIcons.TwitterLogo {...props} />,
  Umbrella: (props) => <PhosphorIcons.Umbrella {...props} />,
  UmbrellaSimple: (props) => <PhosphorIcons.UmbrellaSimple {...props} />,
  Upload: (props) => <PhosphorIcons.Upload {...props} />,
  UploadSimple: (props) => <PhosphorIcons.UploadSimple {...props} />,
  User: (props) => <PhosphorIcons.User {...props} />,
  UserCircle: (props) => <PhosphorIcons.UserCircle {...props} />,
  UserCircleGear: (props) => <PhosphorIcons.UserCircleGear {...props} />,
  UserCircleMinus: (props) => <PhosphorIcons.UserCircleMinus {...props} />,
  UserCirclePlus: (props) => <PhosphorIcons.UserCirclePlus {...props} />,
  UserFocus: (props) => <PhosphorIcons.UserFocus {...props} />,
  UserGear: (props) => <PhosphorIcons.UserGear {...props} />,
  UserList: (props) => <PhosphorIcons.UserList {...props} />,
  UserMinus: (props) => <PhosphorIcons.UserMinus {...props} />,
  UserPlus: (props) => <PhosphorIcons.UserPlus {...props} />,
  UserRectangle: (props) => <PhosphorIcons.UserRectangle {...props} />,
  UserSquare: (props) => <PhosphorIcons.UserSquare {...props} />,
  UserSwitch: (props) => <PhosphorIcons.UserSwitch {...props} />,
  Users: (props) => <PhosphorIcons.Users {...props} />,
  UsersFour: (props) => <PhosphorIcons.UsersFour {...props} />,
  UsersThree: (props) => <PhosphorIcons.UsersThree {...props} />,
  Vault: (props) => <PhosphorIcons.Vault {...props} />,
  Vibrate: (props) => <PhosphorIcons.Vibrate {...props} />,
  VideoCamera: (props) => <PhosphorIcons.VideoCamera {...props} />,
  VideoCameraSlash: (props) => <PhosphorIcons.VideoCameraSlash {...props} />,
  Vignette: (props) => <PhosphorIcons.Vignette {...props} />,
  Voicemail: (props) => <PhosphorIcons.Voicemail {...props} />,
  Volleyball: (props) => <PhosphorIcons.Volleyball {...props} />,
  Wall: (props) => <PhosphorIcons.Wall {...props} />,
  Wallet: (props) => <PhosphorIcons.Wallet {...props} />,
  Warning: (props) => <PhosphorIcons.Warning {...props} />,
  WarningCircle: (props) => <PhosphorIcons.WarningCircle {...props} />,
  WarningOctagon: (props) => <PhosphorIcons.WarningOctagon {...props} />,
  Watch: (props) => <PhosphorIcons.Watch {...props} />,
  WaveSawtooth: (props) => <PhosphorIcons.WaveSawtooth {...props} />,
  WaveSine: (props) => <PhosphorIcons.WaveSine {...props} />,
  WaveSquare: (props) => <PhosphorIcons.WaveSquare {...props} />,
  WaveTriangle: (props) => <PhosphorIcons.WaveTriangle {...props} />,
  Waves: (props) => <PhosphorIcons.Waves {...props} />,
  Webcam: (props) => <PhosphorIcons.Webcam {...props} />,
  WhatsappLogo: (props) => <PhosphorIcons.WhatsappLogo {...props} />,
  Wheelchair: (props) => <PhosphorIcons.Wheelchair {...props} />,
  WifiHigh: (props) => <PhosphorIcons.WifiHigh {...props} />,
  WifiLow: (props) => <PhosphorIcons.WifiLow {...props} />,
  WifiMedium: (props) => <PhosphorIcons.WifiMedium {...props} />,
  WifiNone: (props) => <PhosphorIcons.WifiNone {...props} />,
  WifiSlash: (props) => <PhosphorIcons.WifiSlash {...props} />,
  WifiX: (props) => <PhosphorIcons.WifiX {...props} />,
  Wind: (props) => <PhosphorIcons.Wind {...props} />,
  WindowsLogo: (props) => <PhosphorIcons.WindowsLogo {...props} />,
  Wine: (props) => <PhosphorIcons.Wine {...props} />,
  Wrench: (props) => <PhosphorIcons.Wrench {...props} />,
  X: (props) => <PhosphorIcons.X {...props} />,
  XCircle: (props) => <PhosphorIcons.XCircle {...props} />,
  XSquare: (props) => <PhosphorIcons.XSquare {...props} />,
  YinYang: (props) => <PhosphorIcons.YinYang {...props} />,
  YoutubeLogo: (props) => <PhosphorIcons.YoutubeLogo {...props} />,
};
