import DropdownFixedNavbar from 'components/Custom/DropdownFixedNavbar';
import FooterBlack from 'components/Custom/FooterBlack';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const TermsOfUse = () => (
  <>
    <DropdownFixedNavbar />
    <div className="wrapper">
      <div className="section-space" />
      <div className="section">
        <div className="main">
          <div className="condition-content">
            <Container>
              <Row>
                <Col>
                  <h2 className="title">
                    OKUMA PLATFORMU BİREYSEL Kullanım Şartları ve Koşulları
                  </h2>
                  <h3>
                    <h7> 10/03/2023 </h7>
                    Edict Digital - Okuma Platformu Bireysel KULLANIM KOŞULLARI
                  </h3>

                  <ul>
                    <p>
                      Bu lisans koşulları, siz ve EDICT DIGITAL (veya bağlı
                      kuruluşlarından biri) arasında yapılan anlaşmayı
                      oluşturur. Bu lisans koşulları, yukarıda adı geçen yazılım
                      hizmetlerine uygulanır. BU LİSANS KOŞULLARINA UYDUĞUNUZ
                      TAKDİRDE AŞAĞIDAKİ HAKLARA SAHİP OLURSUNUZ. YAZILIMI
                      KULLANMAKLA, BU KOŞULLARI KABUL EDERSİNİZ. BU KOŞULLARI
                      KABUL ETMİYORSANIZ, YAZILIMI KULLANMAYINIZ. BUNUN YERİNE,
                      GEÇERLİ OLMASI DURUMUNDA, YAZILIMI ÜCRET İADESİ VEYA
                      YERİNE BAŞKA BİR ÜRÜN ALMAK İÇİN APPLE, INC. (“APPLE”)
                      ŞİRKETİNE İADE EDİNİZ.
                    </p>
                    <p>
                      Bu uygulamayı indirdiğiniz ve giriş yaptığınızda bu
                      sitenin tüm kullanım kural ve koşullarını, uygulanan tüm
                      kanun ve yönetmelikleri, var olan yerel hüküm ve kanunları
                      kabul ediyorsunuz. Bu web sitesindeki tüm materyaller
                      ilgili ve uygulanan telif ve yayın hakları tarafından
                      korunmaktadır.
                    </p>

                    <p>
                      <b>1. Kullanım Lisansı</b>
                    </p>

                    <p>
                      YÜKLEME VE KULLANIM HAKLARI: Yazılımın bir kopyasını,
                      Apple'ın uygulama mağazası kullanım kuralları uyarınca
                      izin verilen şekilde, iOS tabanlı cihaza yükleyebilir ve
                      kullanabilirsiniz.
                    </p>

                    <p>
                      Abone’nin erişim için kullanmış olduğu Apple ID hesabı
                      Abone’ye özeldir ve Abone bu hesap ile tek Abonelik
                      oluşturma hakkına sahiptir; aynı hesap ile iki farklı
                      Abonelik oluşturulamaz. Abone, Uygulama’yı sadece kişisel
                      amaçla ve Okuma Platformu Bireysel verdiği yetki ve
                      sınırlar ile ancak Okuma Platformu Bireysel yayınlama,
                      görsel ve işitsel olarak üçüncü kişilerin erişimine sunma
                      tekniğine ve amacına uygun olarak kullanma hakkına
                      sahiptir. Abone, Uygulama’ya Aboneliğini ve işbu
                      Şartname’den kaynaklanan hak ve yükümlülüklerini devir ve
                      temlik edemez.
                    </p>

                    <p>
                      EDICT DIGITAL Bilgi Teknolojileri AŞ Okuma Platformu
                      uygulamasında olan materyalleri (bilgi ve yazılım)
                      kişisel, ticari amaç gütmeyen görüntüleme için birer kopya
                      olarak kısa süreliğine bilgisayarınıza indirebilirsiniz.
                      Bu kullanım lisansı olup size sahiplik vermez ve bu
                      lisansla aşağıdaki işlemleri yapamazsınız:
                    </p>

                    <p> • Materyalleri değiştirme ve kopyalama;</p>
                    <p>
                      • Materyalleri ticari amaçla kullanma, kamu alanlarında
                      sergileme (ticari ya da ticari olmayan amaçlarla);
                    </p>
                    <p>
                      • EDICT DIGITAL Bilgi Teknolojileri AŞ’nin sayfasında
                      bulunan yazılımları alt parçalara ayırma ve ters
                      süreçlerle çözümleme;
                    </p>
                    <p>
                      • Materyallerden telif hakkı ya da sahip, özlük haklarını
                      ibarelerini çıkarma, değiştirme;
                    </p>
                    <p>
                      • Materyalleri başka bir kişiye transfer etme ya da sunucu
                      da yayınlama.
                    </p>
                    <br></br>
                    <p>
                      <b>Feragat </b>
                    </p>

                    <p>
                      EDICT DIGITAL Bilgi Teknolojileri AŞ sayfasındaki
                      materyaller oldukları gibi sunulmuştur. EDICT DIGITAL
                      Bilgi Teknolojileri AŞ hiçbir açık ya da imalı bir garanti
                      vermeyerek materyallerin sınırsız kullanımından, ticari
                      olarak ve belli bir amaca yönelik kullanımından, düşünce
                      ürünü hakları ve diğer telif hakları taleplerinden feragat
                      etmez. Buna ek olarak EDICT DIGITAL Bilgi Teknolojileri AŞ
                      sitesinde ve sitesiyle bağlantılı olan diğer sitelerde
                      bulunan materyallerin doğruluğu, güvenilirliği, sonuçları
                      ve kullanımları hakkında garanti vermez, böyle talepleri
                      temsil etmez.
                    </p>
                    <br></br>
                    <p>
                      <b>Sınırlamalar</b>
                    </p>

                    <p>
                      Hiçbir koşulda EDICT DIGITAL Bilgi Teknolojileri AŞ ve
                      tedarikçileri web sitesinin kullanımından doğan veri kaybı
                      ve diğer zararlardan sorumlu değildir. EDICT DIGITAL Bilgi
                      Teknolojileri AŞ web sitesinin yanlış kullanımından doğan
                      tüm zararlardan da sorumlu değildir.
                    </p>
                    <br></br>
                    <p>
                      <b>Revizyonlar ve Hatalar</b>
                    </p>

                    <p>
                      EDICT DIGITAL Bilgi Teknolojileri AŞ’nin sayfasında
                      bulunan materyaller teknik, tipografik ve görsel hatalar
                      içerebilir. EDICT DIGITAL Bilgi Teknolojileri AŞ web
                      sitesinde bulunan materyallerin doğruluğu, tamlığı ve
                      güncelliği konusunda garanti vermez. EDICT DIGITAL Bilgi
                      Teknolojileri AŞ web sitesinde bulunan materyalleri
                      herhangi bir bilgi verip, ilan vermeden her zaman
                      değiştirebilir. EDICT DIGITAL Bilgi Teknolojileri AŞ var
                      olan materyalleri güncelleme konusunda bir taahhütte
                      bulunmaz.
                    </p>
                    <br></br>
                    <p>
                      <b>Bağlantılar – Alt Siteler</b>
                    </p>

                    <p>
                      EDICT DIGITAL Bilgi Teknolojileri AŞ web sitesiyle
                      bağlantılı olan diğer siteler, bağlantılar ve alt
                      sayfaların içeriklerinden sorumlu değildir. Bu
                      bağlantıların bulunması EDICT DIGITAL Bilgi Teknolojileri
                      AŞ’nin bunları desteklediği anlamına gelmez. Bu
                      bağlantıları ve siteleri kullanım riski kullanıcıya
                      aittir.
                    </p>
                    <br></br>
                    <p>
                      <b>Sitenin Kullanım Değişiklikleri Koşulları</b>
                    </p>

                    <p>
                      EDICT DIGITAL Bilgi Teknolojileri AŞ web sitesinin
                      kullanım koşullarını haber vermeksizin değiştirebilir. Bu
                      siteyi kullanarak halihazırda geçerli olan en son Kullanım
                      Kural ve Koşullarını kabul etmiş oluyorsunuz.
                    </p>
                    <p>
                      Ödemeye ilişkin her türlü bilgi, Apple Inc. ile aralarında
                      olan sözleşme uyarınca ilgili firmanın sorumluluğu
                      altındadır.
                    </p>
                    <p>
                      Okuma Platformu Bireysel kullanıcılarının uygulama
                      üzerinde gerçekleştirdiği eylemlere dair bilgilere ancak
                      anonim olarak ulaşabilir. Bunlar; kullanım ayarları,
                      sıklığı ve tercih edilen içerikleri gibi uygulamanın
                      geliştirilmesine ve performans ölçümlerine yönelik
                      istatistikleri kapsar. Bu bilgiler tamamen anonimdir ve
                      kullanıcıları gerçek kişiler olarak doğrudan veya dolaylı
                      yoldan tanımlamaya yönelik olarak kullanılamaz.
                    </p>

                    <p>
                      Okuma Platformu Bireysel, işbu Şartname ve Gizlilik
                      Politikası çerçevesinde Abone’ye ait her türlü kullanım
                      bilgisi ve istatistikleri resmi makamlarla paylaşabilir,
                      kendisi kayıt altına alabilir, kontrol edebilir,
                      yedekleyebilir, saklayabilir ve sair şekillerde
                      işleyebilir.
                    </p>
                    <br></br>
                    <p>
                      <b>İlgili Kanun</b>
                    </p>

                    <p>
                      EDICT DIGITAL Bilgi Teknolojileri AŞ’nin uygulama ile
                      ilgili tüm şikâyet ve talepler Türkiye Devleti’nin
                      kanunlarına göre işlem görür.
                    </p>

                    <br></br>
                    <h4>İletişim</h4>
                    <p>
                      Haklarınıza yönelik başvurularınızı info@edictdigital.com
                      adresine iletebilirsiniz. Talebinizin niteliğine göre en
                      kısa sürede ve en geç otuz gün içinde ücretsiz olarak
                      başvurularınız sonuçlandırılacaktır.
                    </p>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </div>
  </>
);

export default TermsOfUse;
