import { PAGES } from 'redux/actions/pages';

// feature name
export const READ_BOOK = `${PAGES} [ReadBook]`;

// action types
export const START_READ_BOOK_PAGE = `${READ_BOOK} START`;
export const SET_USER_BOOKMARK = `${READ_BOOK} SET_USER_BOOKMARK`;
export const REMOVE_USER_BOOKMARK = `${READ_BOOK} REMOVE_USER_BOOKMARK`;
export const SAVE_USER_SOUND_URL = `${READ_BOOK} SAVE_SOUND`;
export const SET_BOOK_LAST_PAGE = `${READ_BOOK} SET_BOOK_LAST_PAGE`;
export const CLOSE_CURRENT_BOOK = `${READ_BOOK} CLOSE_CURRENT_BOOK`;

// action creators
export const startReadBookPage = ({ bookId }) => ({
  type: START_READ_BOOK_PAGE,
  payload: { bookId },
});

export const setUserBookmark = ({ id, bookId, pageId, bookmarkText }) => ({
  type: SET_USER_BOOKMARK,
  payload: { id, bookId, pageId, bookmarkText },
});

export const removeUserBookmark = ({ id }) => ({
  type: REMOVE_USER_BOOKMARK,
  payload: { id },
});

export const saveUserSoundUrl = ({ bookId, audioUrl }) => ({
  type: SAVE_USER_SOUND_URL,
  payload: { bookId, audioUrl },
});

export const setBookLastPage = ({ bookId, pageLocation, isListening }) => ({
  type: SET_BOOK_LAST_PAGE,
  payload: { bookId, pageLocation, isListening },
});

export const closeCurrentBook = ({
  bookId,
  pageLocation,
  listenPageLocation,
}) => ({
  type: CLOSE_CURRENT_BOOK,
  payload: {
    bookId,
    pageLocation,
    listenPageLocation,
  },
});
