import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const BUY_NOW_PAGE = `${PAGES} [BuyNow]`;

// actiona types
export const SEND_BUY_NOW_FORM = `${BUY_NOW_PAGE} SEND_BUY_NOW_FORM`;

// action creators
export const sendBuyNowForm = ({
  name,
  school,
  city,
  email,
  phone,
  message,
}) => ({
  type: SEND_BUY_NOW_FORM,
  payload: { name, school, city, email, phone, message },
});
