import React from 'react';
import { connect } from 'react-redux';
import { Play, Pause } from 'phosphor-react';

const QuestionAgainContent = ({
  quiz,
  localizations,
  currentState,
  userAnswers,
  isPlaying,
  currentAudioId,
  onPlayClick,
  onPauseClick,
  choiceSelectAction,
  returnToReportAction,
}) => {
  return (
    <>
      <div className="quiz-modal-title">Tekrar Dene</div>

      <div className="quiz-modal-tabs">
        {quiz?.questions
          ?.sort((a, b) => a.questionOrder - b.questionOrder)
          ?.map((question) => {
            const tabItemClassNames = ['quiz-modal-tab-item', 'not-clickable'];

            if (userAnswers[question.id]?.userAnswerId > 0) {
              if (
                userAnswers[question.id]?.userAnswerId ===
                userAnswers[question.id]?.correctAnswerId
              ) {
                tabItemClassNames.push('correct');
              } else {
                tabItemClassNames.push('wrong');
              }
            }

            return (
              <div key={question.id} className={tabItemClassNames.join(' ')}>
                <p>{question.questionOrder}</p>
              </div>
            );
          })}
      </div>

      {currentState.question !== null ? (
        <div className="quiz-modal-question-wrapper">
          <div className="quiz-modal-question-container">
            {currentState.question.audioUrl &&
            currentState.question.audioUrl !== '' ? (
              <>
                {!isPlaying ||
                currentAudioId !== `Question-${currentState.question.id}` ? (
                  <div
                    className="quiz-modal-play-btn"
                    aria-hidden
                    onClick={(event) => {
                      event.preventDefault();
                      onPlayClick(
                        `Question-${currentState.question.id}`,
                        currentState.question.audioUrl
                      );
                    }}
                  >
                    <Play color="#67BCEC" size={30} weight="duotone" />
                  </div>
                ) : (
                  <div
                    className="quiz-modal-play-btn"
                    aria-hidden
                    onClick={() =>
                      onPauseClick(`Question-${currentState.question?.id}`)
                    }
                  >
                    <Pause color="#67BCEC" size={30} weight="duotone" />
                  </div>
                )}
              </>
            ) : null}
            <div className="quiz-modal-text-container">
              <div className="quiz-modal-question-title">
                <p>{`${localizations?.QUESTION ?? 'Soru'} ${
                  currentState.question.questionOrder
                }`}</p>
              </div>
              <p className="quiz-modal-question-text">
                {currentState.question.text}
              </p>
            </div>
            {currentState.question.imageUrl &&
            currentState.question.imageUrl !== '' ? (
              <div className="quiz-modal-question-image">
                <img
                  src={currentState.question.imageUrl}
                  alt={currentState.question.text}
                />
              </div>
            ) : null}
          </div>
          {currentState.question.choices &&
          currentState.question.choices.length > 0 ? (
            <div className="quiz-modal-question-choices">
              {currentState.question.choices
                .sort((a, b) => a.choiceOrder - b.choiceOrder)
                .map((ch, i) => {
                  const itemClassNames = ['quiz-modal-question-choice-item'];

                  if (
                    userAnswers &&
                    userAnswers[currentState.question.id]?.userAnswerId ===
                      ch.id
                  ) {
                    itemClassNames.push('marked-choice');
                  }

                  if (
                    (!ch.text || ch.text === '') &&
                    ch.imageUrl &&
                    ch.imageUrl !== ''
                  ) {
                    itemClassNames.push('only-image');
                  }

                  return (
                    <div
                      key={ch.id}
                      className={itemClassNames.join(' ')}
                      onClick={() => choiceSelectAction(ch.id)}
                    >
                      <div className="quiz-modal-choice-item-char">
                        {['A', 'B', 'C', 'D'][i]}
                      </div>
                      {ch.audioUrl && ch.audioUrl !== '' ? (
                        <>
                          {!isPlaying ||
                          currentAudioId !== `Choice-${ch.id}` ? (
                            <div
                              className="quiz-modal-play-btn"
                              aria-hidden
                              onClick={(event) => {
                                event.preventDefault();
                                onPlayClick(`Choice-${ch.id}`, ch.audioUrl);
                              }}
                            >
                              <Play
                                color="#67BCEC"
                                size={30}
                                weight="duotone"
                              />
                            </div>
                          ) : (
                            <div
                              className="quiz-modal-play-btn"
                              aria-hidden
                              onClick={() => onPauseClick(`Choice-${ch.id}`)}
                            >
                              <Pause
                                color="#67BCEC"
                                size={30}
                                weight="duotone"
                              />
                            </div>
                          )}
                        </>
                      ) : null}
                      {ch.text && ch.text !== '' ? (
                        <div className="quiz-modal-choice-item-text">
                          {ch.text}
                        </div>
                      ) : null}
                      {ch.imageUrl && ch.imageUrl !== '' ? (
                        <div className="quiz-modal-choice-item-image">
                          <img
                            src={ch.imageUrl}
                            alt={['A', 'B', 'C', 'D'][i]}
                          />
                        </div>
                      ) : null}
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="quiz-modal-close">
        <div className="close-btn" aria-hidden onClick={returnToReportAction}>
          Değerlendirme Sonucuna Dön
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quiz: state.data.quiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionAgainContent);
