import {
  setNotification,
  removeNotification,
  SET_NOTIFICATION,
} from 'redux/actions/notifications';

const NOTIFICATION_SECONDS = 6;

export const notificationsMiddleware = () => (next) => (action) => {
  if (action.type.includes(SET_NOTIFICATION)) {
    const { payload, meta } = action;
    const { type, message } = payload;

    const id = new Date().getMilliseconds();
    const notification = { id, message };

    next(
      setNotification({ type, message: notification, feature: meta.feature })
    );

    setTimeout(() => {
      next(removeNotification({ notificationId: id, feature: meta.feature }));
    }, NOTIFICATION_SECONDS * 1000);
  } else {
    next(action);
  }
};
