import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const VCLOUD_FEATURES = `${PAGES} [Vcloud]`;

// action types
export const VALIDATE_VCLOUD_TOKEN = `${VCLOUD_FEATURES} VALIDATE VCLOUD TOKEN`;

// action creators
export const validateVcloudToken = ({ token, clientid }) => ({
  type: VALIDATE_VCLOUD_TOKEN,
  payload: { token, clientid },
});
