export const bookLevelColorMapper = {
  "LEVEL_1": { main: "#fedd00", },
  "LEVEL_1+": { main: "#fedd00", },
  "LEVEL_2": { main: "#f59bbb", },
  "LEVEL_2+": { main: "#f59bbb", },
  "LEVEL_3": { main: "#ff8200", },
  "LEVEL_3+": { main: "#ff8200", },
  "LEVEL_4": { main: "#97d700", },
  "LEVEL_4+": { main: "#97d700", },
  "LEVEL_5": { main: "#7ba7bc", },
  "LEVEL_5+": { main: "#7ba7bc", },
  "LEVEL_6": { main: "#009ca6", },
  "LEVEL_7": { main: "#9678d3", },
  "LEVEL_8": { main: "#005eb8", },
  "LEVEL_9": { main: "#e10098", },
  "LEVEL_10": { main: "#ef3340", },
};