/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { XCircle, Play, Binoculars } from 'phosphor-react';
import useOutsideAction from 'hooks/use-outside-action';
import { useRef } from 'react';
import { connect } from 'react-redux';

const Dictionary = ({ dictionary, localizations }) => {
  const [collapses, setCollapses] = useState([]);
  const imageModalRef = useRef(null);
  const imageCaptionRef = useRef(null);

  const iframeModalRef = useRef(null);
  const videoCaptionRef = useRef(null);

  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };

  const onImageThumbClick = (word, meaning, source) => {
    const imageModal = document.getElementById('image-modal');
    const imageModalImg = document.getElementById('image-modal-img');
    const imageModalCaption = document.getElementById('image-modal-caption');
    imageModal.style.display = 'block';
    imageModalImg.src = source;
    imageModalImg.alt = meaning;
    imageModalCaption.innerHTML = `${word}`;
  };

  const onVideoThumbClick = (word, source) => {
    const videoModal = document.getElementById('video-modal');
    const videoModalIframe = document.getElementById('video-modal-iframe');
    const videoModalCaption = document.getElementById('video-modal-caption');
    videoModal.style.display = 'block';
    videoModalIframe.src = source;
    videoModalIframe.title = word;
    videoModalCaption.innerHTML = `${word}`;
  };

  const onCloseImageModalClicked = () => {
    const imageModal = document.getElementById('image-modal');
    imageModal.style.display = 'none';
  };

  const onCloseVideoModalClicked = () => {
    const videoModal = document.getElementById('video-modal');
    const videoModalIframe = document.getElementById('video-modal-iframe');
    videoModal.style.display = 'none';
    videoModalIframe.src = ''; // For stopping video without error while closing
  };

  useOutsideAction(onCloseImageModalClicked, imageModalRef, imageCaptionRef);
  useOutsideAction(onCloseVideoModalClicked, iframeModalRef, videoCaptionRef);

  return (
    <>
      <div id="image-modal" className="modal-container">
        <span>
          <XCircle size={40} weight="duotone" />
        </span>
        <img id="image-modal-img" ref={imageModalRef} alt="" />
        <div
          id="image-modal-caption"
          ref={imageCaptionRef}
          className="caption"
        />
      </div>
      <div id="video-modal" className="modal-container">
        <span>
          <XCircle size={40} weight="duotone" />
        </span>
        <iframe
          id="video-modal-iframe"
          ref={iframeModalRef}
          title="some"
          src=""
          width="640"
          height="360"
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
        ></iframe>
        <div
          id="video-modal-caption"
          ref={videoCaptionRef}
          className="caption"
        />
      </div>
      <div className="dictionary-container">
        <div className="dictionary-header">
          <Binoculars
            className="icon-size20"
            weight="duotone"
            // color="#555555"
            color="#BE5FB5"
          />
          <span>{localizations?.DICTIONARY}</span>
        </div>
        <div className="word-list">
          {dictionary && dictionary.length > 0
            ? dictionary
                .sort((a, b) => (a.seq > b.seq ? 1 : b.seq > a.seq ? -1 : 0))
                .map((w) => {
                  return (
                    <div key={w.id} className="word">
                      <a
                        aria-expanded={collapses.includes(w.id)}
                        data-toggle="collapse"
                        style={{
                          color: collapses.includes(w.id)
                            ? '#444444'
                            : '#888888',
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          changeCollapse(w.id);
                        }}
                      >
                        <span>{w.word}</span>
                      </a>
                      <Collapse isOpen={collapses.includes(w.id)}>
                        <div className="meaning-list">
                          {w.meanings && w.meanings.length > 0
                            ? w.meanings
                                .sort((a, b) =>
                                  a.isPageMeaning
                                    ? -1
                                    : b.isPageMeaning
                                    ? 1
                                    : a.seq > b.seq
                                    ? 1
                                    : b.seq > a.seq
                                    ? -1
                                    : 0
                                )
                                .map((m, i) => {
                                  return (
                                    <div key={m.id} className="meaning-item">
                                      <div className="meaning">
                                        <span>
                                          {w.meanings.length > 1
                                            ? `${i + 1}. ${m.meaning}`
                                            : `${m.meaning}`}
                                        </span>
                                      </div>
                                      <div className="sample-sentence">
                                        <span>{m.sampleSentence}</span>
                                      </div>
                                      {(m.imageUrl && m.imageUrl !== '') ||
                                      (m.videoUrl && m.videoUrl !== '') ? (
                                        <div className="visual-content">
                                          {m.imageUrl && m.imageUrl !== '' ? (
                                            <div className="meaning-image">
                                              <img
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  onImageThumbClick(
                                                    w.word,
                                                    m.meaning,
                                                    m.imageUrl
                                                  );
                                                }}
                                                src={m.imageUrl}
                                                alt={m.meaning}
                                              />
                                            </div>
                                          ) : null}
                                          {m.videoUrl && m.videoUrl !== '' ? (
                                            <div
                                              className="meaning-video"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                onVideoThumbClick(
                                                  w.word,
                                                  m.videoUrl
                                                );
                                              }}
                                            >
                                              <img
                                                alt={m.meaning}
                                                src={`https://vumbnail.com/${m.videoUrl
                                                  .split('/')
                                                  .pop()}.jpg`}
                                              />
                                              <div className="play-icon-container">
                                                <Play
                                                  className="icon-size30"
                                                  weight="duotone"
                                                  color="#FFFFFFCC"
                                                />
                                              </div>
                                            </div>
                                          ) : null}
                                        </div>
                                      ) : null}
                                    </div>
                                  );
                                })
                            : null}
                        </div>
                      </Collapse>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { pageIds } = ownProps; // id array
  const dictionaryArray = pageIds.map((id) => {
    const pageDict = state.data.book?.pages?.find(
      (page) => page.id === id
    )?.dictionary;
    return pageDict ?? [];
  });

  return {
    dictionary: [].concat(...dictionaryArray),
    localizations: state.localization?.pairs,
  };
};

export default connect(mapStateToProps, {})(Dictionary);
