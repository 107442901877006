import { PAGES } from 'redux/actions/pages';

// feature name
export const NAVBAR = `${PAGES} [Navbar]`;

// action types
export const SIGNOUT = `${NAVBAR} SIGNOUT`;
export const CHANGE_LANGUAGE = `${NAVBAR} CHANGE_LANGUAGE`;

// action creators
export const signout = ({ email }) => ({
  type: SIGNOUT,
  payload: { email },
});

export const changeLanguage = ({ language }) => ({
  type: CHANGE_LANGUAGE,
  payload: { language },
});
