import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container, Col, Row, Alert, Button } from 'reactstrap';
// import FooterBlack from 'components/Custom/FooterBlack';
import { connect } from 'react-redux';

import DropdownFixedBlurNavbar from 'components/Custom/DropdownFixedBlurNavbar';
import RoundedCard from './home-components/RoundedCard';
import FooterBlack from 'components/Custom/FooterBlack';
import playstoreImage from 'assets/img/playstore-badge.png';
import appstoreImage from 'assets/img/appstore-badge.png';
// import comingSoonImage from 'assets/img/cok-yakinda.png';

const Home = ({ localizations, currentUser }) => {
  const history = useHistory();

  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    const key = 'PrivacyAccepted';
    const item = localStorage.getItem(key);

    if (item && item !== '') {
      const expiry = parseInt(item);
      const now = new Date();

      if (now.getTime() > expiry) {
        localStorage.removeItem(key);
        if (!showConsent) {
          setShowConsent(true);
        }
      } else {
        setShowConsent(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser?.id > 0 && currentUser?.userType === 'STUDENT') {
      history.push('/student');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const consentAcceptClicked = (e) => {
    e.preventDefault();
    const now = new Date();
    const ttl = 1000 * 60 * 60 * 24;
    const expiry = now.getTime() + ttl;
    localStorage.setItem('PrivacyAccepted', expiry.toString());
    setShowConsent(false);
  };
  return (
    <>
      <div
        className="consent"
        style={{
          position: 'fixed',
          bottom: 0,
          zIndex: 2010,
          width: '100%',
        }}
      >
        <Alert isOpen={showConsent} style={{ backgroundColor: '#F4F3ED' }}>
          <div
            className="consent-container"
            style={{
              padding: '10px 10% 10px 10%',
            }}
          >
            <div
              className="consent-content"
              style={{ fontSize: '16px', color: '#555555' }}
            >
              <div
                className="consent-title"
                style={{
                  fontWeight: 600,
                  marginBottom: '10px',
                  letterSpacing: '0.5px',
                }}
              >
                {localizations?.HOME_CONSENT_TITLE}
              </div>
              <div className="consent-text" style={{ marginBottom: '10px' }}>
                {localizations?.HOME_CONSENT_TEXT}
              </div>
              <div
                className="consent-footer"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  className="consent-link"
                  style={{ display: 'block', marginRight: '20px' }}
                >
                  {localizations?.HOME_CONSENT_FOOTER_PART1 + ' '}
                  <a href="#d">{localizations?.HOME_CONSENT_FOOTER_PART2}</a>
                  {' ' + localizations?.HOME_CONSENT_FOOTER_PART3}
                </div>
                <div className="consent-accept">
                  <Button
                    outline
                    className="btn-round"
                    type="button"
                    onClick={consentAcceptClicked}
                    style={{
                      backgroundColor: 'white',
                      color: '#555555',
                      borderColor: '#555555',
                    }}
                  >
                    {localizations?.HOME_CONSENT_ACCEPT_BTN}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Alert>
      </div>
      {/* <DefaultNavbar white={true} /> */}
      <DropdownFixedBlurNavbar />
      <div className="wrapper" style={{ backgroundColor: '#F5F5F7' }}>
        <div className="section-intro">
          <div className="badges-container">
            <div className="badges">
              <p className="badges-text">Okuma Platformu Okul</p>
              <div className="badges-images">
                <a
                  href="https://apps.apple.com/tr/app/okuma-platformu-okul/id6444416015"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appstoreImage} alt="Appstore Imaj" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.edictdigital.okumaplatformu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playstoreImage} alt="Playstore Imaj" />
                </a>
              </div>
            </div>
            <div className="badges-vertical-line"></div>
            <div className="badges">
              <p className="badges-text">Okuma Platformu Bireysel</p>
              <div className="badges-images">
                <a
                  href="https://apps.apple.com/tr/app/okuma-platformu-bireysel/id1662102559"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appstoreImage} alt="Appstore Imaj" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.edictdigital.okumaplatformustudent"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playstoreImage} alt="Playstore Imaj" />
                </a>
              </div>
            </div>
          </div>

          <div className="intro-image-container">
            <div className="intro-pc">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/pc2.png"
                alt="pc görünümü"
              />
            </div>
            <div className="intro-iphone">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/iphone.png"
                alt="iphone görünümü"
              />
            </div>
            <div className="intro-ipad">
              <img
                src="https://ik.imagekit.io/mvnw0kw1mga/web/ipad.png"
                alt="ipad görünümü"
              />
            </div>
          </div>
        </div>

        <div className="section-intro-text">
          <Container>
            <Row>
              <Col sm={12} lg={{ span: 10 }}>
                <Row>
                  <Col>
                    <h1 className="intro-text-small-header">Okuma Platformu</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h2 className="intro-text-title">
                      Tüm cihazlarda
                      <br />
                      7/24 Erişilebilir
                      <br />
                      Çevrim içi
                      <br />
                      Okuma Platformu
                    </h2>
                  </Col>
                </Row>
                <Row>
                  <Col lg={3} />
                  <Col sm={12} lg={6}>
                    <div>
                      <p className="intro-text-description">
                        Kerem, Bezgin ve Çengel'in maceraları seni bekliyor!
                        200+ hikâyeden oluşan dijital, sesli kitaplarla okumanın
                        en eğlenceli hali.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#027FFF',
                    }}
                  >
                    Okuma Ağacı
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <RoundedCard
                  title="200+ Kitap"
                  titleColor="#027FFF"
                  subtitle="Okuma Ağacı okul öncesi ve ilkokul öğrencilerine yönelik 13 seviyeye ayrılmış,
200+ hikâyeyi içeren okuma serisidir."
                  subtitleColor="rgb(29, 29, 31)"
                  backgroundColor="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      paddingTop: '70px',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'start',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/oka.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={6}>
                <RoundedCard
                  title="Okuma Ağacı"
                  titleColor="#FFFFFF"
                  subtitle=""
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left, #1ABCFA, #1C7DF3)"
                >
                  <div className="home-rounded-card-text">
                    <p>
                      Yaşamın içinden farklı beceri seviyelerine uygun güçlü
                      görsel anlatım.
                    </p>
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>

        <div>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: 'rgb(205,58,13)',
                    }}
                  >
                    Öğrenci
                  </h2>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <RoundedCard
                  title="Çocuklar İçin Eğlenceli Tasarım"
                  titleColor="#FFFFFF"
                  subtitle=""
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left, rgb(247, 111,8), rgb(158, 9, 15))"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div className="home-rounded-card-image">
                    <img
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ipad_pro_11_home.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={8}>
                <RoundedCard
                  title="Kullanıcı Dostu Arayüz"
                  titleColor="rgb(205,58,13)"
                  subtitle="Dinleme, ses kaydetme, not ekleme, sözlük ve yakınlaştırma sayesinde eşsiz bir okuma deneyimi."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '80%',
                        maxWidth: '80%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ipad_read.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="Sözlük"
                  titleColor="rgb(205,58,13)"
                  subtitle=""
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/sozluk.png"
                      alt="imaj"
                    />
                    <ul
                      style={{
                        listStyle: 'none',
                        fontSize: '19px',
                        lineHeight: 1.421,
                        fontWeight: 500,
                        letterSpacing: '0.012em',
                        margin: '0.3em 0 0.3em 0',
                        color: 'rgb(29, 29, 31)',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                      }}
                    >
                      <li style={{ marginBottom: '10px' }}>
                        Sayfa'dan kelimeler
                      </li>
                      <li style={{ marginBottom: '10px' }}>
                        Atasözleri ve deyimler
                      </li>
                      <li style={{ marginBottom: '10px' }}>
                        Birden fazla anlam
                      </li>
                      <li style={{ marginBottom: '10px' }}>Örnek cümle</li>
                      <li style={{ marginBottom: '10px' }}>
                        Resimli veya videolu anlatım
                      </li>
                    </ul>
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col>
                <RoundedCard
                  title="Sesli Kitaplar"
                  titleColor="white"
                  subtitle="Dinleme esnasında kelime ve cümle takibi"
                  subtitleColor="white"
                  backgroundImage="https://ik.imagekit.io/mvnw0kw1mga/web/listen.png"
                  backgroundSize="cover"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  ></div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Ses Kayıt"
                  titleColor="rgb(205,58,13)"
                  subtitle={`Okurken sesini kaydet.\nÖğretmenine gönder, sevdiklerinle paylaş, cihazına indir.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '80%',
                        maxWidth: '80%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/rec.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Değerlendirme"
                  titleColor="white"
                  subtitle="Değerlendirmeler ile okuma anlama, söz varlığı ve tür analizi gelişimini kontrol et."
                  subtitleColor="white"
                  backgroundImage="https://ik.imagekit.io/mvnw0kw1mga/web/quiz.png"
                  backgroundSize="cover"
                  backgroundPosition="50% 0%"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  ></div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col>
                <RoundedCard
                  title="Görev"
                  titleColor="rgb(205,58,13)"
                  subtitle="Öğretmenin verdiği okuma, dinleme, oku - kaydet, değerlendirme ve materyal görevlerini teslim tarihinden önce tamamla."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/gorev.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="Mesaj"
                  titleColor="rgb(205,58,13)"
                  subtitle="Öğretmenden gelen yazılı veya sesli mesajları görüntüle."
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '85%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/message.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={8}>
                <RoundedCard
                  title="Rapor"
                  titleColor="#FFFFFF"
                  subtitle="Gelişimini takip et."
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left,#3DD9BD,#049CB7)"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/rapor.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>

        <div style={{ marginBottom: '50px' }}>
          <Container>
            <Row>
              <Col>
                <div style={{ padding: '120px 0 10px 0' }}>
                  <h2
                    style={{
                      margin: 0,
                      marginBottom: '-11px',
                      fontSize: '56px',
                      lineHeight: 1.0714,
                      fontWeight: 600,
                      letterSpacing: '-0.005em',
                      color: '#8876FE',
                    }}
                  >
                    Öğretmen
                  </h2>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <RoundedCard
                  title="Kolay Kullanım"
                  titleColor="#FFFFFF"
                  subtitle="Kullanıcı bazlı yetkilendirme ile her öğretmenin yetkisi kadar menü görüntüleyebilmesi."
                  subtitleColor="#FFFFFF"
                  background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ogretmen_header.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Öğrenim"
                  titleColor="#8876FE"
                  subtitle={`MEB kazanımları ile ilişkilendirilmiş kitaplar.\nÖğretmen ve öğrenciler için hazırlanmış öğrenim materyalleri.\nKitaplara özel ders planları.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/ogrenim.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Materyal"
                  titleColor="#8876FE"
                  subtitle={`Yüzlerce öğrenim materyali.\nDerste kullan veya ev ödevi olarak görev ata.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/document.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Görevler"
                  titleColor="#8876FE"
                  subtitle={`Okuma, dinleme, oku-kaydet, değerlendirme ve materyal içeren görevler oluştur.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/newassignment.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} md={6}>
                <RoundedCard
                  title="Görev Takip"
                  titleColor="#8876FE"
                  subtitle={`Görevlerin tamamlanma durumlarını takip et. Öğrenciden gelen okuma kayıtlarını dinle.`}
                  subtitleColor="rgb(29, 29, 31)"
                  background="white"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/gorev_durumu.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8}>
                <RoundedCard
                  title="Sınıf Raporu"
                  titleColor="white"
                  subtitle="Sınıfın genel durumunu ve gelişimini takip et."
                  subtitleColor="white"
                  background="linear-gradient(to bottom right,#0D1387,#867AF6)"
                  // background="linear-gradient(to bottom right, rgb(228, 196, 159), rgb(252, 235, 214))"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '90%',
                        maxWidth: '90%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/sinif_raporu.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
              <Col xs={12} lg={4}>
                <RoundedCard
                  title="Sınıf Liste"
                  titleColor="white"
                  subtitle="Sınıfın genel durumunu ve gelişimini takip et."
                  subtitleColor="white"
                  background="linear-gradient(to top left, rgb(247, 111,8), rgb(158, 9, 15))"
                  // TODO: güzel oldu
                  // background="linear-gradient(to top left, rgb(225,45,18), #1C7DF3)"
                >
                  <div
                    style={{
                      flexGrow: 1,
                      overflow: 'auto',
                      overflowX: 'hidden',
                      // paddingTop: '70px',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'cover',
                        textAlign: 'center',
                        display: 'block',
                      }}
                      src="https://ik.imagekit.io/mvnw0kw1mga/web/sinif_raporu_listesi.png"
                      alt="imaj"
                    />
                  </div>
                </RoundedCard>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBlack />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth?.currentUser,
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(Home);
