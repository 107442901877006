import { PAGES } from 'redux/actions/pages';

// feature name
export const DO_PRESIGNED_URL = `${PAGES} [DOPresignedUrl]`;

// action types
export const GET_AUDIO_UPLOAD_PARAMS = `${DO_PRESIGNED_URL} GET_AUDIO_UPLOAD_PARAMS`;
export const REMOVE_AUDIO_UPLOAD_PARAMS = `${DO_PRESIGNED_URL} REMOVE_AUDIO_UPLOAD_PARAMS`;

// action creators
export const getAudioUploadParams = ({ type, extention, folder }) => ({
  type: GET_AUDIO_UPLOAD_PARAMS,
  payload: { type, extention, folder },
});

export const removeAudioUploadParams = () => ({
  type: REMOVE_AUDIO_UPLOAD_PARAMS,
});
