import React from 'react';

const CloseWarningContent = ({ closeAction, continueAction }) => {
  return (
    <>
      <div className="quiz-modal-report-text">
        Değerlendirmeyi bitirmeden çıkmak istediğine emin misin?
      </div>
      <div className="quiz-modal-close">
        <div className="close-btn red" aria-hidden onClick={closeAction}>
          Evet, Kaydetmeden Çık
        </div>
        <div className="close-btn" aria-hidden onClick={continueAction}>
          Hayır, Devam Et
        </div>
      </div>
    </>
  );
};

export default CloseWarningContent;
