import React from 'react';
import { connect } from 'react-redux';

const QuizReportContent = ({
  quiz,
  localizations,
  userAnswers,
  tabItemClickAction,
  finishQuizAction,
}) => {
  const buildMessage = () => {
    const result = Object.values(userAnswers).reduce(
      (previousValue, currentAnswer) => {
        if (currentAnswer.userAnswerId === null) {
          return {
            ...previousValue,
            blank: previousValue.blank + 1,
          };
        }

        if (currentAnswer.userAnswerId === currentAnswer.correctAnswerId) {
          return {
            ...previousValue,
            correct: previousValue.correct + 1,
          };
        }

        return {
          ...previousValue,
          incorrect: previousValue.incorrect + 1,
        };
      },
      {
        blank: 0,
        incorrect: 0,
        correct: 0,
      }
    );

    const { blank, incorrect } = result;

    if (blank === 0 && incorrect === 0) {
      return "Harika gidiyorsun. Değerlendirme'yi başarıyla tamamladın.";
    }

    if (blank === 0) {
      return 'Kırmızı renkle gösterilen yanlış soruları tekrar cevaplayabilirsin.';
    }

    if (incorrect === 0) {
      return 'Gri renkle gösterilen boş bıraktığın soruları tekrar cevaplayabilirsin.';
    }

    // Hem boş hem de incorrect var!
    return 'Kırmızı renkle gösterilen yanlış soruları ve gri renkle gösterilen boş bıraktığın soruları tekrar cevaplayabilirsin.';
  };

  return (
    <>
      <div className="quiz-modal-title">Değerlendirme Sonucu</div>

      <div className="quiz-modal-tabs">
        {quiz?.questions
          ?.sort((a, b) => a.questionOrder - b.questionOrder)
          ?.map((question) => {
            const tabItemClassNames = ['quiz-modal-tab-item'];
            if (userAnswers[question.id]?.userAnswerId > 0) {
              if (
                userAnswers[question.id]?.userAnswerId ===
                userAnswers[question.id]?.correctAnswerId
              ) {
                tabItemClassNames.push('correct');
              } else {
                tabItemClassNames.push('wrong');
              }
            }

            return (
              <div
                key={question.id}
                className={tabItemClassNames.join(' ')}
                aria-hidden
                onClick={() => tabItemClickAction(question.id)}
              >
                <p>{question.questionOrder}</p>
              </div>
            );
          })}
      </div>

      <div className="quiz-modal-report-text">{buildMessage()}</div>

      <div className="quiz-modal-close">
        <div className="close-btn" aria-hidden onClick={finishQuizAction}>
          Değerlendirmeyi Bitir
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  quiz: state.data.quiz,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(QuizReportContent);
