import { PAGES } from 'redux/actions/pages';

// feature name
export const STUDENT_HOME = `${PAGES} [StudentHome]`;

// action types
export const START_STUDENT_HOME_PAGE = `${STUDENT_HOME} START_STUDENT_HOME_PAGE`;
export const CHECK_INCOMPLETE_ASSIGNMENT = `${STUDENT_HOME} CHECK_INCOMPLETE_ASSIGNMENT`;
export const GET_STUDENT_TOTAL_POINT = `${STUDENT_HOME} GET_TOTAL_POINT`;

// action creators
export const startStudentHomePage = () => ({
  type: START_STUDENT_HOME_PAGE,
});

export const checkIncompleteAssignment = () => ({
  type: CHECK_INCOMPLETE_ASSIGNMENT,
});

export const getStudentTotalPoint = () => ({
  type: GET_STUDENT_TOTAL_POINT,
});
