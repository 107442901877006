import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const QUIZ_MODAL = `${PAGES} QUIZ_MODAL`;

// action types
export const GET_QUIZ_DETAIL = `${QUIZ_MODAL} GET_QUIZ_DETAIL`;
export const SEND_QUIZ_RESULT = `${QUIZ_MODAL} SEND_QUIZ_RESULT`;

// action creators
export const getQuizDetail = ({ quizId }) => ({
  type: GET_QUIZ_DETAIL,
  payload: { quizId },
});

export const sendQuizResult = ({
  quizId,
  correctCount,
  wrongCount,
  blankCount,
  bookId,
  isPrimary,
}) => ({
  type: SEND_QUIZ_RESULT,
  payload: { quizId, correctCount, wrongCount, blankCount, bookId, isPrimary },
});
