import React from 'react';
import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from 'reactstrap';

function HomepageHeader({ localizations }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);

  const items = [
    {
      src: 'url(' + require('assets/img/carousel-1.jpg').default + ')',
      content: (
        <Row>
          <Col className="ml-auto mr-auto" md="8">
            <h1 className="title">{localizations?.CAROUSEL_SLIDE1_HEADER}</h1>
            <ul className="text-white slider-desc">
              <li>{localizations?.CAROUSEL_SLIDE1_DESC1}</li>
              <li>{localizations?.CAROUSEL_SLIDE1_DESC2}</li>
              <li>{localizations?.CAROUSEL_SLIDE1_DESC3}</li>
              <li>{localizations?.CAROUSEL_SLIDE1_DESC4}</li>
              <li>{localizations?.CAROUSEL_SLIDE1_DESC5}</li>
            </ul>
          </Col>
        </Row>
      ),
      altText: '',
      caption: '',
    },
    // {
    //   src: 'url(' + require('assets/img/carousel-2.jpg').default + ')',
    //   content: (
    //     <Row>
    //       <Col className="ml-auto mr-auto text-center" md="8">
    //         <h1 className="title">Sınırsız Erişim</h1>
    //         <h4 className="description text-white">
    //           Web tabanlı okuma platformu ile kitaplara 7/24 erişim.
    //         </h4>
    //       </Col>
    //     </Row>
    //   ),
    //   altText: '',
    //   caption: '',
    // },
    // {
    //   src: 'url(' + require('assets/img/carousel-3.jpg').default + ')',
    //   content: (
    //     <Row>
    //       <Col className="ml-auto mr-auto" md="8">
    //         <h1 className="title">Okumayı Destekleme</h1>
    //         <h4 className="description text-white">
    //           Sesli okuma, ses kaydetme, okuma ve yazma araçları ile okuma
    //           deneyimi.
    //         </h4>
    //       </Col>
    //     </Row>
    //   ),
    //   altText: '',
    //   caption: '',
    // },
  ];

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        {items.length > 1 ? (
          <CarouselIndicators
            items={items}
            activeIndex={activeIndex}
            onClickHandler={goToIndex}
          />
        ) : null}
        {items.map((item) => {
          return (
            <CarouselItem
              onExiting={onExiting}
              onExited={onExited}
              key={item.src}
            >
              <div className="page-header header-filter">
                <div
                  className="page-header-image"
                  style={{
                    backgroundImage: item.src,
                  }}
                ></div>
                <div className="content-center text-center">{item.content}</div>
              </div>
            </CarouselItem>
          );
        })}
        {items.length > 1 ? (
          <>
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-right" />
              <span className="sr-only">Next</span>
            </a>
          </>
        ) : null}
      </Carousel>
    </>
  );
}

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(HomepageHeader);
