import DropdownFixedNavbar from 'components/Custom/DropdownFixedNavbar';
import FooterBlack from 'components/Custom/FooterBlack';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Kvkk = () => (
  <>
    <DropdownFixedNavbar />
    <div className="wrapper">
      <div className="section-space" />
      <div className="section">
        <div className="main">
          <div className="condition-content">
            <Container>
              <Row>
                <Col>
                  <h2 className="title">KVKK AYDINLATMA METNİ</h2>
                  <h3>
                    Edict Digital 6698 Sayılı Kişisel Verilerin Korunması Kanunu
                    Kapsamındaki Aydınlatma Metni
                  </h3>
                  <ul>
                    <li>
                      <h4>1. Veri Sorumlusunun Kimliği</h4>
                      <p>
                        Bu aydınlatma metni, 6698 sayılı Kişisel Verilerin
                        Korunması Kanununun 10. maddesi ile Aydınlatma
                        Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve
                        Esaslar Hakkında Tebliğ (Kanun) kapsamında veri
                        sorumlusu sıfatıyla Edict Digital Bilgi Teknolojileri
                        A.Ş. tarafından hazırlanmıştır.
                      </p>
                    </li>
                    <li>
                      <h4>2. Kişisel Verilerinizin İşlenme Amacı</h4>
                      <p>
                        Edict Digital Bilgi Teknolojileri A.Ş., sözlü, yazılı ya
                        da elektronik ortamda edinilmiş kişisel verileri 6698
                        Sayılı Kanunu’nun 5. ve 6. maddelerinde belirtilen
                        kişisel veri işleme şartları ve amaçları kapsamında işbu
                        Aydınlatma Metninde belirtilen amaçlarla işleyebilmekte
                        ve aktarılabilmektedir.
                      </p>
                      <p>
                        Toplanan kişisel veriler, Kanun tarafından öngörülen
                        temel ilkelere uygun olarak, aşağıda belirtilen amaçlar
                        doğrultusunda işlenebilir ve taraflara aktarılabilir.
                      </p>
                      <p>
                        Sunulan hizmeti sağlamak ve geliştirmek, kullanıcı
                        deneyimlerini kişiselleştirmek, kullanıcı ile iletişim
                        kurmak, aldığınız hizmeti anlamak ve geliştirmek, Edict
                        Digital ortaklık düzenlemelerine katılımınızı sağlamak.
                      </p>
                    </li>
                    <li>
                      <h4>
                        3. Kişisel Verilerinizin Toplanma Yöntemi ve Hukuki
                        Sebebi
                      </h4>
                      <p>
                        Kişisel verileriniz Edict Digital faaliyetlerini
                        yürütmek amacıyla farklı kanallarla hukuki sebeplerine
                        dayanılarak toplanmaktadır. Edict Digital'in veri
                        sahibinden aldığı kişisel veriler aşağıda
                        listelenmiştir.
                      </p>
                      <ol>
                        <li>
                          a. İletişim ve profil bilgileri: Adınız, e-posta
                          adresiniz, mesleğiniz ve doğum tarihiniz dahil
                          kullanıcı bilgileri, kaydolduktan sonra profilinizde
                          takma adınız, konumunuz (şehir) ve diğer kişisel veya
                          demografik bilgiler gibi ek bilgiler.
                        </li>
                        <li>
                          b. Üçüncü taraf entegre hizmetlerden hesap ve kimlik
                          doğrulama bilgileri: Facebook, Google ve benzeri tek
                          oturum açma (single sign on) hizmeti gibi üçüncü taraf
                          bir sosyal ağ veya entegre hizmet aracılığıyla
                          kaydolmaya veya başka bir şekilde erişim izni vermeye
                          karar verirseniz, Edict Digital'de “Entegre Hizmet”
                          hesabınızla ilişkilendirilmiş kişisel bilgileri
                          edinir.
                        </li>
                        <li>
                          c. Konum bilgisi: Bölgeniz için size özel eğitim
                          deneyimleri sunmak amacıyla konumunuz (ülkeniz, iliniz
                          gibi) hakkında bilgi toplayabilir ve kullanabilir veya
                          IP adresinize göre yaklaşık konumunuzu çıkarabiliriz,
                          ancak tam olarak coğrafi konumunuz alınmaz. Herhangi
                          bir konum bilgisi verilmesini önlemek için
                          bilgisayarınızdaki veya mobil cihazınızdaki ayarları
                          değiştirebilirsiniz.
                        </li>
                        <li>
                          d. Hizmetimizi kullanımınızla ilgili bilgiler:
                          Hizmetimizi kullanımınızla ilgili görüntülediğiniz
                          sayfalar, görüntülediğiniz video sayısı ve bir sayfada
                          harcanan süre gibi kullanım bilgilerini
                          toplayabiliriz. Bu, sizin için en uygun eğitim
                          deneyimlerini daha iyi uyarlamamızı sağlar.
                        </li>
                        <li>
                          e. Kullanım ve cihaz bilgileri: Kullanıcılarımıza
                          kişiselleştirilmiş ve yüksek kaliteli deneyim sağlamak
                          için, web sitemizi ziyaret ettiğinizde,
                          e-postalarımızı okuduğumuzda, hizmetimizi
                          kullandığımızda veya başka bir şekilde bizimle
                          etkileşime girdiğinde tarayıcınızdan veya cihazınızdan
                          belirli teknik bilgileri otomatik olarak kaydeden
                          çeşitli teknolojiler kullanabiliriz. Bu bilgiler
                          genellikle çerezler, web işaretçileri, Flash
                          nesneleri, günlük dosyaları ve benzer teknolojiler
                          (topluca “izleme teknolojileri”) dahil olmak üzere
                          çeşitli izleme teknolojileri aracılığıyla toplanır.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>
                        4. Kişisel Verilerinizin Aktarıldığı Taraflar ve Aktarım
                        Amaçları
                      </h4>
                      <p>
                        Toplanan kişisel verileriniz, Kanun tarafından öngörülen
                        temel ilkelere uygun olarak ve Kanun’un 8. ve 9.
                        maddelerinde belirtilen kişisel veri işleme şartları ve
                        amaçları dahilinde, Edict Digital tarafından aşağıda yer
                        alan amaçlar doğrultusunda iş ortaklarımıza,
                        tedarikçilerimize, kanunen yetkili kamu kurumları ve
                        kanunen yetkili özel kurumlara aktarılabilmektedir:
                      </p>
                      <ol>
                        <li>
                          1. İş süreçlerimizin mevzuata uygun yürütülmesi ile
                          hukuk işlerinin takibi ve yürütülmesi amacıyla
                          avukatlar, denetçiler ve vergi danışmanlarına,
                        </li>
                        <li>
                          2. Yetkili kurum ve kuruluşlara bilgi verilmesi
                          amacıyla düzenleyici ve denetleyici kurumlar, mahkeme
                          ve icra müdürlükleri gibi sair resmî kurumlara,
                        </li>
                        <li>
                          3. Taleplerinizin yerine getirilmesi amacıyla
                          tarafınızca yetki verilmiş olan vekil ve
                          temsilcilerinize,
                        </li>
                        <li>
                          4. Yayınların hedeflenen kitlelere ulaşmasını sağlamak
                          amacıyla sosyal medya platformlarına,
                        </li>
                        <li>
                          5. Faaliyetlerimizin devamlılığı ve organizasyon
                          gerekliliklerini sağlamak amacıyla hizmet aldığımız
                          üçüncü kişi şirketlere ve iş ortaklarımıza
                          aktarılabilmektedir.
                        </li>
                      </ol>
                    </li>
                    <li>
                      <h4>
                        5. Kişisel Veri Sahibi Olarak Kanun'un 11. Maddesinde
                        Sayılan Hakları
                      </h4>
                      <p>
                        Kişisel veri sahibi olarak Kanun’un 11. maddesi uyarınca
                        aşağıdaki haklara sahipsiniz:
                      </p>
                      <ol>
                        <li>
                          1. Kişisel verilerinizin işlenip işlenmediğini
                          öğrenme,
                        </li>
                        <li>
                          2. Kişisel verileriniz işlenmişse buna ilişkin bilgi
                          talep etme,
                        </li>
                        <li>
                          3. Kişisel verilerinizin işlenme amacını ve bunların
                          amacına uygun kullanılıp kullanılmadığını öğrenme,
                        </li>
                        <li>
                          4. Yurt içinde veya yurt dışında kişisel verilerinizin
                          aktarıldığı üçüncü̈ kişileri bilme,
                        </li>
                        <li>
                          5. Kişisel verilerinizin eksik veya yanlış işlenmiş
                          olması hâlinde bunların düzeltilmesini isteme ve bu
                          kapsamda yapılan işlemin kişisel verilerinizin
                          aktarıldığı üçüncü̈ kişilere bildirilmesini isteme,
                        </li>
                        <li>
                          6. Kanun’a ve ilgili diğer kanun hükümlerine uygun
                          olarak işlenmiş olmasına rağmen, işlenmesini
                          gerektiren sebeplerin ortadan kalkması hâlinde kişisel
                          verilerin silinmesini veya yok edilmesini isteme ve bu
                          kapsamda yapılan işlemin kişisel verilerinizin
                          aktarıldığı üçüncü̈ kişilere bildirilmesini isteme,
                        </li>
                        <li>
                          7. İşlenen verilerin münhasıran otomatik sistemler
                          vasıtasıyla analiz edilmesi suretiyle aleyhinize bir
                          sonucun ortaya çıkması durumunda buna itiraz etme,
                        </li>
                        <li>
                          8. Kişisel verilerinizin kanuna aykırı olarak
                          işlenmesi sebebiyle zarara uğramanız hâlinde zararın
                          giderilmesini talep etme
                        </li>
                      </ol>
                    </li>
                    <p>
                      Haklarınıza yönelik başvurularınızı edictdigital@gmail.com
                      adresine iletebilirsiniz. Talebinizin niteliğine göre en
                      kısa sürede ve en geç otuz gün içinde ücretsiz olarak
                      başvurularınız sonuçlandırılacaktır; ancak ayrıca bir
                      maliyet doğması halinde Edict Digital'in Kişisel Verileri
                      Koruma Kurulunca belirlenecek tarifeye göre tarafınızdan
                      ücret talep edebilme hakkı saklıdır.
                    </p>
                  </ul>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterBlack />
    </div>
  </>
);

export default Kvkk;
