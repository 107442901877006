import { PAGES } from 'redux/actions/pages';

// feature name
export const STUDENT_BOOKS = `${PAGES} [StudentBooks]`;

// action types
export const START_STUDENT_BOOKS_PAGE = `${STUDENT_BOOKS} START_PAGE`;
export const GET_STUDENT_BOOKS = `${STUDENT_BOOKS} GET_STUDENT_BOOKS`;

// action creators
export const startStudentBooksPage = () => ({
  type: START_STUDENT_BOOKS_PAGE,
});

export const getStudentBooks = () => ({
  type: GET_STUDENT_BOOKS,
});
