import { useEffect } from 'react';

const useOutsideAction = (action, ...refs) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (refs && refs.length > 0) {
        if (
          !refs.filter((ref) => ref.current.contains(event.target)).length > 0
        ) {
          action();
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refs, action]);
};

export default useOutsideAction;
