import React, { useState, useEffect, useRef } from 'react';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { connect } from 'react-redux';

import ImageCardItem from './ImageCardItem';

const ImageCardSlider = ({ title, imageCardList, language }) => {
  const containerRef = useRef(null);
  const [distance, setDistance] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const updateSize = () => {
      if (containerWidth !== containerRef?.current?.clientWidth) {
        setContainerWidth(containerRef?.current?.clientWidth + 0);
      }
    };

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [containerWidth]);

  const handleNext = () => {
    if (containerWidth + distance < (imageCardList?.length ?? 0) * 170 - 10) {
      setDistance(distance + 170);
    } else {
      setDistance(0);
    }
  };

  const handlePrev = () => {
    if (distance <= 170) {
      setDistance(0);
    } else {
      setDistance(distance - 170);
    }
  };

  if (!imageCardList || imageCardList.length === 0) {
    return null;
  }

  return (
    <>
      <h5 className="image-card-slider-title">
        {title.toLocaleUpperCase(language)}
      </h5>
      <div className="image-card-slider-wrapper">
        <div className="image-card-slider-btn prev" onClick={handlePrev}>
          {distance > 0 ? (
            <CaretLeft size={40} color="white" weight="bold" />
          ) : null}
        </div>
        <div className="image-card-slider" ref={containerRef}>
          <div
            className="image-card-slider-container"
            style={{
              transform: `translate3d(-${distance}px, 0, 0)`,
            }}
          >
            {imageCardList?.map((card) => (
              <ImageCardItem {...card} />
            ))}
          </div>
        </div>
        <div className="image-card-slider-btn next" onClick={handleNext}>
          {containerWidth < (imageCardList?.length ?? 0) * 170 - 10 ? (
            <CaretRight size={40} color="white" weight="bold" />
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  language: state.localizations?.language ?? 'TR',
});

export default connect(mapStateToProps, null)(ImageCardSlider);
