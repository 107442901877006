import React, { useRef, useState } from 'react';

import CustomNavbar from './CustomNavbar';

function DropdownFixedNavbar({ calledBy, white }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const logoRef = useRef(null);

  return (
    <CustomNavbar
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
      navbarColor=""
      logoRef={logoRef}
      buttonColor="secondary"
      calledBy={calledBy}
      white={white}
    />
  );
}

export default DropdownFixedNavbar;
