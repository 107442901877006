import React, { useRef, useState } from 'react';

import CustomNavbarBlur from './CustomNavbarBlur';

function DropdownFixedBlurNavbar({ calledBy, white }) {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const logoRef = useRef(null);

  return (
    <CustomNavbarBlur
      collapseOpen={collapseOpen}
      setCollapseOpen={setCollapseOpen}
      navbarColor=""
      logoRef={logoRef}
      buttonColor="primary"
      calledBy={calledBy}
      white={white}
    />
  );
}

export default DropdownFixedBlurNavbar;
