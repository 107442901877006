export const monthMapper = [
  { index: '09', text: 'Eylül' },
  { index: '10', text: 'Ekim' },
  { index: '11', text: 'Kasım' },
  { index: '12', text: 'Aralık' },
  { index: '01', text: 'Ocak' },
  { index: '02', text: 'Şubat' },
  { index: '03', text: 'Mart' },
  { index: '04', text: 'Nisan' },
  { index: '05', text: 'Mayıs' },
  { index: '06', text: 'Haziran' },
  { index: '07', text: 'Temmuz' },
  { index: '08', text: 'Ağustos' },
];
