import { useState, useRef } from 'react';
import RecordStatusTypes from '../enums/record-status-types';

let mediaRecorder;
let localStream;

export const useAudioRecorder = () => {
  const dataArray = useRef([]);

  const [status, setStatus] = useState(RecordStatusTypes.IDLE);
  const [audioResult, setAudioResult] = useState('');
  const [audioData, setAudioData] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const startRecording = () => {
    if (status === RecordStatusTypes.IDLE) {
      try {
        setErrorMessage('');
        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then((mediaStreamObj) => {
            localStream = mediaStreamObj;
            mediaRecorder = new MediaRecorder(mediaStreamObj);
            mediaRecorder.start();
            // mediaRecorder.onstart = () => {
            //   setStatus(RecordStatusTypes.RECORDING);
            // };
            mediaRecorder.addEventListener('start', () => {
              setStatus(RecordStatusTypes.RECORDING);
            });
            mediaRecorder.addEventListener('dataavailable', (event) => {
              dataArray.current.push(event.data);
            });
          })
          .catch((error) => {
            setErrorMessage(error?.message);
          });
      } catch (error) {
        setErrorMessage(error?.message);
      }
    } else {
    }
  };

  const resumeRecording = () => {
    if (status === RecordStatusTypes.PAUSED) {
      mediaRecorder.resume();
      mediaRecorder.addEventListener('resume', () => {
        setStatus(RecordStatusTypes.RECORDING);
      });
      // mediaRecorder.onresume = () => {
      //   setStatus(RecordStatusTypes.RECORDING);
      // };
    } else {
    }
  };

  const pauseRecording = () => {
    if (status === RecordStatusTypes.RECORDING) {
      mediaRecorder.pause();

      mediaRecorder.addEventListener('pause', () => {
        setStatus(RecordStatusTypes.PAUSED);
      });
      // mediaRecorder.onpause = () => {
      //   setStatus(RecordStatusTypes.PAUSED);
      // };
      // } else {
    }
  };

  const stopRecording = () => {
    if (status !== RecordStatusTypes.IDLE) {
      mediaRecorder.stop();
      mediaRecorder.addEventListener('stop', () => {
        const audioData = new Blob(dataArray.current, {
          // type: 'audio/mp3',
          type: 'audio/mp4;codecs="mp4a.67"',
        });
        dataArray.current = [];
        setAudioData(audioData);
        setAudioResult(window.URL.createObjectURL(audioData));
        setStatus(RecordStatusTypes.IDLE);
        localStream.getAudioTracks().forEach((track) => {
          track.stop();
        });
      });
    } else {
    }
  };

  const removeAudioData = () => {
    setAudioData(null);
    setAudioResult('');
    dataArray.current = [];
    setStatus(RecordStatusTypes.IDLE);
    localStream?.getAudioTracks()?.forEach((track) => {
      track.stop();
    });
    setErrorMessage('');
  };

  return {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    status,
    audioData,
    audioResult,
    errorMessage,
    removeAudioData,
  };
};

// /**
//  * AUDIO RECORDER
//  */
// import { useEffect, useState } from 'react';
// import Swal from 'sweetalert2';

// async function requestRecorder() {
//   const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
//   return new MediaRecorder(stream);
// }

// const useAudio = (localizations) => {
//   const [audioURL, setAudioURL] = useState('');
//   const [isRecording, setIsRecording] = useState(false);
//   const [recorder, setRecorder] = useState(null);
//   const [audioData, setAudioData] = useState(null);
//   const [audioError, setAudioError] = useState(false);
//   const [startRecordCalled, setStartRecordCalled] = useState(false);

//   useEffect(() => {
//     // Lazily obtain recorder first time we're recording.
//     if (recorder === null) {
//       if (isRecording) {
//         requestRecorder().then(setRecorder, (err) => {
//           console.error(err);
//           setAudioError(true);
//           setIsRecording(false);
//         });
//       }
//       return;
//     }

//     // Manage recorder state.
//     if (isRecording) {
//       try {
//         recorder.start();
//       } catch (error) {
//         console.log(error);
//         setAudioError(true);
//         setIsRecording(false);
//         setRecorder(null);
//       }
//     } else {
//       recorder.stop();
//     }

//     // Obtain the audio when ready.
//     const handleData = (e) => {
//       setAudioData(e.data);
//       setAudioURL(URL.createObjectURL(e.data));
//     };

//     recorder.addEventListener('dataavailable', handleData);
//     return () => {
//       recorder.removeEventListener('dataavailable', handleData);
//       // setRecorder(null);
//     };
//   }, [recorder, isRecording]);

//   useEffect(() => {
//     if (audioError || startRecordCalled) {
//       console.log('Localization in useAudio:', localizations);
//       Swal.fire({
//         icon: 'error',
//         title: localizations?.MESSAGE_ERROR,
//         text: localizations?.MESSAGE_START_AUDIO_ERROR_TEXT,
//         showCancelButton: false,
//         confirmButtonText: localizations?.MESSAGE_CONFIRM_BTN,
//       }).then((_) => {
//         setStartRecordCalled(false);
//         setAudioError(false);
//       });
//     }
//     // eslint-disable-next-line
//   }, [audioError]);

//   const startRecording = () => {
//     setStartRecordCalled(true);
//     setIsRecording(true);
//   };

//   const stopRecording = () => {
//     setIsRecording(false);
//   };

//   const removeAudioData = () => {
//     setAudioURL('');
//     setIsRecording(false);
//     setRecorder(null);
//     setAudioData(null);
//     setAudioError(false);
//     setStartRecordCalled(false);
//   };

//   return [
//     audioURL,
//     isRecording,
//     startRecording,
//     stopRecording,
//     audioData,
//     removeAudioData,
//   ];
// };
// export default useAudio;
