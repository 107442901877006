import React, { useEffect } from 'react';

const CustomPopover = ({
  name,
  trigger,
  children,
  toggle,
  setIsShown,
  containerStyle,
}) => {
  const onToggle = (e) => {
    const popoverContainerEl = document.querySelector(
      '#popover-container-' + name
    );

    if (popoverContainerEl.classList.contains('hidden')) {
      if (setIsShown) setIsShown(true);
      popoverContainerEl.classList.remove('hidden');
    } else {
      if (setIsShown) setIsShown(false);
      popoverContainerEl.classList.add('hidden');
    }
  };

  useEffect(() => {
    onToggle();
    // eslint-disable-next-line
  }, [toggle]);
  return (
    <div className="popover-wrapper">
      <div className="popover-trigger" onClick={onToggle}>
        {trigger}
      </div>
      <div
        className="popover-container"
        style={containerStyle}
        id={'popover-container-' + name}
      >
        <span className="popover-arrow" />
        <div className="popover-content">{children}</div>
      </div>
    </div>
  );
};

export default CustomPopover;
