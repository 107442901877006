import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  CaretLeft,
  Headphones,
  BookOpen,
  Microphone,
  Question,
} from 'phosphor-react';
import { Col, Container, Row, Modal } from 'reactstrap';

import StudentLoadingIndicator from './StudentLoadingIndicator';
import requireSpecificUserAuthentication from 'hocs/require-specific-user-authentication';
import { getStudentBooks, startStudentBooksPage } from 'redux/actions/pages';
import ImageCardSlider from './ImageCardSlider';
import BookStates from 'enums/book-states';
import BookActionsModalContent from './BookActionsModalContent';
import QuizModal from './quiz-modal';
import usePreviousValue from 'hooks/use-previous-value';

// TODO: Hata gelirse mesaj göstermeliyiz.

const StudentBooks = ({
  loading,
  localizations,
  getStudentBooks,
  bookList,
  incompletedBooks,
  startStudentBooksPage,
}) => {
  const location = useLocation();
  const previousBookList = usePreviousValue(bookList);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);
  const [clickedBook, setClickedBook] = useState(null);

  useEffect(() => {
    startStudentBooksPage();
    function handleWindowHeight() {
      setWindowHeight(window.innerHeight);
    }

    // Get all student releated books
    getStudentBooks();

    window.addEventListener('resize', handleWindowHeight);
    return () => {
      window.removeEventListener('resize', handleWindowHeight);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookList?.length !== previousBookList?.length) {
      if (location?.state) {
        const { bookId, showModal } = location?.state;
        if (bookId > 0 && showModal === true && bookList?.length > 0) {
          const currentBook = bookList.find((book) => book.id === bookId);

          if (currentBook) {
            setIsModalOpen(true);
            setClickedBook(currentBook);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, bookList]);

  useEffect(() => {
    if (bookList?.length > 0) {
      setClickedBook((b) => {
        if (b) {
          return bookList.find((book) => book.id === b.id);
        }
        return null;
      });
    }
  }, [bookList]);

  const createUpperElementsFromBook = (book) => {
    let upperElements = [];

    // Book reading status indicator
    upperElements.push(
      <div key={`${book.id}-read`} className="book-status-indicator default">
        <BookOpen
          weight="duotone"
          size={20}
          color={
            book?.isBookReaded
              ? BookStates.completed.color
              : book?.pageLocation > 0
              ? BookStates.notCompleted.color
              : BookStates.notStarted.color
          }
        />
      </div>
    );

    // Book listening status indicator
    if (book.hasBookAudio) {
      upperElements.push(
        <div
          key={`${book.id}-listen`}
          className="book-status-indicator default"
        >
          <Headphones
            weight="duotone"
            size={20}
            color={
              book?.isBookListened
                ? BookStates.completed.color
                : book?.listenPageLocation > 0
                ? BookStates.notCompleted.color
                : BookStates.notStarted.color
            }
          />
        </div>
      );
    }

    // Book recording status indicator
    upperElements.push(
      <div key={`${book.id}-record`} className="book-status-indicator default">
        <Microphone
          weight="duotone"
          size={20}
          color={
            book?.audioRecorded
              ? BookStates.completed.color
              : BookStates.notStarted.color
          }
        />
      </div>
    );

    // Book quiz status indicator
    if (book?.hasPrimaryQuiz) {
      upperElements.push(
        <div key={`${book.id}-quiz`} className="book-status-indicator default">
          <Question
            weight="duotone"
            size={20}
            color={
              book?.quizSolved
                ? BookStates.completed.color
                : BookStates.notStarted.color
            }
          />
        </div>
      );
    }

    return upperElements;
  };

  return (
    <>
      <div className="student-books">
        <StudentLoadingIndicator />
        <div className="go-back">
          <Link to={{ pathname: '/student' }}>
            <CaretLeft
              color="white"
              weight="bold"
              size={windowHeight <= 768 ? 40 : 80}
            />
          </Link>
        </div>
        {!loading ? (
          <div className="books-container">
            <div className="scroll-container">
              <Container>
                <Row>
                  <Col xs={12} xxl={12} className="mb-5">
                    <ImageCardSlider
                      title="Okumaya Devam Et!"
                      imageCardList={bookList
                        ?.filter((book) => incompletedBooks?.includes(book.id))
                        ?.map((book) => {
                          return {
                            key: book.id,
                            image: book.coverImage,
                            onCardClick: (e) => {
                              setClickedBook(book);
                              setIsModalOpen(true);
                            },
                            upperLayerElements:
                              createUpperElementsFromBook(book),
                            imageAlt: book.title,
                          };
                        })}
                    />
                  </Col>
                  {[...new Set(bookList?.map((book) => book.level))]
                    .sort((a, b) => {
                      if (a === 'LEVEL_10') {
                        return 1;
                      }

                      if (b === 'LEVEL_10') {
                        return -1;
                      }

                      return a.localeCompare(b, 'TR');
                    })
                    ?.map((level) => {
                      const levelBooks = bookList
                        ?.filter((book) => book.level === level)
                        ?.sort((a, b) => {
                          if (a.disabled === b.disabled) {
                            return a.orderInLevel - b.orderInLevel;
                          }

                          return a.disabled - b.disabled;
                        });

                      if (levelBooks.length === 0) {
                        return null;
                      }
                      return (
                        <Col xs={12} xxl={12} className="mb-5" key={level}>
                          <ImageCardSlider
                            title={
                              (localizations && localizations[level]) ?? level
                            }
                            imageCardList={levelBooks?.map((book) => {
                              return {
                                key: book.id,
                                image: book.coverImage,
                                onCardClick: (e) => {
                                  setClickedBook(book);
                                  setIsModalOpen(true);
                                },
                                upperLayerElements:
                                  createUpperElementsFromBook(book),
                                imageAlt: book.title,
                                disabled: book.disabled,
                              };
                            })}
                          />
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(false)}
        size="lg"
        contentClassName="book-modal-content"
        backdropClassName="book-modal-backdrop"
        centered
      >
        <BookActionsModalContent
          book={clickedBook}
          onClose={() => setIsModalOpen(false)}
          openPrimaryQuiz={() => {
            setIsModalOpen(false);
            setIsQuizModalOpen(true);
          }}
        />
      </Modal>

      <QuizModal
        isOpen={isQuizModalOpen}
        toggle={() => {
          const hasIncompleted =
            !clickedBook.isBookReaded ||
            (clickedBook.hasBookAudio && !clickedBook.isBookListened) ||
            !clickedBook.audioRecorded;
          if (hasIncompleted) {
            setIsModalOpen(true);
          }
          setIsQuizModalOpen(false);
        }}
        quizId={clickedBook?.primaryQuizId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.ui.loading,
  localizations: state.localization?.pairs,
  bookList: state.data.myBooks?.books,
  incompletedBooks: state.data.myBooks?.incompleteBooks,
});

const mapDispatchToProps = {
  getStudentBooks,
  startStudentBooksPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireSpecificUserAuthentication(['STUDENT'])(StudentBooks));
