import React from 'react';

import { bookLevelColorMapper } from 'mappers/book-level-color.mapper';
import { connect } from 'react-redux';

const ReportSummary = ({
  avatarUrl,
  name,
  surname,
  level,
  totalPoint,
  localizations,
}) => (
  <div className="student-report-summary">
    <div className="student-container">
      <img src={avatarUrl} alt="Student Avatar" />
      <span>{`${name} ${surname}`}</span>
    </div>
    {level && (
      <div className="summary-item summary-item-flex">
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: 10,
            marginRight: '4px',
            backgroundColor: bookLevelColorMapper[level]?.main ?? '#778899',
          }}
        />
        <span>{`${localizations?.REPORT_SUMMARY_LEVEL ?? 'Seviye'}: ${
          level.split('_')[1]
        }`}</span>
      </div>
    )}
    <div className="summary-item">{`Puan: ${totalPoint ?? 0}`}</div>
    <div className="logo">
      <img
        alt="Okuma Platformu Logo"
        src={require('assets/img/logo.png').default}
      />
    </div>
  </div>
);

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(ReportSummary);
