import { PAGES } from 'redux/actions/pages';

// feature name
export const STUDENT_REPORTS = `${PAGES} [StudentReports]`;

// action types
export const START_STUDENT_REPORTS_PAGE = `${STUDENT_REPORTS} START_PAGE`;
export const GET_STUDENT_REPORTS = `${STUDENT_REPORTS} GET_REPORTS`;

// action creators
export const startStudentReportsPage = () => ({
  type: START_STUDENT_REPORTS_PAGE,
});

export const getStudentReports = () => ({
  type: GET_STUDENT_REPORTS,
});
