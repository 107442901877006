import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
// import Rellax from 'rellax';
import HomepageHeader from 'components/Custom/HomepageHeader';
import DefaultNavbar from 'components/Custom/DefaultNavbar';
import { Container, Col, Row, Alert, Button } from 'reactstrap';
// import Testimonials from 'components/Custom/Testimonials';
// import Pricing from 'components/Custom/Pricing';
import FooterBlack from 'components/Custom/FooterBlack';
import { connect } from 'react-redux';
import { iconMapper } from 'mappers/icon.mapper';

const HomeOldDesign = ({ localizations, currentUser }) => {
  const history = useHistory();

  const [showConsent, setShowConsent] = useState(true);

  useEffect(() => {
    const key = 'PrivacyAccepted';
    const item = localStorage.getItem(key);

    if (item && item !== '') {
      const expiry = parseInt(item);
      const now = new Date();

      if (now.getTime() > expiry) {
        localStorage.removeItem(key);
        if (!showConsent) {
          setShowConsent(true);
        }
      } else {
        setShowConsent(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (currentUser?.id > 0 && currentUser?.userType === 'STUDENT') {
      history.push('/student');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  // useEffect(() => {
  //   // initialise Rellax for this page
  //   if (window.innerWidth >= 991) {
  //     setTimeout(function () {
  //       new Rellax('.rellax', {
  //         center: true,
  //       });
  //     }, 5000);
  //   }
  // });

  const consentAcceptClicked = (e) => {
    e.preventDefault();
    const now = new Date();
    const ttl = 1000 * 60 * 60 * 24;
    const expiry = now.getTime() + ttl;
    localStorage.setItem('PrivacyAccepted', expiry.toString());
    setShowConsent(false);
  };
  return (
    <>
      <div
        className="consent"
        style={{
          position: 'fixed',
          bottom: 0,
          zIndex: 2010,
          width: '100%',
        }}
      >
        <Alert isOpen={showConsent} style={{ backgroundColor: '#F4F3ED' }}>
          <div
            className="consent-container"
            style={{
              padding: '10px 10% 10px 10%',
            }}
          >
            <div
              className="consent-content"
              style={{ fontSize: '16px', color: '#555555' }}
            >
              <div
                className="consent-title"
                style={{
                  fontWeight: 600,
                  marginBottom: '10px',
                  letterSpacing: '0.5px',
                }}
              >
                {localizations?.HOME_CONSENT_TITLE}
              </div>
              <div className="consent-text" style={{ marginBottom: '10px' }}>
                {localizations?.HOME_CONSENT_TEXT}
              </div>
              <div
                className="consent-footer"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                }}
              >
                <div
                  className="consent-link"
                  style={{ display: 'block', marginRight: '20px' }}
                >
                  {localizations?.HOME_CONSENT_FOOTER_PART1 + ' '}
                  <a href="#d">{localizations?.HOME_CONSENT_FOOTER_PART2}</a>
                  {' ' + localizations?.HOME_CONSENT_FOOTER_PART3}
                </div>
                <div className="consent-accept">
                  <Button
                    outline
                    className="btn-round"
                    type="button"
                    onClick={consentAcceptClicked}
                    style={{
                      backgroundColor: 'white',
                      color: '#555555',
                      borderColor: '#555555',
                    }}
                  >
                    {localizations?.HOME_CONSENT_ACCEPT_BTN}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Alert>
      </div>
      <DefaultNavbar white={true} />
      <div className="wrapper">
        <HomepageHeader />
        <div className="main">
          <div className="section-space"></div>
          <div>
            <div
              className="home-features section-image"
              style={{
                backgroundColor: 'white',
              }}
            >
              <Col className="mr-auto ml-auto text-center" md="8">
                <h2 className="title">
                  {localizations?.INDIVIDUAL_FEATURES_HEADER}
                </h2>
                {/* <h4 className="description">
                  {localizations?.INDIVIDUAL_FEATURES_DESC}
                </h4> */}
              </Col>
              <Container fluid>
                <Row>
                  <Col className="px-0" md="6">
                    <Col sm="12">
                      <div className="info info-horizontal">
                        <div className="icon">
                          {localizations?.INDIVIDUAL_FEATURES_INFO1_ICON
                            ? iconMapper[
                                localizations.INDIVIDUAL_FEATURES_INFO1_ICON
                              ]({
                                size: 28,
                                weight: 'duotone',
                                color: '#2E2E2E',
                              })
                            : null}
                        </div>
                        <div className="description">
                          <h5 className="info-title">
                            {localizations?.INDIVIDUAL_FEATURES_INFO1_TITLE}
                          </h5>
                          <p className="description">
                            {localizations?.INDIVIDUAL_FEATURES_INFO1_DESC}
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon">
                          {localizations?.INDIVIDUAL_FEATURES_INFO2_ICON
                            ? iconMapper[
                                localizations.INDIVIDUAL_FEATURES_INFO2_ICON
                              ]({
                                size: 28,
                                weight: 'duotone',
                                color: '#2E2E2E',
                              })
                            : null}
                        </div>
                        <div className="description">
                          <h5 className="info-title">
                            {localizations?.INDIVIDUAL_FEATURES_INFO2_TITLE}
                          </h5>
                          <p className="description">
                            {localizations?.INDIVIDUAL_FEATURES_INFO2_DESC}
                          </p>
                        </div>
                      </div>
                      <div className="info info-horizontal">
                        <div className="icon">
                          {localizations?.INDIVIDUAL_FEATURES_INFO3_ICON
                            ? iconMapper[
                                localizations.INDIVIDUAL_FEATURES_INFO3_ICON
                              ]({
                                size: 28,
                                weight: 'duotone',
                                color: '#2E2E2E',
                              })
                            : null}
                        </div>
                        <div className="description">
                          <h5 className="info-title">
                            {localizations?.INDIVIDUAL_FEATURES_INFO3_TITLE}
                          </h5>
                          <p className="description">
                            {localizations?.INDIVIDUAL_FEATURES_INFO3_DESC}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Col>
                  <Col md="6">
                    <div className="image-container">
                      <img
                        alt="..."
                        src={require('assets/img/custom-ipad3.png').default}
                      ></img>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="section-space"></div>
          <div
            className="schools-features"
            style={{ backgroundColor: 'whitesmoke' }}
          >
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">
                    {localizations?.SCHOOLS_FEATURES_HEADER}
                  </h2>
                  {/* <h4 className="description">
                    This is the paragraph where you can write more details about
                    your product. Keep you user engaged by providing meaningful
                    information.
                  </h4> */}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <div className="info info-horizontal">
                    <div className="icon">
                      {localizations?.SCHOOLS_FEATURES_INFO1_ICON
                        ? iconMapper[localizations.SCHOOLS_FEATURES_INFO1_ICON](
                            {
                              size: 28,
                              weight: 'duotone',
                              color: '#2E2E2E',
                            }
                          )
                        : null}
                    </div>
                    <div className="description">
                      <h5 className="info-title">
                        {localizations?.SCHOOLS_FEATURES_INFO1_TITLE}
                      </h5>
                      <p className="description">
                        {localizations?.SCHOOLS_FEATURES_INFO1_DESC}
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon">
                      {localizations?.SCHOOLS_FEATURES_INFO2_ICON
                        ? iconMapper[localizations.SCHOOLS_FEATURES_INFO2_ICON](
                            {
                              size: 28,
                              weight: 'duotone',
                              color: '#2E2E2E',
                            }
                          )
                        : null}
                    </div>
                    <div className="description">
                      <h5 className="info-title">
                        {localizations?.SCHOOLS_FEATURES_INFO2_TITLE}
                      </h5>
                      <p className="description">
                        {localizations?.SCHOOLS_FEATURES_INFO2_DESC}
                      </p>
                    </div>
                  </div>
                </Col>
                <Col md="4">
                  <div className="phone-container">
                    <img
                      alt="..."
                      src={require('assets/img/iphone-assignments.png').default}
                    ></img>
                  </div>
                </Col>
                <Col md="4">
                  <div className="info info-horizontal">
                    <div className="icon">
                      {localizations?.SCHOOLS_FEATURES_INFO3_ICON
                        ? iconMapper[localizations.SCHOOLS_FEATURES_INFO3_ICON](
                            {
                              size: 28,
                              weight: 'duotone',
                              color: '#2E2E2E',
                            }
                          )
                        : null}
                    </div>
                    <div className="description">
                      <h5 className="info-title">
                        {localizations?.SCHOOLS_FEATURES_INFO3_TITLE}
                      </h5>
                      <p className="description">
                        {localizations?.SCHOOLS_FEATURES_INFO3_DESC}
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon">
                      {localizations?.SCHOOLS_FEATURES_INFO4_ICON
                        ? iconMapper[localizations.SCHOOLS_FEATURES_INFO4_ICON](
                            {
                              size: 28,
                              weight: 'duotone',
                              color: '#2E2E2E',
                            }
                          )
                        : null}
                    </div>
                    <div className="description">
                      <h5 className="info-title">
                        {localizations?.SCHOOLS_FEATURES_INFO4_TITLE}
                      </h5>
                      <p className="description">
                        {localizations?.SCHOOLS_FEATURES_INFO4_DESC}
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>

          {/* <div
            className="section related-products"
            data-background-color="blue"
          >
            <Container>
              <h2 className="title text-center" style={{ color: '#333333' }}>
                {localizations?.HOME_PRODUCTS_HEADER}
              </h2>
              <Row>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap1.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap2.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap3.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap4.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap5.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap6.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap7.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
                <Col md="3" sm="6">
                  <Card className="card-product">
                    <div className="card-image zero-padding">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img rounded"
                          src={require('assets/img/kitap8.jpeg').default}
                        ></img>
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div> */}

          {/* <Testimonials />
          <Pricing /> */}
          <FooterBlack />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.auth?.currentUser,
  localizations: state.localization?.pairs,
});

export default connect(mapStateToProps, null)(HomeOldDesign);
