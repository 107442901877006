import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import {
  getFromStorage,
  removeFromStorage,
  FOUND_IN_STORAGE,
  NOT_FOUND_IN_STORAGE,
} from 'redux/actions/storage';
import { setAuth, AUTH, START_AUTH } from 'redux/actions/auth';
import { TOKEN_STORAGE_KEY, BASE_URL } from 'redux/actions/constants';
import { setLoader } from 'redux/actions/ui';
import { setNotification } from 'redux/actions/notifications';
import { setHeader } from 'redux/actions/header';
import { sendLog } from 'redux/actions/log';
import { PRODUCT_TYPE } from 'redux/actions/constants';
import SeverityTypes from 'enums/severity-types';

// Constants
const CURRENT_USER_URL = `${BASE_URL}/api/user/current-user`;

// Auth Middleware implementation
export const authMiddleware = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case START_AUTH:
      next(getFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: AUTH }));
      break;
    case `${AUTH} ${FOUND_IN_STORAGE}`:
      next(setLoader({ state: true, feature: AUTH }));
      next(
        apiRequest({
          body: null,
          method: 'GET',
          url: CURRENT_USER_URL,
          feature: AUTH,
          token: action.payload,
        })
      );
      break;
    case `${AUTH} ${NOT_FOUND_IN_STORAGE}`:
      break;
    case `${AUTH} ${API_SUCCESS}`:
      next(setLoader({ state: false, feature: AUTH }));
      next(setAuth({ auth: action.payload }));
      next(
        setHeader({
          header: {
            organization: action.payload.currentUser?.organization?.id,
            suborganization:
              action.payload.currentUser?.suborganizations[0]?.id,
          },
        })
      );
      break;
    case `${AUTH} ${API_ERROR}`:
      next(setLoader({ state: false, feature: AUTH }));
      if (action.payload.errors) {
        action.payload.errors.forEach((error) => {
          next(setNotification({ message: error.message, feature: AUTH }));
        });
      } else if (typeof action.payload === 'string') {
        next(setNotification({ message: action.payload, feature: AUTH }));
      } else if (action.payload.hasOwnProperty('message')) {
        next(
          setNotification({ message: action.payload.message, feature: AUTH })
        );
      } else {
        next(
          setNotification({
            message: 'Giriş yapmanız gerekmektedir.',
            feature: AUTH,
          })
        );
      }
      next(removeFromStorage({ storageKey: TOKEN_STORAGE_KEY, feature: AUTH }));
      next(
        sendLog({
          product: PRODUCT_TYPE,
          title: `${AUTH} ${API_ERROR}`,
          detail: action.payload,
          severity: SeverityTypes.major,
        })
      );

      break;
    default:
      break;
  }
};
