import React from 'react';
import { useHistory } from 'react-router-dom';
import { X, CheckCircle, BookBookmark } from 'phosphor-react';
import { Col, Container, Row } from 'reactstrap';

import listenImage from 'assets/img/listen.svg';
import quizImage from 'assets/img/quiz.svg';
import readImage from 'assets/img/read.svg';
import recordImage from 'assets/img/record.svg';
import BookActionTypes from 'enums/book-action-types';
import BookStates from 'enums/book-states';
import { connect } from 'react-redux';

const BookActionsModalContent = ({
  book,
  onClose,
  openPrimaryQuiz,
  localizations,
  language,
}) => {
  const history = useHistory();

  const onListenClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/read/${book?.id}`,
      state: {
        actionType: BookActionTypes.listen,
        bookId: book?.id,
        hasAnyOtherIncompleteTask:
          !book.isBookReaded ||
          !book.audioRecorded ||
          (book.hasPrimaryQuiz && !book.quizSolved),
      },
    });
  };
  const onReadClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/read/${book?.id}`,
      state: {
        actionType: BookActionTypes.read,
        bookId: book?.id,
        hasAnyOtherIncompleteTask:
          (book.hasBookAudio && !book.isBookListened) ||
          !book.audioRecorded ||
          (book.hasPrimaryQuiz && !book.quizSolved),
      },
    });
  };
  const onRecordClick = (e) => {
    e.preventDefault();
    history.push({
      pathname: `/read/${book?.id}`,
      state: {
        actionType: BookActionTypes.record,
        bookId: book?.id,
        hasAnyOtherIncompleteTask:
          !book.isBookReaded ||
          (book.hasBookAudio && !book.isBookListened) ||
          (book.hasPrimaryQuiz && !book.quizSolved),
      },
    });
  };
  const onQuizClick = (e) => {
    e.preventDefault();
    openPrimaryQuiz();
  };

  return (
    <>
      <div className="modal-header justify-content-center my-3">
        <button
          aria-hidden={true}
          className="close"
          onClick={onClose}
          type="button"
        >
          <X color="red" size={40} />
        </button>
      </div>
      <div className="modal-body mb-4">
        <Container>
          <Row className="no-gutters">
            <Col xs={6}>
              <img src={book?.coverImage} alt="" />
            </Col>
            <Col xs={6}>
              {book?.disabled !== null &&
              book?.disabled !== undefined &&
              book?.disabled === true ? (
                <div className="book-warning-container">
                  <span className="book-warning-text">
                    {(
                      localizations?.BOOK_DISABLED_WARNING_TEXT ??
                      'Bu kitap sınıf içerisinde çalışılacaktır.'
                    ).toLocaleUpperCase(language)}
                  </span>
                </div>
              ) : (
                <>
                  <div className="book-action-item" onClick={onReadClick}>
                    <img src={readImage} alt="" />
                    <span className="font-luckiest-guy">
                      {localizations?.ACTION_TYPE_READ ?? 'OKUMA'}
                    </span>
                    {book?.isBookReaded ? (
                      <CheckCircle color="#06d6a0" size={30} weight="duotone" />
                    ) : book?.pageLocation > 0 ? (
                      <BookBookmark
                        color={BookStates.notCompleted.color}
                        size={30}
                        weight="duotone"
                      />
                    ) : null}
                  </div>

                  {book?.hasBookAudio ? (
                    <div className="book-action-item" onClick={onListenClick}>
                      <img src={listenImage} alt="" />
                      <span className="font-luckiest-guy">
                        {localizations?.ACTION_TYPE_LISTEN ?? 'DİNLEME'}
                      </span>
                      {book?.isBookListened ? (
                        <CheckCircle
                          color="#06d6a0"
                          size={30}
                          weight="duotone"
                        />
                      ) : book?.listenPageLocation > 0 ? (
                        <BookBookmark
                          color={BookStates.notCompleted.color}
                          size={30}
                          weight="duotone"
                        />
                      ) : null}
                    </div>
                  ) : null}

                  <div className="book-action-item" onClick={onRecordClick}>
                    <img src={recordImage} alt="" />
                    <span className="font-luckiest-guy">
                      {localizations?.ACTION_TYPE_RECORD ?? 'OKU - KAYDET'}
                    </span>
                    {book?.audioRecorded ? (
                      <CheckCircle color="#06d6a0" size={30} weight="duotone" />
                    ) : null}
                  </div>

                  {book?.hasPrimaryQuiz ? (
                    <div className="book-action-item" onClick={onQuizClick}>
                      <img src={quizImage} alt="" />
                      <span className="font-luckiest-guy">
                        {localizations?.ACTION_TYPE_QUIZ ?? 'DEĞERLENDİRME'}
                      </span>
                      {book?.quizSolved ? (
                        <CheckCircle
                          color="#06d6a0"
                          size={30}
                          weight="duotone"
                        />
                      ) : null}
                    </div>
                  ) : null}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
      ;
    </>
  );
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
  language: state.localization?.language ?? 'TR',
});

export default connect(mapStateToProps, null)(BookActionsModalContent);
