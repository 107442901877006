// action types

// Aşağıdaki işlemler state: { data: { VAR } } şeklinde uzanan
// VAR'ın tipine göre seçilmelidir.

// 1. VAR type:  (ARRAY | OBJECT | VALUE )
// Direkt olarak data objesi içerisinde değeri atanacaksa
// YOKSA EKLER, VARSA YERİNE YENİSİNİ KOYAR
export const SET_DATA = 'SET_DATA';
export const PUT_DATA = 'PUT_DATA';
export const UPDATE_ITEM_DATA = 'UPDATE_ITEM_DATA';
export const UPDATE_WITH_PART_OF_ITEM = 'UPDATE_WITH_PART_OF_ITEM';
export const REMOVE_DATA = 'REMOVE_DATA';
export const UPDATE_ITEM_OF_ARRAY_OF_OBJECT = 'UPDATE_ITEM_OF_ARRAY_OF_OBJECT';
export const UPDATE_ONE_OBJECT_ARRAY_ITEM = 'UPDATE_ONE_OBJECT_ARRAY_ITEM';
export const REMOVE_ONE_OBJECT_ARRAY_ITEM = 'REMOVE_ONE_OBJECT_ARRAY_ITEM';
export const CLEAR_DATA = 'CLEAR_DATA';

// action creators
export const setData = ({ data, feature }) => ({
  type: `${feature} ${SET_DATA}`,
  payload: data,
  meta: { feature },
});

export const putData = ({ feature, data, where }) => ({
  type: `${feature} ${PUT_DATA}`,
  payload: { data, where },
  meta: { feature },
});

export const updateItemData = ({ feature, where, item, key, value }) => ({
  type: `${feature} ${UPDATE_ITEM_DATA}`,
  payload: { where, item, key, value },
  meta: { feature },
});

export const updateWithPartOfItem = ({ feature, where, item, partOfItem }) => ({
  type: `${feature} ${UPDATE_WITH_PART_OF_ITEM}`,
  payload: { where, item, partOfItem },
  meta: { feature },
});

export const updateItemOfArrayOfObject = ({
  feature,
  where,
  item,
  key,
  id,
  data,
}) => ({
  type: `${feature} ${UPDATE_ITEM_OF_ARRAY_OF_OBJECT}`,
  payload: { where, item, key, id, data },
  meta: { feature },
});

export const updateOneObjectArrayItem = ({
  feature,
  where,
  key,
  id,
  data,
}) => ({
  type: `${feature} ${UPDATE_ONE_OBJECT_ARRAY_ITEM}`,
  payload: { where, key, id, data },
  meta: { feature },
});

export const removeOneObjectArrayItem = ({ feature, where, key, id }) => ({
  type: `${feature} ${REMOVE_ONE_OBJECT_ARRAY_ITEM}`,
  payload: { where, key, id },
  meta: { feature },
});

export const removeData = ({ feature, where }) => ({
  type: `${feature} ${REMOVE_DATA}`,
  payload: { where },
  meta: { feature },
});

export const clearData = () => ({
  type: CLEAR_DATA,
});
