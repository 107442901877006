import { SET_MESSAGE, REMOVE_MESSAGE } from 'redux/actions/message';

const initState = {};

export const messageReducer = (message = initState, action) => {
  switch (true) {
    case action.type.includes(SET_MESSAGE):
      return { ...message, [action.meta.feature]: action.payload };
    case action.type.includes(REMOVE_MESSAGE):
      const copyOfMessage = { ...message };
      delete copyOfMessage[action.meta.feature];
      return copyOfMessage;
    default:
      return message;
  }
};
