import { PAGES } from 'redux/actions/pages/constants';

// feature name
export const CONTACT_US_PAGE = `${PAGES} [ContactUs]`;

// actiona types
export const SEND_CONTACT_FORM = `${CONTACT_US_PAGE} SEND_CONTACT_FORM`;

// action creators
export const sendContactForm = ({ name, email, phone, message }) => ({
  type: SEND_CONTACT_FORM,
  payload: { name, email, phone, message },
});
