import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { setOrigin } from 'redux/actions/ui';

const requireSpecificUserAuthentication = (userTypes) => (ChildComponent) => {
  class ComposedComponent extends Component {
    render() {
      const { auth, location, setOrigin } = this.props;
      if (auth && auth.currentUser && auth.currentUser.id) {
        if (userTypes.includes(auth.currentUser.userType)) {
          return <ChildComponent {...this.props} />;
        }
        return <Redirect to="/not-authorized" />;
      }

      setOrigin({
        origin: location.pathname,
        feature: '[RequireAuthentication]',
      });
      return <Redirect to="/" />;
    }
  }

  const mapToStateProps = (state) => ({ auth: state.auth });
  const mapDispatchToProps = { setOrigin };

  return connect(mapToStateProps, mapDispatchToProps)(ComposedComponent);
};

export default requireSpecificUserAuthentication;
