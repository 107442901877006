import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  CaretLeft,
  Headphones,
  BookOpen,
  Microphone,
  Question,
  Files,
} from 'phosphor-react';
import { Col, Container, Row, Modal } from 'reactstrap';

import StudentLoadingIndicator from './StudentLoadingIndicator';
import {
  getStudentAssignments,
  startStudentAssignmentsPage,
} from 'redux/actions/pages';
import requireSpecificUserAuthentication from 'hocs/require-specific-user-authentication';
import ImageCardSlider from './ImageCardSlider';
import BookActionTypes from 'enums/book-action-types';
import BookStates from 'enums/book-states';
import AssignmentActionsModalContent from './AssignmentActionsModalContent';
import QuizModal from './quiz-modal';
import usePreviousValue from 'hooks/use-previous-value';
import noAssignmentImage from 'assets/img/fall-with-skateboard.svg';

// TODO: Hata gelirse mesaj göstermeliyiz.

const StudentAssignments = ({
  loading,
  // localizations,
  getStudentAssignments,
  assignmentList,
  startStudentAssignmentsPage,
}) => {
  const location = useLocation();
  const previousAssignmentList = usePreviousValue(assignmentList);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuizModalOpen, setIsQuizModalOpen] = useState(false);
  const [clickedAssignment, setClickedAssignment] = useState(null);
  const [currentTask, setCurrentTask] = useState(null);

  useEffect(() => {
    startStudentAssignmentsPage();

    function handleWindowHeight() {
      setWindowHeight(window.innerHeight);
    }

    // Get all student releated books
    getStudentAssignments();

    window.addEventListener('resize', handleWindowHeight);
    return () => {
      window.removeEventListener('resize', handleWindowHeight);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (assignmentList?.length !== previousAssignmentList?.length) {
      if (location?.state) {
        const { assignmentId, showModal } = location?.state;
        if (
          assignmentId > 0 &&
          showModal === true &&
          assignmentList?.length > 0
        ) {
          const currentAssignment = assignmentList.find(
            (ass) => ass.assignmentId === assignmentId
          );

          if (currentAssignment) {
            setIsModalOpen(true);
            setClickedAssignment(currentAssignment);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, assignmentList]);

  useEffect(() => {
    if (assignmentList?.length > 0) {
      setClickedAssignment((as) => {
        if (as) {
          return assignmentList.find(
            (assignment) => assignment.assignmentId === as.assignmentId
          );
        }

        return null;
      });
    }
  }, [assignmentList]);

  const createUpperElementsFromAssignment = (assignment) => {
    let upperElements = [];

    // Check if assignment has READ task
    const readingTask = assignment?.tasks?.find(
      (task) => task.taskType === BookActionTypes.read
    );
    if (readingTask) {
      upperElements.push(
        <div
          key={`${assignment.assignmentId}-read`}
          className="book-status-indicator default"
        >
          <BookOpen
            weight="duotone"
            size={20}
            color={
              readingTask.elements[0]?.isCompleted
                ? BookStates.completed.color
                : assignment?.pageLocation > 0
                ? BookStates.notCompleted.color
                : BookStates.notStarted.color
            }
          />
        </div>
      );
    }

    // Check if book has audio
    if (assignment.hasBookAudio) {
      // Check if assignment has LISTEN task
      const listeningTask = assignment?.tasks?.find(
        (task) => task.taskType === BookActionTypes.listen
      );
      if (listeningTask) {
        upperElements.push(
          <div
            key={`${assignment.assignmentId}-listen`}
            className="book-status-indicator default"
          >
            <Headphones
              weight="duotone"
              size={20}
              color={
                listeningTask.elements[0]?.isCompleted
                  ? BookStates.completed.color
                  : assignment?.listenPageLocation > 0
                  ? BookStates.notCompleted.color
                  : BookStates.notStarted.color
              }
            />
          </div>
        );
      }
    }

    // Check if assignment has RECORD task
    const recordingTask = assignment?.tasks?.find(
      (task) => task.taskType === BookActionTypes.record
    );
    if (recordingTask) {
      upperElements.push(
        <div
          key={`${assignment.assignmentId}-record`}
          className="book-status-indicator default"
        >
          <Microphone
            weight="duotone"
            size={20}
            color={
              recordingTask.elements[0]?.isCompleted
                ? BookStates.completed.color
                : BookStates.notStarted.color
            }
          />
        </div>
      );
    }

    // Check if assignment has QUIZ task(s)
    const quizTask = assignment?.tasks?.find(
      (task) => task.taskType === BookActionTypes.quiz
    );
    if (quizTask) {
      upperElements.push(
        <div
          key={`${assignment.assignmentId}-quiz`}
          className="book-status-indicator default"
        >
          <Question
            weight="duotone"
            size={20}
            color={
              quizTask?.elements?.every((element) => element?.isCompleted)
                ? BookStates.completed.color
                : quizTask?.elements?.some((element) => element?.isCompleted)
                ? BookStates.notCompleted.color
                : BookStates.notStarted.color
            }
          />
        </div>
      );
    }

    // Check if assignment has MATERIAL task(s)
    const materialTask = assignment?.tasks?.find(
      (task) => task.taskType === BookActionTypes.material
    );
    if (materialTask) {
      upperElements.push(
        <div
          key={`${assignment.assignmentId}-material`}
          className="book-status-indicator default"
        >
          <Files
            weight="duotone"
            size={20}
            color={
              materialTask?.elements?.every((element) => element?.isCompleted)
                ? BookStates.completed.color
                : materialTask?.elements?.some(
                    (element) => element?.isCompleted
                  )
                ? BookStates.notCompleted.color
                : BookStates.notStarted.color
            }
          />
        </div>
      );
    }

    return upperElements;
  };

  const isAssignmentExpired = (assignment) => {
    if (assignment.passedDueDate) {
      return true;
    }

    if (!assignment.dueDate) {
      return assignment.passedDueDate;
    }

    const now = new Date();
    const today =
      now.getFullYear() * 10000 + (now.getMonth() + 1) * 100 + now.getDate();
    const dueDate = parseInt(assignment.dueDate.split('.').reverse().join(''));
    return today > dueDate;
  };

  const isAssignmentCompleted = (assignment) => {
    return assignment?.tasks?.every((task) =>
      task.elements.every((element) => element.isCompleted === true)
    );
  };

  return (
    <>
      <div className="student-assignments">
        <StudentLoadingIndicator />
        <div className="go-back">
          <Link to={{ pathname: '/student' }}>
            <CaretLeft
              color="white"
              weight="bold"
              size={windowHeight <= 768 ? 40 : 80}
            />
          </Link>
        </div>
        {!loading ? (
          <div className="books-container">
            <div className="scroll-container">
              <Container>
                <Row>
                  {assignmentList?.length > 0 ? (
                    <>
                      <Col xs={12} xxl={12}>
                        <ImageCardSlider
                          title="Yeni Görevler"
                          imageCardList={assignmentList
                            ?.filter(
                              (assignment) => !isAssignmentExpired(assignment)
                            )
                            ?.map((assignment) => {
                              return {
                                key: assignment.assignmentId,
                                image: assignment.coverImage,
                                onCardClick: (e) => {
                                  setClickedAssignment(assignment);
                                  setIsModalOpen(true);
                                },
                                upperLayerElements:
                                  createUpperElementsFromAssignment(assignment),
                                imageAlt: assignment.title,
                              };
                            })}
                        />
                      </Col>
                      <Col xs={12} xxl={12}>
                        <ImageCardSlider
                          title="Tamamlanmamış Görevler"
                          imageCardList={assignmentList
                            ?.filter(
                              (assignment) =>
                                isAssignmentExpired(assignment) &&
                                !isAssignmentCompleted(assignment)
                            )
                            ?.map((assignment) => {
                              return {
                                key: assignment.assignmentId,
                                image: assignment.coverImage,
                                onCardClick: (e) => {
                                  setClickedAssignment(assignment);
                                  setIsModalOpen(true);
                                },
                                upperLayerElements:
                                  createUpperElementsFromAssignment(assignment),
                                imageAlt: assignment.title,
                              };
                            })}
                        />
                      </Col>
                      <Col xs={12} xxl={12}>
                        <ImageCardSlider
                          title="Tamamlanmış Görevler"
                          imageCardList={assignmentList
                            ?.filter((assignment) =>
                              isAssignmentCompleted(assignment)
                            )
                            ?.map((assignment) => {
                              return {
                                key: assignment.assignmentId,
                                image: assignment.coverImage,
                                onCardClick: (e) => {
                                  setClickedAssignment(assignment);
                                  setIsModalOpen(true);
                                },
                                upperLayerElements:
                                  createUpperElementsFromAssignment(assignment),
                                imageAlt: assignment.title,
                              };
                            })}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col xs={12} xxl={12}>
                      <div className="no-assignment">
                        <img src={noAssignmentImage} alt="Görev Yok!" />
                        <p>GÖREV YOK!</p>
                      </div>
                    </Col>
                  )}
                </Row>
              </Container>
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        isOpen={isModalOpen}
        toggle={() => {
          setIsModalOpen(false);
          setClickedAssignment(null);
        }}
        size="lg"
        contentClassName="assignment-modal-content"
        backdropClassName="assignment-modal-backdrop"
        centered
      >
        <AssignmentActionsModalContent
          assignment={clickedAssignment}
          onClose={() => {
            setIsModalOpen(false);
            setClickedAssignment(null);
          }}
          returnWithCurrentTask={(task) => {
            setIsModalOpen(false);
            setCurrentTask(task);
            setIsQuizModalOpen(true);
          }}
        />
      </Modal>

      <QuizModal
        isOpen={isQuizModalOpen && currentTask !== null}
        toggle={() => {
          // Eğer kullanıcının yapabileceği başka işlem varsa
          // Tekrardan modal açılmalıdır.
          const hasIncompleted = clickedAssignment?.tasks?.some((task) =>
            task.elements.some(
              (element) =>
                element.isCompleted === false &&
                element.elementId !== currentTask?.elementId
            )
          );
          if (hasIncompleted) {
            setIsModalOpen(true);
          } else {
            setClickedAssignment(null);
          }

          setIsQuizModalOpen(false);
          setCurrentTask(null);
        }}
        quizId={currentTask?.elementId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.ui.loading,
  localizations: state.localization?.pairs,
  assignmentList: state.data.myAssignedBooks?.books,
});

const mapDispatchToProps = {
  getStudentAssignments,
  startStudentAssignmentsPage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireSpecificUserAuthentication(['STUDENT'])(StudentAssignments));
