import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { connect } from 'react-redux';

const StudentLoadingIndicator = ({ loading }) => {
  return loading ? (
    <div className="whole-screen-without-bg">
      <PuffLoader color="white" size={100} />
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps, {})(StudentLoadingIndicator);
