import { PAGES } from 'redux/actions/pages';

// feature name
export const BOOK_LIST = `${PAGES} [BookList]`;

// action types
export const START_BOOK_LIST_SCREEN = `${BOOK_LIST} START`;

// action creators
export const startBookListScreen = () => ({
  type: START_BOOK_LIST_SCREEN,
});
