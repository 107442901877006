import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { monthMapper } from './month.mapper';
import { connect } from 'react-redux';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const MonthlyReadingTaskIncentiveOrNotComparison = ({
  monthlyResults,
  localizations,
}) => {
  const createMonthArray = () => {
    const date = new Date();
    const indexOfCurrentMonth = date.getMonth() + 1;
    const firstMonthIndex = parseInt(monthMapper[0].index);

    return Array.from(
      {
        length:
          indexOfCurrentMonth < firstMonthIndex
            ? 13 + indexOfCurrentMonth - firstMonthIndex
            : 1 + indexOfCurrentMonth - firstMonthIndex,
      },
      (_, i) => i + firstMonthIndex
    );
  };

  const getIndividualReadingData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon) === (month === 12 ? 12 : month % 12) &&
          current.type === 'READ' &&
          current.incentive
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const getAssignmentReadingData = () =>
    createMonthArray().map((month) =>
      monthlyResults.reduce((total, current) => {
        if (
          parseInt(current.mon) === (month === 12 ? 12 : month % 12) &&
          current.type === 'READ' &&
          !current.incentive
        ) {
          return total + current.count;
        }
        return total;
      }, 0)
    );

  const calculateAcademicYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if (currentDate.getMonth() + 1 >= parseInt(monthMapper[0].index)) {
      return `${currentYear}-${currentYear + 1}`;
    }
    return `${currentYear - 1}-${currentYear}`;
  };

  return (
    <div className="student-improvement-progress">
      <div className="section-header">{`${
        localizations?.MONTHLY_READING_COMPARISON_HEADER ?? 'Okuma Seçimi'
      } (${calculateAcademicYear()} ${
        localizations?.REPORT_ACADEMIC_YEAR ?? 'Eğitim Öğretim Yılı'
      })`}</div>
      <div className="chart-container">
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: 'bottom',
                align: 'center',
                labels: { font: { size: 16 }, padding: 8 },
              },
              title: {
                display: false,
              },
              datalabels: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: { font: { size: 16 } },
              },
              y: {
                ticks: { font: { size: 20 }, precision: 0 },
              },
            },
          }}
          data={{
            labels: monthMapper.map((month) => month.text),
            datasets: [
              {
                label:
                  localizations?.MONTHLY_READING_COMPARISON_INCENTIVE_LABEL ??
                  'Bireysel Okuma',
                data: getIndividualReadingData(),
                borderColor:
                  localizations?.MONTHLY_READING_COMPARISON_INCENTIVE_BORDER_COLOR ??
                  'rgba(79, 118, 255, 1)',
                backgroundColor:
                  localizations?.MONTHLY_READING_COMPARISON_INCENTIVE_BGCOLOR ??
                  'rgba(79, 118, 255, 1)',
              },
              {
                label:
                  localizations?.MONTHLY_READING_COMPARISON_ASSIGNMENT_LABEL ??
                  'Görev Okuması',
                data: getAssignmentReadingData(),
                borderColor:
                  localizations?.MONTHLY_READING_COMPARISON_ASSIGNMENT_BORDER_COLOR ??
                  'rgba(255, 193, 23, 1)',
                backgroundColor:
                  localizations?.MONTHLY_READING_COMPARISON_ASSIGNMENT_BGCOLOR ??
                  'rgba(255, 193, 23, 1)',
              },
            ],
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

export default connect(
  mapStateToProps,
  null
)(MonthlyReadingTaskIncentiveOrNotComparison);
