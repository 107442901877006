import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';

import requireSpecificUserAuthentication from 'hocs/require-specific-user-authentication';
import assignmentsBtnImage from 'assets/img/gorevler-btn.svg';
import booksBtnImage from 'assets/img/kitaplar-btn.svg';
import messagesBtnImage from 'assets/img/mesajlar-btn.svg';
import exitBtnImage from 'assets/img/exit.min.svg';
import reportsBtnImage from 'assets/img/reports.svg';
import {
  checkIncompleteAssignment,
  signout,
  getStudentTotalPoint,
  startStudentHomePage,
} from 'redux/actions/pages';
import number0Image from 'assets/img/0.svg';
import number1Image from 'assets/img/1.svg';
import number2Image from 'assets/img/2.svg';
import number3Image from 'assets/img/3.svg';
import number4Image from 'assets/img/4.svg';
import number5Image from 'assets/img/5.svg';
import number6Image from 'assets/img/6.svg';
import number7Image from 'assets/img/7.svg';
import number8Image from 'assets/img/8.svg';
import number9Image from 'assets/img/9.svg';
import starImage from 'assets/img/star.svg';

const numberImageMapper = [
  number0Image,
  number1Image,
  number2Image,
  number3Image,
  number4Image,
  number5Image,
  number6Image,
  number7Image,
  number8Image,
  number9Image,
];

const StudentHomePage = ({
  auth,
  checkIncompleteAssignment,
  // hasIncompleteAssignment,
  signout,
  getStudentTotalPoint,
  totalPoint,
  startStudentHomePage,
}) => {
  const history = useHistory();

  useEffect(() => {
    startStudentHomePage();
    checkIncompleteAssignment();
    getStudentTotalPoint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="student-home-container">
      <div
        className="student-exit-btn"
        aria-hidden
        onClick={() => signout({ email: auth?.currentUser?.email })}
      >
        <img src={exitBtnImage} alt="Çıkış Butonu" />
      </div>
      <div
        className="student-reports-btn"
        aria-hidden
        onClick={() => history.push('/reports')}
      >
        <div className="student-reports-image-container">
          <div className="student-report-btn-image">
            <img src={reportsBtnImage} alt="Raporlar Butonu" />
          </div>
          <div className="student-report-point-container">
            <div className="student-report-point">
              {totalPoint
                ?.split('')
                ?.map((ch, index) => (
                  <img
                    key={`${ch}-${index}`}
                    src={numberImageMapper[parseInt(ch)]}
                    alt=""
                  />
                )) ?? <img src={numberImageMapper[0]} alt="" />}
              <img src={starImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-container">
        <Container className="d-flex align-items-center">
          <Row>
            <Col>
              <div>
                {/* <div className="assignments-btn"> */}
                <Link
                  to={{
                    pathname: '/assignments',
                  }}
                >
                  <img src={assignmentsBtnImage} alt="" />
                </Link>
              </div>
            </Col>
            <Col>
              <div>
                <Link
                  to={{
                    pathname: '/books',
                  }}
                >
                  <img src={booksBtnImage} alt="" />
                </Link>
              </div>
            </Col>
            <Col>
              <div>
                <Link
                  to={{
                    pathname: '/messages',
                  }}
                >
                  <img src={messagesBtnImage} alt="" />
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  hasIncompleteAssignment: state.data.hasIncompleteAssignment,
  totalPoint: state.data.reports?.totalPoint,
});

const mapDispatchToProps = {
  checkIncompleteAssignment,
  signout,
  getStudentTotalPoint,
  startStudentHomePage,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(requireSpecificUserAuthentication(['STUDENT'])(StudentHomePage));
