import React from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import { XCircle } from 'phosphor-react';

const CustomModal = ({
  title,
  isOpen,
  toggle,
  onCloseClick,
  children,
  buttons,
  backdrop = true,
  keyboard = true,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={backdrop}
      keyboard={keyboard}
      size="lg"
      backdropClassName="custom-modal-backdrop"
      centered
    >
      <div className="modal-header justify-content-center">
        <button
          aria-hidden={true}
          className="close"
          onClick={onCloseClick}
          type="button"
          style={{ opacity: 1 }}
        >
          <div
            className="close-button-container"
            style={{
              padding: 0,
              margin: 0,
              width: '35px',
              height: '35px',
              borderRadius: '20px',
              backgroundColor: '#B95353',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <XCircle
              weight="duotone"
              color="white"
              size={27}
              style={{ padding: 0, margin: 0 }}
            />
          </div>
        </button>
        <h5
          className="title"
          style={{
            fontSize: '2.0em',
            fontWeight: 400,
            width: '80%',
            textAlign: 'center',
          }}
        >
          {title}
        </h5>
      </div>
      <div
        className="modal-body"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {children}
      </div>
      <ModalFooter
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap-reverse',
          marginTop: '16px',
        }}
      >
        {buttons && buttons.length > 0
          ? buttons
              .filter((b) => b !== null && b !== undefined)
              .map((b) => {
                return (
                  <ModalButton
                    key={b.name}
                    fullWidth={b.fullWidth}
                    color={b.color}
                    text={b.text}
                    icon={b.icon}
                    onClick={b.onClick}
                  />
                );
              })
          : null}
      </ModalFooter>
    </Modal>
  );
};

const ModalButton = ({ fullWidth, color, text, icon, onClick }) => {
  let style = {
    backgroundColor: color,
    margin: '0 7.5px 0 7.5px',
    padding: '10px',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  };

  if (fullWidth) {
    style = { ...style, minWidth: '200px', marginTop: '10px' };
  }
  return (
    <div className="modal-button" style={style} onClick={onClick}>
      {text}
      <span style={{ marginLeft: '5px' }}>{icon}</span>
    </div>
  );
};

export default CustomModal;
