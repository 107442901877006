import { PAGES } from 'redux/actions/pages';

// feature name
export const FORGOT_PASSWORD_PAGE = `${PAGES} [ForgotPassword]`;

// action types
export const START_FORGOT_PASSWORD_PAGE = `${FORGOT_PASSWORD_PAGE} START`;
export const SEND_LINK_BY_EMAIL = `${FORGOT_PASSWORD_PAGE} SEND_LINK_BY_EMAIL`;

// action creators
export const startForgotPasswordPage = () => ({
  type: START_FORGOT_PASSWORD_PAGE,
});

export const sendLinkByEmail = ({ email }) => ({
  type: SEND_LINK_BY_EMAIL,
  payload: { email },
});
