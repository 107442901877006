import React from 'react';
import { withRouter } from 'react-router-dom';
import CustomLoading from 'views/CustomLoading';
import DefaultNavbar from 'components/Custom/DefaultNavbar';
import { Button, Container, Row, Col } from 'reactstrap';
import { sendLog } from 'redux/actions/log';
import { PRODUCT_TYPE } from 'redux/actions/constants';
import SeverityTypes from 'enums/severity-types';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    this.props.sendLog({
      product: PRODUCT_TYPE,
      title: 'UI Error',
      detail: {
        stack: `${error.stack}`,
        inputs: {},
        data: error.response?.data,
        message: error.message,
      },
      severity: SeverityTypes.critical,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <CustomLoading />
          <DefaultNavbar white={true} />
          <div id="headers">
            <div className="header-1">
              <div className="page-header header-filter">
                <div
                  className="page-header-image"
                  style={{
                    width: '100%',
                    height: '100%',
                    backgroundImage:
                      'url(' + require('assets/img/error.jpg').default + ')',
                  }}
                />
                <Container>
                  <Row>
                    <Col className="ml-auto text-right" md="7">
                      <h1 className="title">
                        {this.props.localizations?.ERROR_BOUNDARY_TITLE}
                      </h1>
                      <h4 className="description">
                        {this.props.localizations?.ERROR_BOUNDARY_DESC}
                      </h4>
                      <br></br>
                      <div className="buttons">
                        <Button
                          className="mr-3"
                          color="info"
                          href="/"
                          size="lg"
                        >
                          {this.props.localizations?.ERROR_BOUNDARY_GO_HOME_BTN}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </>
      );
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  sendLog,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ErrorBoundary));
