import { apiRequest, API_SUCCESS, API_ERROR } from 'redux/actions/api';
import { BASE_URL } from 'redux/actions/constants';
import { SEND_LOG } from 'redux/actions/log';

const SEND_LOG_URL = `${BASE_URL}/api/log/new-log`;

export const logMiddleware = () => (next) => (action) => {
  next(action);

  switch (action.type) {
    case SEND_LOG:
      next(
        apiRequest({
          body: action.payload,
          method: 'POST',
          url: SEND_LOG_URL,
          feature: SEND_LOG,
        })
      );
      break;
    case `${SEND_LOG} ${API_SUCCESS}`:
      break;
    case `${SEND_LOG} ${API_ERROR}`:
      break;
    default:
      break;
  }
};
