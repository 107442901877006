import React from 'react';

const ImageCardItem = ({
  image,
  upperLayerElements,
  onCardClick,
  imageAlt,
  disabled,
  style,
}) => {
  return (
    <>
      <div
        className={`image-card-item ${
          disabled !== null && disabled !== undefined && disabled === true
            ? 'disabled-book'
            : ''
        }`}
        onClick={onCardClick}
        style={style?.container}
      >
        <img src={image} alt={imageAlt} style={style?.image} />
        {upperLayerElements && upperLayerElements.length > 0 ? (
          <div
            className="image-card-upper-layer-container"
            style={style?.upperLayer}
          >
            {upperLayerElements}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ImageCardItem;
