import {
  foundInStorage,
  notFoundInStorage,
  GET_FROM_STORAGE,
  SET_TO_STORAGE,
  REMOVE_FROM_STORAGE,
} from 'redux/actions/storage';

// import { PostMessageHelper } from 'helpers/post-message.helper';

export const storageMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    switch (true) {
      case action.type.includes(GET_FROM_STORAGE):
        const storedData = localStorage.getItem(action.payload);
        if (storedData && storedData !== '') {
          dispatch(
            foundInStorage({ result: storedData, feature: action.meta.feature })
          );
        } else {
          dispatch(notFoundInStorage({ feature: action.meta.feature }));
        }
        break;
      case action.type.includes(SET_TO_STORAGE):
        localStorage.setItem(
          action.payload.storageKey,
          action.payload.storageValue
        );
        // const setMessage = {
        //   [action.payload.storageKey]: action.payload.storageValue,
        // };
        // PostMessageHelper.postCrossDomainMessage(setMessage);
        break;
      case action.type.includes(REMOVE_FROM_STORAGE):
        localStorage.removeItem(action.payload);
        // const removeMessage = { [action.payload]: '' };
        // PostMessageHelper.postCrossDomainMessage(removeMessage);
        break;
      default:
        break;
    }
  };
