import React from 'react';
import { Alert, Container } from 'reactstrap';
import { ThumbsUp, HandPalm } from 'phosphor-react';
import { connect } from 'react-redux';

const CustomAlert = ({ notifications, localizations }) => {
  return (
    <div className="custom-alert">
      {notifications.map((notification) => {
        return (
          <Alert
            key={notification.message.id}
            color={notification.type === 'success' ? 'info' : 'danger'}
            isOpen={true}
          >
            <Container>
              <div className="alert-icon">
                {notification.type === 'success' ? (
                  <ThumbsUp size={24} weight="duotone" />
                ) : (
                  <HandPalm size={24} weight="duotone" />
                )}
              </div>
              <strong>
                {notification.type === 'success'
                  ? localizations?.NOTIFICATION_HEADER_SUCCESS
                  : localizations?.NOTIFICATION_HEADER_ERROR}
              </strong>{' '}
              {(localizations && localizations[notification.message.message]) ??
                notification.message.message}
            </Container>
          </Alert>
        );
      })}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    notifications: state.notifications,
    localizations: state.localization?.pairs,
  };
};

export default connect(mapStateToProps, {})(CustomAlert);
