const UserTypes = Object.freeze({
  student: 'STUDENT',
  other: 'OTHER',
  admin: 'ADMIN',
  demo: 'DEMO',
  demoOther: 'DEMO-OTHER',
  demoStudent: 'DEMO-STUDENT',
});

export default UserTypes;
