import React, { useState, useEffect } from 'react';
import { useHistory /* , useLocation */ } from 'react-router-dom';
import DefaultNavbar from 'components/Custom/DefaultNavbar';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap';

import { Password, EnvelopeSimple } from 'phosphor-react';

// core components
// import DropdownScrollNavbar from 'components/Navbars/DropdownScrollNavbar.js';
import { connect } from 'react-redux';
import { startSigninPage, signin } from 'redux/actions/pages';
import FooterBlack from 'components/Custom/FooterBlack';
import CustomAlert from './CustomAlert';
import CustomLoading from './CustomLoading';
import { Link } from 'react-router-dom';

function LoginPage({
  auth,
  redirectTo,
  startScreen,
  signinAction,
  localizations,
}) {
  const [emailFocus, setEmailFocus] = useState(false);
  const [passwordFocus, setPasswordFocus] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    document.body.classList.add('login-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
      document.body.classList.remove('sidebar-collapse');
    };
  }, []);

  useEffect(() => {
    startScreen();

    // eslint-disable-next-line
  }, [auth]);

  useEffect(() => {
    if (redirectTo && redirectTo !== '' && redirectTo !== '/signin') {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  const onSigninClick = (e) => {
    e.preventDefault();
    signinAction({ email, password });
  };

  return (
    <>
      <CustomAlert />
      <CustomLoading />
      <DefaultNavbar calledBy="signin" white={true} />
      <div className="page-header header-filter" filter-color="blue">
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader
                      className="text-center"
                      style={{ fontSize: '30px' }}
                    >
                      {localizations?.SIGNIN_HEADER}
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          'no-border input-lg log login-input' +
                          (emailFocus ? ' input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <EnvelopeSimple
                              size={16}
                              color="#555555"
                              weight="duotone"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={localizations?.FORM_USER}
                          type="text"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          style={{
                            backgroundColor: 'white',
                            color: '#555555 !important',
                          }}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          'no-border input-lg login-input' +
                          (passwordFocus ? ' input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Password
                              size={16}
                              color="#555555"
                              weight="duotone"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={localizations?.FORM_PASSWORD}
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                          style={{
                            backgroundColor: 'white',
                            color: '#555555 !important',
                          }}
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={onSigninClick}
                        size="lg"
                      >
                        {localizations?.FORM_SIGNIN_BTN}
                      </Button>
                    </CardFooter>
                    {/* <div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          {localizations?.SIGNIN_CREATE_ACCOUNT_BTN}
                        </a>
                      </h6>
                    </div> */}
                    <div className="pull-right">
                      <h6>
                        <Link
                          className="link footer-link"
                          to="/forgot-password"
                        >
                          {localizations?.SIGNIN_FORGOT_PASSWORD_BTN}
                        </Link>
                        {/* <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Şifremi Unuttum
                        </a> */}
                      </h6>
                    </div>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBlack />
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  redirectTo: state.ui.redirectTo,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  startScreen: startSigninPage,
  signinAction: signin,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
