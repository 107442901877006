import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { connect } from 'react-redux';

const CustomLoading = ({ loading }) => {
  return loading ? (
    <div className="loading whole-screen">
      <PacmanLoader color="#67BCEC" size={50} />
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    loading: state.ui.loading,
  };
};

export default connect(mapStateToProps, {})(CustomLoading);
