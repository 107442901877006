// Feature Name Part
export const LOCALIZATION = '[Localization]';

// Action Types
export const START_LOCALIZATION = `${LOCALIZATION} START`;
export const SET_LOCALIZATION = `${LOCALIZATION} SET`;

// Action Creator Functions
export const startLocalization = (language) => ({
  type: START_LOCALIZATION,
  payload: { language },
});

export const setLocalization = ({ localization }) => ({
  type: SET_LOCALIZATION,
  payload: localization,
});
