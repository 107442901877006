import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { EnvelopeSimple } from 'phosphor-react';

import { startForgotPasswordPage, sendLinkByEmail } from 'redux/actions/pages';
import DefaultNavbar from 'components/Custom/DefaultNavbar';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap';
import CustomAlert from './CustomAlert';
import CustomLoading from './CustomLoading';
import FooterBlack from 'components/Custom/FooterBlack';

const ForgotPassword = ({
  redirectTo,
  startForgotPasswordPage,
  sendLinkByEmail,
  localizations,
}) => {
  const [emailFocus, setEmailFocus] = useState(false);
  const [email, setEmail] = useState('');

  const history = useHistory();

  useEffect(() => {
    startForgotPasswordPage();
    document.body.classList.add('login-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
      document.body.classList.remove('sidebar-collapse');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (redirectTo && redirectTo !== '' && redirectTo !== '/create-password') {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  const onSendLinkByEmailClick = (e) => {
    e.preventDefault();
    sendLinkByEmail({ email });
  };

  return (
    <>
      <CustomAlert />
      <CustomLoading />
      <DefaultNavbar calledBy="create-password" white={true} />
      <div className="page-header header-filter" filter-color="blue">
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader
                      className="text-center"
                      style={{ fontSize: '30px' }}
                    >
                      {localizations?.FORGOT_PASSWORD_HEADER}
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          'no-border input-lg log login-input' +
                          (emailFocus ? ' input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <EnvelopeSimple
                              size={16}
                              color="#555555"
                              weight="duotone"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={localizations?.FORM_EMAIL}
                          type="email"
                          name="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          style={{
                            backgroundColor: 'white',
                            color: '#555555 !important',
                          }}
                        />
                      </InputGroup>
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={onSendLinkByEmailClick}
                        size="lg"
                      >
                        {localizations?.FORM_SEND_LINK_EMAIL_BTN}
                      </Button>
                    </CardBody>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBlack />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  startForgotPasswordPage,
  sendLinkByEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
