import React from 'react';

const ToolIconButton = ({ size, onButtonClick, color, children, ...rest }) => {
  return (
    <div
      className="tool-icon-button"
      onClick={onButtonClick}
      style={{
        width: `${size}em`,
        height: `${size}em`,
        background: color,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: `${size * 0.12}em`,
        ...rest,
      }}
    >
      {children}
    </div>
  );
};

export default ToolIconButton;
