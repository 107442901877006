import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Password } from 'phosphor-react';

import {
  VALIDATE_EMAIL_TOKEN,
  startCreatePasswordPage,
  validateEmailToken,
  resetPassword,
} from 'redux/actions/pages';
import { removeMessage } from 'redux/actions/message';
import MessageTypes from 'enums/message-types';
import DefaultNavbar from 'components/Custom/DefaultNavbar';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from 'reactstrap';
import CustomAlert from './CustomAlert';
import CustomLoading from './CustomLoading';
import FooterBlack from 'components/Custom/FooterBlack';

const CreatePassword = ({
  redirectTo,
  message,
  startCreatePasswordPage,
  validateEmailToken,
  resetPassword,
  removeMessage,
  localizations,
}) => {
  const [passwordFocus, setPasswordFocus] = useState(false);
  const [password2Focus, setPassword2Focus] = useState(false);

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    startCreatePasswordPage();
    document.body.classList.add('login-page');
    document.body.classList.add('sidebar-collapse');
    document.documentElement.classList.remove('nav-open');
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove('login-page');
      document.body.classList.remove('sidebar-collapse');
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (redirectTo && redirectTo !== '' && redirectTo !== '/create-password') {
      history.push(redirectTo);
    }
    // eslint-disable-next-line
  }, [redirectTo]);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token && token !== '') {
      validateEmailToken({ token });
    }
    // eslint-disable-next-line
  }, [location.search]);

  useEffect(() => {
    if (
      message &&
      message.messageContent !== undefined &&
      message.messageContent !== null &&
      message.messageContent !== ''
    ) {
      Swal.fire({
        icon: message.messageType || 'error',
        title:
          message.messageType === MessageTypes.success
            ? localizations?.MESSAGE_SUCCESS
            : localizations?.MESSAGE_ERROR,
        text: message.messageContent,
        showCancelButton: false,
        confirmButtonText: localizations?.MESSAGE_GO_HOME_CONFIRM_BTN,
      }).then((_) => {
        removeMessage(VALIDATE_EMAIL_TOKEN);
        history.push('/signin');
      });
    }
    // eslint-disable-next-line
  }, [message]);

  const onResetPasswordClick = (e) => {
    e.preventDefault();

    const token = new URLSearchParams(location.search).get('token');
    if (token) {
      resetPassword({ password, password2, token });
    } else {
      // resetPassword({ password, password2, token });
      console.log('Token yok!');
    }
  };

  return (
    <>
      <CustomAlert />
      <CustomLoading />
      <DefaultNavbar calledBy="create-password" white={true} />
      <div className="page-header header-filter" filter-color="blue">
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader
                      className="text-center"
                      style={{ fontSize: '30px' }}
                    >
                      {localizations?.CREATE_PASSWORD_HEADER}
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          'no-border input-lg log login-input' +
                          (passwordFocus ? ' input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Password
                              size={16}
                              color="#555555"
                              weight="duotone"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={localizations?.FORM_PASSWORD}
                          type="password"
                          name="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          onFocus={() => setPasswordFocus(true)}
                          onBlur={() => setPasswordFocus(false)}
                          style={{
                            backgroundColor: 'white',
                            color: '#555555 !important',
                          }}
                        />
                      </InputGroup>
                      <InputGroup
                        className={
                          'no-border input-lg login-input' +
                          (password2Focus ? ' input-group-focus' : '')
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Password
                              size={16}
                              color="#555555"
                              weight="duotone"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder={localizations?.FORM_PASSWORD2}
                          type="password"
                          name="password2"
                          value={password2}
                          onChange={(e) => setPassword2(e.target.value)}
                          onFocus={() => setPassword2Focus(true)}
                          onBlur={() => setPassword2Focus(false)}
                          style={{
                            backgroundColor: 'white',
                            color: '#555555 !important',
                          }}
                        />
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        onClick={onResetPasswordClick}
                        size="lg"
                      >
                        Şifreyi yenile
                      </Button>
                    </CardFooter>
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterBlack />
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  redirectTo: state.ui.redirectTo,
  message: state.message[VALIDATE_EMAIL_TOKEN],
  localizations: state.localization?.pairs,
});

const mapDispatchToProps = {
  startCreatePasswordPage,
  validateEmailToken,
  resetPassword,
  removeMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreatePassword);
